import { gql } from 'apollo-angular';

// Kellerbook related queries
export const getUserKellerBook = gql`
query MyQuery {
  getOwnUserCellars {
    cellar_bk_df
    cellar_bk_id
    cellar_bk_nm
    cellar_bk_typ
    totalCellarWines
  }
}`;

export const getFriendsKellerBook = gql`
query MyQuery($user_id: ID!) {
  getUserCellars(user_id: $user_id) {
    cellar_bk_crtd_time
    cellar_bk_df
    cellar_bk_id
    cellar_bk_nm
    cellar_bk_typ
    user_id
    cellar_bk_updt_time
  }
}`;

// Wine related queries
export const getOwnCellarWines = gql`
query MyQuery($cellar_bk_id: ID!) {
  getOwnCellarWines(cellar_bk_id: $cellar_bk_id) {
    bought_on_tresorvino
    cw_deletion_status
    cellar_bk_id
    cellar_wine_df
    cw_available_qty
    cw_on_sale_qty
    cw_sold_qty
    cw_bottle_size
    cw_crtd_time
    cw_currency
    cw_image
    cw_lid
    cw_lid_qlty
    cw_liquid_lvl
    cw_packaging
    cw_price
    cw_quantity
    cw_storage
    cw_storage_temp
    cw_updt_time
    cw_vintage
    cw_ask_price
    cw_bid_price
    cw_description
    user_wine_id
    user_id
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
    global_wine_id
    global_wine {
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      name
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      status
      storage
      soil
      storage_temperature
      sub_region
      sub_type
      type
      vineyard
      vintage {
        final
        first
      }
      wine_type
      current_price {
        prices {
          bottle_size
          currency
          domain
          likelihood
          region
          vintage
          wine_name
          wine_price
          wine_tax
          wine_url
        }
        average
        day
        difference
        relDiff
        status
      }
    }
    statistics {
      absDifference
      currentValue
      investmentValue
      relDifference
    }
  }
}`;

export const getWineListQuery = gql`
query MyQuery {
  getAllWines{
    nextToken
    wines {
      id
      color
      name
      country
      cultivation
      currency
      acquisition_price
      current_price
      format
      grape_variety
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      packaging
      predicate_de
      predicate_fr
      producer_name
      production_quantity
      quantity
      region
      seller
      soil
      storage_temperature
      storage
      sub_type
      type
      vintage {
        first
        final
      }
      cw_currency
      cw_bottle_size
      cw_image
    }
  }
}`;

export const getWineByNameQuery = gql`
  query MyQuery($name: String!){
    searchWinesByName(name: $name){
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      name
      searchname
      location
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      soil
      status
      storage
      storage_temperature
      sub_region
      type
      sub_type
      vineyard
      vintage {
        first
        final
      }
      wine_type
    }
}`;

export const getFriendsKellerWineQuery = gql`
query MyQuery($cellar_bk_id: ID!) {
  getCellarWines(cellar_bk_id: $cellar_bk_id) {
    cw_bid_price
    cw_ask_price
    cellar_bk_id
    cellar_wine_df
    cw_bottle_size
    cw_crtd_time
    cw_currency
    cw_image
    cw_lid
    cw_lid_qlty
    cw_liquid_lvl
    cw_packaging
    cw_price
    cw_quantity
    cw_available_qty
    cw_storage
    cw_storage_temp
    cw_updt_time
    cw_vintage
    cw_description
    global_wine_id
    user_id
    user_wine_id
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
    global_wine {
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      name
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      soil
      status
      storage
      storage_temperature
      sub_region
      sub_type
      type
      vineyard
      wine_type
      vintage {
        final
        first
      }
      current_price {
        prices {
          wine_url
          wine_tax
          wine_price
          wine_name
          region
          domain
          currency
        }
        average
        day
        status
      }
    }
  }
}`;

// Notifications related queries
export const getUnreadNotificationsQuery = gql`
query MyQuery {
  getNotification {
    user_id
    invoker_user_id
    event_id
    category
    created_timestamp
    payload {
      cellar_bk_id
      cellar_bk_nm
      user_wine_id
      wine_name
      community_id
      community_name
      post_id
      comment_id
      sales_id
      bid_detail_id
      sell_price
    }
    invoker_user_info {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
  }
}`;

export const getFriendsCount = gql`
query MyQuery {
  getFriends {
    totalFriends
  }
}`;

export const getCommunitiesCount = gql`
query MyQuery {
  getOwnCommunities {
    totalCommunities
  }
}`;

export const getDashboardLineChart = gql`
query MyQuery($cellar_bk_id: String) {
  getDashboard(cellar_bk_id: $cellar_bk_id) {
    history {
      sumInvestmentValue
      sumCurrentValue
      day_timestamp
      count
      cellar_bk_id
    }
  }
}
`;

export const getDashboard = gql`
query MyQuery($cellar_bk_id: String) {
  getDashboard(cellar_bk_id: $cellar_bk_id) {
    highestGlobalWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestGlobalWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestOwnWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestOwnWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestGlobalWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestGlobalWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestOwnWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestOwnWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    totalPendingFriends
    totalFriends
    totalOwnCommunities
    totalJoinedCommunities
    regions {
      count
      key
    }
    types {
      key
      count
    }
    history {
      sumInvestmentValue
      sumCurrentValue
      day_timestamp
      count
      cellar_bk_id
    }
  }
}
`;

export const getCurrentUserSales = gql`
query MyQuery {
  getCurrentUserSales {
    distance
    cellar_bk_id
    settle_status
    cellar_bk {
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      cellar_bk_updt_time
    }
    sale_id
    so_ask_price
    so_per_bottle_price
    so_highest_bid
    so_num_bids
    so_quantity
    so_status
    so_crtd_date
    so_updt_date
    so_crtd_time
    so_updt_time
    user_id
    user_wine_id
    so_vino_status
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
    cellar_wine {
      cellar_bk_id
      cw_bid_price
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_vintage
      cw_updt_time
      cw_description
      cellar_wine_df
      cw_ask_price
      cw_bottle_size
      global_wine_id
      user_id
      user_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid_quality
        lid
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        vintage {
          final
          first
        }
        wine_type
        current_price {
          average
          day
          difference
          relDiff
          status
          prices {
            bottle_size
            currency
            domain
            fetch_time
            likelihood
            region
            request_id
            vintage
            wine_name
            wine_price
            wine_tax
            wine_url
          }
        }
      }
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
    }
  }
}
`

export const getSalesBid = gql`
query MyQuery ($selectedWineSaleId: ID!) {
  getSalesBid(sale_id: $selectedWineSaleId) {
    sale_deletion_status
    cellar_bk_id
    error
    global_wine_id
    sale_id
    seller_user_id
    so_quantity
    so_status
    user_wine_id
    bids {
      bd_ask_price
      bd_bid_price
      bd_crtd_time
      bd_quantity
      bd_status
      bd_updt_time
      bid_detail_id
      buyer_user_id
      cellar_bk_id
      sale_offer_id
      seller_user_id
      user_wine_id
      buyer_user_information {
        address
        error
        firstname
        id
        lastname
        mailID
        phone_number
        picture
        identityId
      }
      seller_user_id
      seller_user_information {
        address
        error
        firstname
        id
        lastname
        phone_number
        picture
        mailID
        identityId
      }
    }
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        wine_type
        vineyard
        type
        sub_type
        sub_region
        storage_temperature
        storage
        status
        soil
        seller
        region
        producer_title
        production_quantity
        quantity
        vintage {
          final
          first
        }
        current_price {
          average
          day
          difference
          relDiff
          status
        }
      }
      global_wine_id
      user_id
      user_wine_id
      user {
        error
        firstname
        id
        identityId
        lastname
        picture
      }
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
    }
  }
}
`;

export const getAllOffers = gql`
  query MyQuery($distance: String!, $pincode: String!, $country: String) {
    getAllSales(distance: $distance, pincode: $pincode, country: $country) {
      SalesWines {
        distance
        so_pincode
        bid_status
        cellar_bk_id
        global_wine_id
        sale_id
        so_ask_price
        so_per_bottle_price
        so_crtd_date
        so_crtd_time
        so_highest_bid
        so_num_bids
        so_quantity
        so_status
        so_updt_date
        so_updt_time
        user_id
        user_wine_id
        cellar_wine {
          cellar_bk_id
          cellar_wine_df
          cw_ask_price
          cw_available_qty
          cw_bid_price
          cw_bottle_size
          cw_crtd_time
          cw_currency
          cw_image
          cw_lid_qlty
          cw_lid
          cw_liquid_lvl
          cw_packaging
          cw_price
          cw_quantity
          cw_sold_qty
          cw_storage
          cw_storage_temp
          cw_updt_time
          cw_vintage
          cw_description
          global_wine_id
          user_id
          user_wine_id
          global_wine {
            add_info1
            add_info2
            add_info3
            barrel
            color
            country
            cultivation
            currency
            current_price {
              average
            }
            date_added
            date_updated
            flavor
            format
            grape_variety
            id
            label
            label_quality
            lid
            lid_quality
            liquid_level
            location
            name
            packaging
            picture
            predicate_de
            predicate_fr
            producer_name
            producer_title
            production_quantity
            quantity
            region
            seller
            soil
            status
            storage
            storage_temperature
            sub_region
            sub_type
            type
            vineyard
            wine_type
            vintage {
              final
              first
            }
          }
          user {
            picture
            lastname
            identityId
            id
            firstname
            error
          }
        }
        seller_rank {
          seller_avg_rating
          seller_settled_trade
          seller_total_post
          seller_total_rank
          seller_total_wine
          seller_user_id
          seller_user_type
          sr_crtd_time
          sr_updt_time
        }
        user {
          error
          firstname
          id
          identityId
          lastname
          picture
          paymentPreference
          address
        }
      }
      pincodeDataStatus
    }
  }
`;

export const getAllMyBids = gql`
  query MyQuery {
    getCurrentUserBids {
      distance
      rating_status
      rating {
        bid_detail_id
        bid_rating_id
        buyer_user_id
        sale_offer_id
        sb_bid_comment
        sb_bid_rating
        sb_crtd_time
        sb_updt_time
        seller_user_id
      }
      bd_ask_price
      bd_bid_price
      bd_crtd_date
      bd_crtd_time
      bd_quantity
      bd_status
      bd_updt_date
      bd_updt_time
      bid_detail_id
      bd_user_cellar {
        cellar_bk_crtd_time
        cellar_bk_df
        cellar_bk_id
        cellar_bk_nm
        cellar_bk_typ
        cellar_bk_updt_time
        totalCellarWines
        user_id
      }
      bd_cellar_bk_id
      buyer_user_id
      cellar_bk_id
      sale_offer_id
      user_wine_id
      seller_user_information {
        address
        error
        firstname
        id
        lastname
        mailID
        phone_number
        picture
        identityId
      }
      cellar_wine {
        cellar_bk_id
        cw_ask_price
        cw_available_qty
        cw_bid_price
        cw_currency
        cw_image
        cw_price
        cw_quantity
        cw_sold_qty
        cw_vintage
        cw_description
        user_id
        user_wine_id
        user {
          error
          firstname
          id
          identityId
          lastname
          picture
        }
        global_wine_id
        global_wine {
          color
          country
          currency
          id
          location
          name
          picture
          producer_title
          producer_name
          production_quantity
          quantity
          status
          sub_region
          sub_type
          type
          vintage {
            final
            first
          }
          wine_type
        }
      }
    }
  }
`;

export const getPostalCodesByParams = gql`
  query MyQuery($country: String!, $state: String!, $city: String!) {
    getPostalCodeByCity(country: $country, state: $state, city: $city) {
      pincodes
    }
  }
`;

export const getPostalCodesForNewUser = gql`
  query MyQuery($pincode: String!, $country: String!) {
    getPostalcodes(pincode: $pincode, country: $country) {
      pincode
    }
  }
`;

export const getCurrentUserVinoCoins = gql`
  query MyQuery {
    getCurrentUserVinoCoins {
      user_id
      vinoCoins
      functionalityCharges {
        currency_value
        func_cd
        validity
        vino_coin
      }
    }
  }
`;

export const getVcTransaction = gql`
  query MyQuery($startDate: String!, $endDate: String!) {
    getVcTransaction(
        startDate: $startDate,
        endDate: $endDate 
    ) {
      amount
      amount_in_eur
      cur_bal
      end_date
      func_cd
      prev_bal
      ref_field
      ref_id
      status
      timestamp
      transaction_id
      transaction_type
      user_id
      vino_coins
      start_date
      payment_method
      payment_error
      payment_reference
      payment_reference_number
    }
  }
`;
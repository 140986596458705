<div class="container">
    <div id="federatedUserDetails" class="allFormBox federated-user-wrapper" [ngClass]="{'with-heading': firstTimeLogin}">
        <h5 class="federated-note text-white" *ngIf="firstTimeLogin">{{ 'common.federated_welcome_note' | translate }}</h5>
        <form [formGroup]="federatedUserDetailsForm" class="federatedUserDetailsForm" (ngSubmit)="onSubmit()">
            <div class="form-row mb-3">
                <div class="form-group col-sm-12">
                    <label class="text-white" for="userType">{{
                        "User Type:" | translate }}</label>
                    <div>
                        <select formControlName="userType" class="form-control allDropdowns" [ngClass]="{
            invalid: submitted && federatedUserDetails.userType.errors
            }">
                            <option value="">{{ "Select User Type" | translate }}</option>
                            <option *ngFor="let userType of userTypeList" [value]="userType">
                                {{ userType | translate }}
                            </option>
                        </select>

                        <div *ngIf="
            (submitted && federatedUserDetails.userType.errors) ||
            (federatedUserDetails.userType.touched &&
                federatedUserDetails.userType.invalid)
            " class="invalid-feedback errorDisplay">
                            <div *ngIf="federatedUserDetails.userType.errors.required">
                                {{ "UserTypeRequired" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group form-check mb-3">
                <input type="checkbox" formControlName="termsConditions" id="termsConditions"
                    class="form-check-input allInputBoxes" [ngClass]="{'is-invalid': submitted && federatedUserDetails.termsConditions.errors}" />
                <a class="allLinks" type="button" for="termsConditions" href="#" data-toggle="modal" (click)="tncOverlay = true"
                    data-backdrop="false" data-target="#federatedTermsModal">{{ "Terms & Conditions" | translate }}</a>
                <div *ngIf="submitted && federatedUserDetails.termsConditions.errors" class="invalid-feedback">
                    {{ "TCWarning" | translate }}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4 btnOptions">
                    <button type="submit" class="btn tr-btn-primary mr-2">
                        {{ "Confirm" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="" *ngIf="tncOverlay">
    <div class="modal fade show sell-wine tr-modal" tabindex="-1" role="dialog" aria-labelledby=""
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tr-shadow">
          <div class="modal-header">
            <h5 class="modal-title">{{ 'login.terms_conditions' | translate }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tncOverlay = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modelText">
                <app-terms-conditions></app-terms-conditions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="animation-container d-flex justify-content-center">
  <div class="first-svg">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 429.85 365.3">
      <defs>
        <style>
          .cls-1 {
            fill: transparent;
          }

          .cls-2 {
            fill: url(#Unbenannter_Verlauf_329);
          }

          .cls-3 {
            fill: url(#Unbenannter_Verlauf_2);
          }

          .cls-16,
          .cls-4 {
            fill: #fff;
          }

          .cls-4 {
            opacity: 0.25;
          }

          .cls-5 {
            fill: url(#Unbenannter_Verlauf_340);
          }

          .cls-6 {
            fill: url(#Unbenannter_Verlauf_186);
          }

          .cls-7 {
            fill: url(#Unbenannter_Verlauf_340-2);
          }

          .cls-8 {
            fill: #5b358c;
          }

          .cls-14,
          .cls-9 {
            fill: none;
            stroke-miterlimit: 10;
          }

          .cls-9 {
            stroke: #4c2683;
          }

          .cls-10 {
            fill: #312783;
          }

          .cls-11 {
            fill: url(#Unbenannter_Verlauf_186-2);
          }

          .cls-12 {
            fill: url(#Unbenannter_Verlauf_340-3);
          }

          .cls-13 {
            fill: #4c2c63;
          }

          .cls-14 {
            stroke: #f6f6f6;
            stroke-width: 0.5px;
          }

          .cls-15 {
            opacity: 0.26;
          }

          .cls-17 {
            fill: #2a1433;
          }

          .cls-18 {
            fill: #c5b6da;
            opacity: 0.84;
          }

          .cls-19 {
            fill: url(#Unbenannter_Verlauf_7);
          }

          .cls-20 {
            opacity: 0.65;
            fill: url(#Unbenannter_Verlauf_134);
          }

          .cls-21 {
            fill: url(#Unbenannter_Verlauf_139);
          }

          .cls-22 {
            fill: url(#Unbenannter_Verlauf_81);
          }

          .cls-23 {
            fill: url(#Unbenannter_Verlauf_115);
          }

          .cls-24 {
            fill: url(#Unbenannter_Verlauf_310);
          }

          .cls-25 {
            fill: url(#Unbenannter_Verlauf_124);
          }

          .cls-26 {
            fill: url(#Unbenannter_Verlauf_310-2);
          }

          .cls-27 {
            fill: url(#Unbenannter_Verlauf_150);
          }

          .cls-28 {
            fill: url(#Unbenannter_Verlauf_109);
          }

          .cls-29 {
            fill: url(#Unbenannter_Verlauf_89);
          }

          .cls-30 {
            fill: url(#Unbenannter_Verlauf_47);
          }

          .cls-31 {
            fill: url(#Unbenannter_Verlauf_103);
          }

          .cls-32 {
            fill: url(#Unbenannter_Verlauf_310-3);
          }

          .cls-33 {
            fill: url(#Unbenannter_Verlauf_66);
          }

          .dollar-1{fill:#cf4266;}
          .pink-border{fill:none;stroke:#cf4266;stroke-miterlimit:10;stroke-width:8.7px;}

          #dollar-2 .cls-7{
            fill:#cf4266;
          }
          #tresor-back .cls-2 {
              fill: #646464;
          }
          #inside .cls-4 {
            opacity: 1;
              fill: #bdbdbd;
          }
          #tresor-front-1 .cls-3{
              fill:#575756;
          }
          #tresor-front-1 .cls-4{
              fill:url(#Unbenannter_Verlauf_6);
              opacity: 1;
          }
          #tresor-front-1 .cls-5{
              fill:url(#Unbenannter_Verlauf_6-2);
          }
          #tresor-front-1 .cls-6{
              fill:url(#Unbenannter_Verlauf_6-3);
          }
          #tresor-front-1 .cls-7{
              fill:#9d9d9c;
          }
          #screen .cls-8{
              fill:#878787;
          }
          #screen .cls-9{
              fill:#f6f6f6;
          }
          #screen .cls-10{
              fill:#b2b2b2;
          }
          #screen .cls-11, #screen .cls-24{
              fill:none;
          }
          #screen .cls-11{
              stroke:#706f6f;
              stroke-linecap:round;
              stroke-linejoin:round;
              stroke-width:5;
          }
          #screen .cls-12{
              fill:url(#TresorVino_Verlauf);
          }
          #screen .cls-13{
              fill:url(#TresorVino_Verlauf-2);
          }
          #screen .cls-14{
              fill:url(#TresorVino_Verlauf-3);
              stroke: none;
          }
          #screen .cls-15{
              fill:url(#TresorVino_Verlauf-4);
              opacity: 1;
          }
          #screen .cls-16{
              fill:url(#TresorVino_Verlauf-5);
          }
          #screen .cls-17{
              fill:url(#TresorVino_Verlauf-6);
          }
          #screen .cls-18{
              fill:url(#TresorVino_Verlauf-7);
          }
          #screen .cls-19{
              fill:url(#TresorVino_Verlauf-8);
          }
          #screen .cls-20{
              fill:url(#TresorVino_Verlauf-9);
          }
          #screen .cls-21{
              fill:#ededed;
          }
          #screen .cls-22{
              fill:#cf4266;
          }
          #screen .cls-23{
              fill:#5b358c;
          }
          #screen .cls-24{
              stroke:#9d9d9c;
              stroke-miterlimit:10;
          }
          #screen .cls-25{
              fill:#dadada;
          }
        </style>
        <radialGradient id="Unbenannter_Verlauf_329" cx="124.88" cy="183.58" r="95.34" gradientTransform="translate(0 171.52) scale(1 0.57)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#5b358c" />
          <stop offset="0.1" stop-color="#53307e" />
          <stop offset="0.45" stop-color="#39204f" />
          <stop offset="0.71" stop-color="#291633" />
          <stop offset="0.87" stop-color="#231228" />
        </radialGradient>
        <linearGradient id="Unbenannter_Verlauf_2" x1="53.94" y1="175.46" x2="114.54" y2="175.2" gradientUnits="userSpaceOnUse">
          <stop offset="0.51" stop-color="#351f36" />
          <stop offset="1" stop-color="#4c2c63" />
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_340" x1="101.83" y1="85.55" x2="115.73" y2="85.55" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="#acacac" />
          <stop offset="0.93" stop-color="#94633c" />
          <stop offset="1" stop-color="#936037" />
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_186" x1="8.73" y1="192.33" x2="75.24" y2="192.05" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4c2c63" />
          <stop offset="1" stop-color="#bca5d0" />
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_340-2" x1="61.29" y1="93.65" x2="76.54" y2="93.65" xlink:href="#Unbenannter_Verlauf_340" />
        <linearGradient id="Unbenannter_Verlauf_186-2" x1="88.45" y1="192.33" x2="154.96" y2="192.05" xlink:href="#Unbenannter_Verlauf_186" />
        <linearGradient id="Unbenannter_Verlauf_340-3" x1="141.02" y1="93.65" x2="156.27" y2="93.65" xlink:href="#Unbenannter_Verlauf_340" />
        <radialGradient id="Unbenannter_Verlauf_7" cx="88.72" cy="204.99" r="45.97" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#cf4266" />
          <stop offset="1" stop-color="#82368c" />
        </radialGradient>
        <linearGradient id="Unbenannter_Verlauf_134" x1="92.34" y1="181.92" x2="85.32" y2="218.62" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#e3dcef" />
          <stop offset="1" stop-color="#bca5d0" />
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_139" x1="19.26" y1="245.6" x2="48.5" y2="261.18" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#936037" />
          <stop offset="1" stop-color="#063" />
        </linearGradient>
        <radialGradient id="Unbenannter_Verlauf_81" cx="-11.72" cy="214.14" r="9.96" gradientTransform="matrix(0.99, -0.15, 0.15, 0.99, 27.94, 51.11)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#82368c" />
          <stop offset="1" stop-color="#47204e" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_115" cx="65.12" cy="255.06" r="10.66" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#8e4b97" />
          <stop offset="1" stop-color="#4c2453" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_310" cx="81.79" cy="245.13" r="10" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#ca64a3" />
          <stop offset="1" stop-color="#8b2a41" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_124" cx="82.35" cy="264.35" r="12.28" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#9a64a6" />
          <stop offset="1" stop-color="#5f2968" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_310-2" cx="53.07" cy="279.91" r="8.14" xlink:href="#Unbenannter_Verlauf_310" />
        <radialGradient id="Unbenannter_Verlauf_150" cx="65.25" cy="273" r="10.69" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#965ba1" />
          <stop offset="1" stop-color="#652c4e" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_109" cx="66.34" cy="291.48" r="10.76" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#9861a4" />
          <stop offset="1" stop-color="#82368c" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_89" cx="80.81" cy="282.64" r="10.56" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#82368c" />
          <stop offset="1" stop-color="#4f2357" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_47" cx="97.36" cy="274.8" r="9.5" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#82368c" />
          <stop offset="1" stop-color="#612a6a" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_103" cx="82.12" cy="299.68" r="8.14" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#a172af" />
          <stop offset="1" stop-color="#82368c" />
        </radialGradient>
        <radialGradient id="Unbenannter_Verlauf_310-3" cx="96.2" cy="292.93" r="11.32" xlink:href="#Unbenannter_Verlauf_310" />
        <radialGradient id="Unbenannter_Verlauf_66" cx="95.59" cy="308.91" r="10.33" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#8f5e9a" />
          <stop offset="1" stop-color="#71307a" />
        </radialGradient>
        <linearGradient id="Unbenannter_Verlauf_13" x1="135.12" y1="61.17" x2="197.65" y2="318.37" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#9d9d9c"/><stop offset="1" stop-color="#706f6f"/></linearGradient><linearGradient id="Unbenannter_Verlauf_6" x1="193.54" y1="276.38" x2="142.29" y2="94.27" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#878787"/><stop offset="1" stop-color="#ededed"/></linearGradient><linearGradient id="Unbenannter_Verlauf_6-2" x1="58.06" y1="138.88" x2="13.23" y2="142.54" xlink:href="#Unbenannter_Verlauf_6"/><linearGradient id="Unbenannter_Verlauf_6-3" x1="58.06" y1="222.36" x2="13.23" y2="226.02" xlink:href="#Unbenannter_Verlauf_6"/><linearGradient id="TresorVino_Verlauf" x1="257.57" y1="199" x2="257.57" y2="186.31" gradientTransform="translate(0.31 -2.46) rotate(-0.04)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#45203e"/><stop offset="1" stop-color="#cf4466"/></linearGradient><linearGradient id="TresorVino_Verlauf-2" x1="265.2" y1="199" x2="265.2" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-3" x1="272.74" y1="199" x2="272.74" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-4" x1="281.27" y1="199" x2="281.27" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-5" x1="289.72" y1="199" x2="289.72" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-6" x1="298.6" y1="199" x2="298.6" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-7" x1="313.7" y1="199" x2="313.7" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-8" x1="327.04" y1="199" x2="327.04" y2="186.31" xlink:href="#TresorVino_Verlauf"/><linearGradient id="TresorVino_Verlauf-9" x1="319.89" y1="199" x2="319.89" y2="186.31" xlink:href="#TresorVino_Verlauf"/>
        <linearGradient id="Unbenannter_Verlauf_13" x1="196.25" y1="61.17" x2="258.78" y2="318.37" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#9d9d9c"/>
          <stop offset="1" stop-color="#706f6f"/>
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_13" x1="135.12" y1="61.17" x2="197.65" y2="318.37" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#9d9d9c"/><stop offset="1" stop-color="#706f6f"/>
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_6" x1="193.54" y1="276.38" x2="142.29" y2="94.27" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#878787"/>
          <stop offset="1" stop-color="#ededed"/>
        </linearGradient>
        <linearGradient id="Unbenannter_Verlauf_6-2" x1="58.06" y1="138.88" x2="13.23" y2="142.54" xlink:href="#Unbenannter_Verlauf_6"/>
        <linearGradient id="Unbenannter_Verlauf_6-3" x1="58.06" y1="222.36" x2="13.23" y2="226.02" xlink:href="#Unbenannter_Verlauf_6"/>
      </defs>
      <g id="background">
        <rect id="Rechteck_3" data-name="Rechteck 3" class="cls-1" x="-0.94" y="-0.55" width="430.8" height="366.41"/>
      </g>
      <g id="glow-bottom">
        <ellipse class="cls-2" cx="124.88" cy="276.49" rx="95.75" ry="55.09" />
      </g>
      <g id="tresor-back">
        <rect class="cls-2 gray-border" x="31.95" y="56.33" width="262.21" height="251.73" rx="15.08"/>
      </g>
      <g id="inside">
        <rect class="cls-4" x="52.95" y="75.53" width="220.22" height="213.32" rx="12.46"/>
      </g>
      <g id="bottle-3">
        <path class="cls-3" d="M132.55,178.49q0-12.62-.11-25.24c0-6.44-1.76-12.23-5.63-17-5.18-6.36-10-13.74-9.91-22.36,0-1.58,0-12.59,0-15.52a2.2,2.2,0,0,0,1.7-2.14V94.32a2.2,2.2,0,0,0-2.19-2.2H101.16a2.21,2.21,0,0,0-2.2,2.2v1.91A2.18,2.18,0,0,0,99.88,98c0,2.09,0,14.43,0,16.19a42.08,42.08,0,0,1-.25,5.27c-.71,5.8-4.63,11.17-8.31,15.46-4.65,5.4-6.68,11.85-6.64,19.29q.15,24.72.22,49.42l.21,51.09c0,3,.62,3.73,3.31,3.72l41.17-.17c2.67,0,3.28-.71,3.26-3.77Q132.71,216.5,132.55,178.49Z" />
        <path class="cls-4" d="M101.09,97.92v16.25a44.62,44.62,0,0,1-.29,5.39c-.79,6.27-5,11.83-8.62,16-4.36,5-6.47,11.1-6.46,18.58q0,16.9,0,33.8v68.53h1V187.92q0-16.9,0-33.8c0-7.22,2-13.07,6.21-17.9,3.69-4.25,8-10,8.87-16.53a44.92,44.92,0,0,0,.29-5.52V97.93Z" />
        <path class="cls-5" d="M115.72,83.23c0-2-.63-2.73-2.44-2.74h-9c-1.88,0-2.49.69-2.49,2.81v7.3h13.89C115.73,85.17,115.72,88.66,115.72,83.23Z" />
      </g>
      <g id="bottle-2">
        <path class="cls-6" d="M95,195.66q0-13.84-.11-27.7c0-7.07-1.93-13.43-6.19-18.66-5.68-7-10.92-15.08-10.88-24.54,0-1.74,0-13.82,0-17a2.4,2.4,0,0,0,1.87-2.34v-2.1a2.41,2.41,0,0,0-2.42-2.41H60.55a2.41,2.41,0,0,0-2.41,2.41v2.1a2.41,2.41,0,0,0,1,2c0,2.3,0,15.84,0,17.77a44.49,44.49,0,0,1-.28,5.78c-.77,6.36-5.07,12.27-9.12,17-5.1,5.92-7.33,13-7.28,21.17q.17,27.12.23,54.24L43,279.33c0,3.34.68,4.1,3.63,4.09l45.18-.19c2.94,0,3.6-.78,3.59-4.14Q95.17,237.38,95,195.66Z" />
        <path class="cls-4" d="M60.48,107.23v17.84a49.71,49.71,0,0,1-.32,5.91c-.87,6.88-5.51,13-9.46,17.54-4.79,5.51-7.11,12.18-7.09,20.39q0,18.56,0,37.1v75.21h1.14V206q0-18.54,0-37.1c0-7.92,2.22-14.34,6.81-19.64,4.06-4.67,8.83-11,9.74-18.14a52.34,52.34,0,0,0,.32-6.06V107.24Z" />
        <path class="cls-7" d="M76.53,91.11c0-2.23-.68-3-2.68-3H64c-2.07,0-2.73.76-2.73,3.09v8H76.53C76.55,93.24,76.53,97.07,76.53,91.11Z" />
        <polygon class="cls-8" points="50.98 167.77 88.56 167.77 88.56 216.24 50.32 216.24 50.98 167.77" />
        <polygon class="cls-9" points="53.19 169.7 86.27 169.7 86.27 214.3 52.61 214.3 53.19 169.7" />
        <path class="cls-10" d="M56.81,191.72c-.05,0,0-.07,0-.19a39.51,39.51,0,0,1,3.86-5.22c.29-.33.49-.53.69-.53a3.81,3.81,0,0,1,.56.07c.18,0,.33.18.18.49a20.44,20.44,0,0,0-1.07,2.59,27,27,0,0,1,2.75-3c.16-.14.21-.15.34-.11a3.29,3.29,0,0,1,.63.21c.24.18.15.36-.16.81-.85,1.23-1.88,3-2.47,4a.86.86,0,0,1-.32.31c-.12.05-.35.09-.54.17s-.17,0-.14-.05c.51-1.12,1.12-2.39,1.85-3.69a27.89,27.89,0,0,0-2.67,3c-.08.11-.13.17-.2.17a.91.91,0,0,1-.37-.18c-.23-.15-.3-.38-.1-.89s.54-1.3.94-2.2a43.86,43.86,0,0,0-2.85,3.51,1.24,1.24,0,0,1-.6.5A1.33,1.33,0,0,1,56.81,191.72Z" />
        <path class="cls-10" d="M65.24,190.5c-.42.59-.77.82-1,.82a1.05,1.05,0,0,1-.82-.59c-.17-.29-.16-.7.28-1.27a2.65,2.65,0,0,1,1.74-1.07.73.73,0,0,1,.56.43.19.19,0,0,1,.17-.1,1.29,1.29,0,0,1,.45.15c.18.09.26.18.26.27a2.4,2.4,0,0,1-.31.77,3.51,3.51,0,0,0-.33.88c.35-.08.7-.35,1.25-1.08l0,0c.06-.07.07-.08.1-.05l0,0a.1.1,0,0,1,0,.15l0,0c-.53.87-1.24,1.54-1.86,1.54C65.38,191.38,65.11,191.17,65.24,190.5Zm.61-1.46a2.23,2.23,0,0,0-.95.59,1.52,1.52,0,0,0-.56,1.13C64.8,190.59,65.27,190.11,65.85,189Z" />
        <path class="cls-10" d="M67.18,191.17c-.18-.12-.26-.22-.26-.4s.17-.55.55-1.06a11.89,11.89,0,0,0,.91-1.35c0-.05.06-.06.16-.06a1.29,1.29,0,0,1,.42.11c.11.07.18.18.1.52a7.42,7.42,0,0,1,1.26-.86.91.91,0,0,1,.26-.12c.06,0,.08.13.08.2s0,.13-.15.2a10.84,10.84,0,0,0-2.4,2.49c-.33.46-.38.51-.51.51A.8.8,0,0,1,67.18,191.17Z" />
        <path class="cls-10" d="M69.71,193.3A14.71,14.71,0,0,1,71,191.06a1.22,1.22,0,0,1-.63.26.59.59,0,0,1-.49-.24.78.78,0,0,1-.29-.62,1.52,1.52,0,0,1,.37-.91,2.83,2.83,0,0,1,1.92-1.21.72.72,0,0,1,.67.48.61.61,0,0,1,.25.16c.2.18.35.39.22.61-.23.41-.51.91-.83,1.44a8.5,8.5,0,0,0,1.41-1.32l0,0c0-.06.07-.07.1-.05l0,0a.12.12,0,0,1,0,.16l0,0c-.86,1.15-2,1.69-2.89,3.31-.14.23-.31.2-.66.31a1.53,1.53,0,0,1-.36.07C69.67,193.53,69.64,193.47,69.71,193.3Zm2.59-4.42a2.63,2.63,0,0,0-1.82,1.92,3.08,3.08,0,0,0,1.1-.7c.27-.44.44-.77.63-1.1Z" />
        <path class="cls-10" d="M75.2,190.38c-.5.59-1,1-1.35,1a.84.84,0,0,1-.59-.35c-.27-.3-.18-.68.31-1.33.69-.91.8-1.08.84-1.24s.09-.08.17-.08c.29,0,.79.09.79.43,0,.53-.79,1.17-1.11,1.88A4,4,0,0,0,75.87,189c.14-.23.18-.29.29-.29a2.37,2.37,0,0,1,.45.07c.18,0,.27.12.27.27a1.92,1.92,0,0,1-.39.83c-.31.51-.44.8-.38.93.34-.08.73-.39,1.23-1.07l0,0c0-.07.07-.08.09-.05l0,0a.11.11,0,0,1,0,.15l0,0c-.28.45-1.08,1.44-1.71,1.44a.69.69,0,0,1-.47-.16C75.11,191,75,190.79,75.2,190.38Z" />
        <path class="cls-10" d="M77.57,191.29a1.1,1.1,0,0,1-.26-1.85,2.66,2.66,0,0,1,1.75-1.05c.21,0,.38.07.6.4a.63.63,0,0,1,.27.47c0,.19-.16.44-.66.75a5.91,5.91,0,0,1-1.28.54,1.41,1.41,0,0,0,0,.29,1.11,1.11,0,0,0,.33,0,2.64,2.64,0,0,0,2-1.17l0,0c0-.07.07-.08.09-.05l0,0s0,.09,0,.15l0,0a3.16,3.16,0,0,1-2.32,1.55A1.09,1.09,0,0,1,77.57,191.29ZM79.45,189a2.35,2.35,0,0,0-1.32,1.16,3.91,3.91,0,0,0,.88-.47C79.36,189.44,79.47,189.24,79.45,189Zm.74-2.29s.07,0,.13,0a1.47,1.47,0,0,1,.42.28c.05.08,0,.19-.11.28a3.85,3.85,0,0,1-.92.51,1.77,1.77,0,0,1-.39.09,2.11,2.11,0,0,1-.53,0c-.09,0-.08-.06,0-.09A7.7,7.7,0,0,0,80.19,186.72Z" />
        <path class="cls-10" d="M79.68,191.57a.87.87,0,0,1-.18-.26c0-.07,0-.12,0-.14.79-.27,1.17-.64,1.17-.89s-.51-.52-.51-.94a1,1,0,0,1,.61-.83,1.27,1.27,0,0,1,.57-.19.63.63,0,0,1,.56.35c.19.29.17.39.09.42-.56.17-1.15.51-1.15.79s.49.36.49.85a.94.94,0,0,1-.48.77,1.5,1.5,0,0,1-.77.26A.56.56,0,0,1,79.68,191.57Z" />
        <path class="cls-10" d="M66.86,198.2H65.08l.92-1.12c.09-.11.23-.28.32-.41a.7.7,0,0,0,.14-.38.41.41,0,0,0-.45-.43.48.48,0,0,0-.47.48h-.39a1,1,0,0,1,.23-.58.77.77,0,0,1,.62-.26.79.79,0,0,1,.85.8,1,1,0,0,1-.19.56,4.13,4.13,0,0,1-.38.47l-.41.49h1Z" />
        <path class="cls-10" d="M67.74,198a1.89,1.89,0,0,1,0-2.16.73.73,0,0,1,.6-.29.76.76,0,0,1,.6.29,1.89,1.89,0,0,1,0,2.16.77.77,0,0,1-.6.29A.76.76,0,0,1,67.74,198Zm.24-1.87a1.48,1.48,0,0,0-.18.79,1.5,1.5,0,0,0,.18.79.42.42,0,0,0,.36.22.43.43,0,0,0,.36-.22,1.6,1.6,0,0,0,.18-.79,1.58,1.58,0,0,0-.18-.79.43.43,0,0,0-.36-.22A.42.42,0,0,0,68,196.08Z" />
        <path class="cls-10" d="M70,198a1.89,1.89,0,0,1,0-2.16.76.76,0,0,1,.6-.29.73.73,0,0,1,.6.29,1.89,1.89,0,0,1,0,2.16.76.76,0,0,1-.6.29A.77.77,0,0,1,70,198Zm.24-1.87a1.58,1.58,0,0,0-.18.79,1.6,1.6,0,0,0,.18.79.43.43,0,0,0,.36.22.42.42,0,0,0,.36-.22,1.5,1.5,0,0,0,.18-.79,1.48,1.48,0,0,0-.18-.79.42.42,0,0,0-.36-.22A.43.43,0,0,0,70.24,196.08Z" />
        <path class="cls-10" d="M72.32,198.07l.62-.8-.14,0a.84.84,0,0,1-.62-.25.82.82,0,0,1-.24-.61.94.94,0,0,1,.26-.66.9.9,0,0,1,.68-.27,1,1,0,0,1,.69.26.86.86,0,0,1,.23.6,1.14,1.14,0,0,1-.13.51,2.42,2.42,0,0,1-.32.47l-.74.95Zm.92-1.29a.57.57,0,0,0,.16-.39.51.51,0,0,0-.16-.38.53.53,0,0,0-.37-.15.55.55,0,0,0-.38.15.56.56,0,0,0-.16.39.55.55,0,0,0,.16.39.52.52,0,0,0,.37.15A.51.51,0,0,0,73.24,196.78Z" />
      </g>
      <g id="dollar-1">
        <path class="dollar-1" d="M315.81,178.98c-.14-8.74-.65-20.32-2.3-27.79-4.34-19.68-32.8-16.05-36.41-12.37,0,0,13.39,4.7,15.82,13.8,1.65,6.18-22.24,10.81-22.78,29.86"/>
      </g>
      <g id="dollar-2">
        <path class="dollar-1" d="M315.07,209.18c-1.38-2.45-3.12-4.43-5.22-5.95-2.1-1.52-4.37-2.71-6.79-3.58-2.42-.87-4.69-1.77-6.8-2.69-2.11-.93-3.86-2.02-5.25-3.28-1.39-1.26-2.07-2.98-2.05-5.17,.02-1.43,.47-2.75,1.35-3.96,.89-1.21,2.26-1.8,4.13-1.78,1.68,.02,2.92,.73,3.72,2.14,.79,1.41,1.18,3.05,1.16,4.92v1.4l16.26,.18c0-.2,0-.38,0-.57l.21,.11s.66-26.44-2.28-39.76c-4.34-19.68-32.8-16.05-36.41-12.37,0,0,13.39,4.7,15.82,13.8,1.65,6.18-22.24,10.81-22.78,29.86-.21,7.34,.94,11.6,2.44,14.07,.32,1.48,.79,2.81,1.43,3.96,1.31,2.38,2.98,4.32,4.99,5.81,2.01,1.49,4.19,2.7,6.55,3.63,2.36,.93,4.55,1.86,6.56,2.78"/>
      </g>
      <g id="dollar-3">
        <path class="dollar-1" d="M315.07,209.18c-1.38-2.45-3.12-4.43-5.22-5.95-2.1-1.52-4.37-2.71-6.79-3.58-2.42-.87-4.69-1.77-6.8-2.69-2.11-.93-3.86-2.02-5.25-3.28-1.39-1.26-2.07-2.98-2.05-5.17,.02-1.43,.47-2.75,1.35-3.96,.89-1.21,2.26-1.8,4.13-1.78,1.68,.02,2.92,.73,3.72,2.14,.79,1.41,1.18,3.05,1.16,4.92v1.4l16.26,.18c0-.2,0-.38,0-.57l.21,.11s.66-26.44-2.28-39.76c-4.34-19.68-32.8-16.05-36.41-12.37,0,0,13.39,4.7,15.82,13.8,1.65,6.18-22.24,10.81-22.78,29.86-.21,7.34,.94,11.6,2.44,14.07,.32,1.48,.79,2.81,1.43,3.96,1.31,2.38,2.98,4.32,4.99,5.81,2.01,1.49,4.19,2.7,6.55,3.63,2.36,.93,4.55,1.86,6.56,2.78,2.02,.93,3.69,2.02,5.02,3.28,1.33,1.26,1.98,2.95,1.96,5.07-.02,1.68-.52,3.11-1.5,4.29-.98,1.17-2.37,1.75-4.18,1.73-1.75-.02-3.14-.56-4.19-1.64-1.05-1.07-1.56-3.01-1.53-5.82l.02-1.78-16.84-.18-.03,2.53c-.06,5.92,1.42,10.26,4.44,13,3.03,2.75,7.5,4.46,13.41,5.15l-.09,8.42,8.6,.09,.09-8.42c6.43-.62,11.31-2.43,14.65-5.45,3.34-3.02,5.04-7.71,5.11-14.07,.04-4.05-.62-7.3-2-9.75Z"/>
      </g>
      <g id="dollar-4">
        <path class="dollar-1" d="M275.53,233.21c-3.03-2.75-4.51-7.08-4.44-13l.03-2.53,16.84,.18-.02,1.78c-.03,2.81,.48,4.74,1.53,5.82,1.05,1.07,2.44,1.62,4.19,1.64,1.81,.02,3.2-.56,4.18-1.73,.98-1.17,1.48-2.6,1.5-4.29,.02-2.12-.63-3.81-1.96-5.07-1.33-1.26-3-2.36-5.02-3.28-2.02-.93-4.2-1.85-6.56-2.78-2.36-.93-4.55-2.14-6.55-3.63-2.01-1.49-3.67-3.42-4.99-5.81-1.32-2.38-1.95-5.48-1.91-9.28,.13-11.66,5.91-18.36,17.34-20.11l.06-5.42,8.6,.09-.06,5.42c6.23,.69,10.73,2.63,13.5,5.81,2.77,3.18,4.12,7.98,4.05,14.4l-16.27-.18v-1.4c.04-1.87-.35-3.51-1.15-4.92-.8-1.41-2.04-2.13-3.72-2.14-1.87-.02-3.25,.57-4.13,1.78-.89,1.21-1.34,2.53-1.35,3.96-.02,2.18,.66,3.91,2.05,5.17,1.39,1.26,3.14,2.36,5.25,3.28,2.11,.93,4.38,1.82,6.8,2.69,2.42,.87,4.69,2.06,6.79,3.58,2.1,1.52,3.84,3.5,5.22,5.95,1.38,2.45,2.04,5.7,2,9.75-.07,6.36-1.77,11.05-5.11,14.07-3.34,3.02-8.22,4.84-14.65,5.45l-.09,8.42-8.6-.09,.09-8.42c-5.92-.69-10.39-2.4-13.41-5.15Z"/>
      </g>
      <g id="dollar-5">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39"/>
      </g>
      <g id="dollar-6">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08"/>
      </g>
      <g id="dollar-7">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08h-60.95"/>
      </g>
      <g id="dollar-8">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H110.73"/>
      </g>
      <g id="dollar-9">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99"/>
      </g>
      <g id="dollar-10">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99c-8.33,0-15.08-6.75-15.08-15.08"/>
      </g>
      <g id="dollar-11">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99c-8.33,0-15.08-6.75-15.08-15.08v-117.91"/>
      </g>
      <g id="dollar-12">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99c-8.33,0-15.08-6.75-15.08-15.08V71.59"/>
      </g>
      <g id="dollar-13">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99c-8.33,0-15.08-6.75-15.08-15.08V71.59c0-8.33,6.75-15.08,15.08-15.08H124.75"/>
      </g>
      <g id="dollar-14">
        <path class="dollar-1 pink-border" d="M293.12,246.78v46.39c0,8.33-6.75,15.08-15.08,15.08H45.99c-8.33,0-15.08-6.75-15.08-15.08V71.59c0-8.33,6.75-15.08,15.08-15.08H237.49"/>
      </g>
      <g id="dollar-15">
        <path class="dollar-1 pink-border" d="M293.47,71.59c0-8.33-6.75-15.08-15.08-15.08H45.99c-8.33,0-15.08,6.75-15.08,15.08v221.58c0,8.33,6.75,15.08,15.08,15.08h232.05c8.33,0,15.08-6.75,15.08-15.08v-46.39"/>
      </g>
      <g id="dollar-16">
        <path class="dollar-1 pink-border" d="M294.00,165.66V71.59c0-8.33-6.75-15.08-15.08-15.08H45.99c-8.33,0-15.08,6.75-15.08,15.08v221.58c0,8.33,6.75,15.08,15.08,15.08h232.05c8.33,0,15.08-6.75,15.08-15.08v-46.39"/>
      </g>
      <g id="bottle-1">
        <path class="cls-11" d="M174.73,195.66q-.06-13.84-.11-27.7c0-7.07-1.94-13.43-6.19-18.66-5.68-7-10.92-15.08-10.88-24.54,0-1.74,0-13.82,0-17a2.4,2.4,0,0,0,1.88-2.34v-2.1a2.41,2.41,0,0,0-2.42-2.41H140.28a2.41,2.41,0,0,0-2.41,2.41v2.1a2.42,2.42,0,0,0,1,2c0,2.3,0,15.84,0,17.77a48.33,48.33,0,0,1-.28,5.78c-.78,6.36-5.07,12.27-9.12,17-5.1,5.92-7.33,13-7.28,21.17q.15,27.12.23,54.24.12,28,.23,56.07c0,3.34.69,4.1,3.64,4.09l45.18-.19c2.94,0,3.6-.78,3.59-4.14Q174.9,237.38,174.73,195.66Z" />
        <path class="cls-4" d="M140.21,107.23v17.84a47.52,47.52,0,0,1-.31,5.91c-.87,6.88-5.51,13-9.46,17.54-4.79,5.51-7.11,12.18-7.09,20.39q0,18.56,0,37.1v75.21h1.14V206q0-18.54,0-37.1c0-7.92,2.21-14.34,6.81-19.64,4.06-4.67,8.83-11,9.74-18.14a52.34,52.34,0,0,0,.32-6.06V107.24Z" />
        <path class="cls-12" id="cork" d="M156.26,91.11c0-2.23-.69-3-2.68-3h-9.83c-2.07,0-2.73.76-2.73,3.09v8h15.24C156.28,93.24,156.26,97.07,156.26,91.11Z" />
        <polygon class="cls-13" points="129.56 173.87 169.97 173.87 169.97 238.26 128.84 238.26 129.56 173.87" />
        <polygon class="cls-14" points="134.03 178.52 165.57 178.52 165.57 233.36 133.48 233.36 134.03 178.52" />
        <g class="cls-15">
          <path class="cls-1" d="M138.93,184.76c.28,0,.43.28.43.83a10.06,10.06,0,0,1-.68,2.63,21.1,21.1,0,0,1-1,2.25,15.92,15.92,0,0,1-1.52,2,4.13,4.13,0,0,1-.4.28.49.49,0,0,0,0,.12v.05a3.4,3.4,0,0,0,.71,2,1.42,1.42,0,0,0,1.1.42c.6,0,1.29-.48,2.06-1.44a6.56,6.56,0,0,0,.77-1.35c.11-.14.21-.21.31-.21a.3.3,0,0,1,.28.32,6.49,6.49,0,0,1-.92,1.61,6.22,6.22,0,0,1-1.61,1.4,1.91,1.91,0,0,1-.84.19h-.29c-1.16,0-1.87-.93-2.14-2.81a8.08,8.08,0,0,1,.47-3.11,9,9,0,0,1,.43-1c.18-.46.72-1.53,1.62-3.2a2.57,2.57,0,0,1,1-.95A1.09,1.09,0,0,1,138.93,184.76Zm-3.22,7.17v.1c0,.07,0,.11-.05.1s.31-.24.63-.72a8.9,8.9,0,0,0,1.36-2.21,17,17,0,0,0,1-2.79,6.06,6.06,0,0,0,.12-.75v0a.67.67,0,0,0,0-.25h0a1.85,1.85,0,0,0-.58.64c-.13.17-.66,1.18-1.59,3A8.82,8.82,0,0,0,135.71,191.93Z" />
          <path class="cls-1" d="M141.27,192.2a.86.86,0,0,1,.89.51.84.84,0,0,1,.08.38,2,2,0,0,1-.07.52s0,0,.08,0h.26a5,5,0,0,0,2.94-1.22.75.75,0,0,1,.37-.14h.06a.27.27,0,0,1,.16.25c0,.14-.26.37-.74.68a5.54,5.54,0,0,1-3.12,1l-.18,0c-.06,0-.15.11-.29.32a1.55,1.55,0,0,1-1.21.65h-.15a.83.83,0,0,1-.81-.66,2.11,2.11,0,0,1,0-.26v-.09a2.16,2.16,0,0,1,1.12-1.81A1.91,1.91,0,0,1,141.27,192.2Zm-1.23,2a.48.48,0,0,0,.28.49.29.29,0,0,0,.14,0c.29,0,.57-.2.86-.6v0a2.85,2.85,0,0,0-.39-.21,1.17,1.17,0,0,1-.5-.68h0a1.72,1.72,0,0,0-.37.72S140.07,194,140,194.15Zm1-1.08c0,.14.12.27.36.4a.38.38,0,0,0,.18.07h0a3.48,3.48,0,0,0,.08-.43c0-.25-.1-.38-.3-.38S141,192.87,141,193.07Z" />
          <path class="cls-1" d="M147.9,191.21a.27.27,0,0,1,.25.29,6,6,0,0,1-.46.72,5.65,5.65,0,0,0-.56,1.94c0,.49.17.8.44.92a.28.28,0,0,0,.12,0h.06a2,2,0,0,0,1.12-.56,9.56,9.56,0,0,0,1.21-1.6c.17-.39.35-.59.53-.59s.27.14.27.28v0a9.68,9.68,0,0,1-.87,1.41,4.44,4.44,0,0,1-1.35,1.3,2.41,2.41,0,0,1-.91.26h0a1.24,1.24,0,0,1-1.07-.81,1.74,1.74,0,0,1-.09-.64,4.31,4.31,0,0,1,.41-1.73l.12-.35v-.09s0,.08.07.08h0a2.26,2.26,0,0,0-.73.23,1.67,1.67,0,0,0-.54.36.4.4,0,0,1-.39-.3l.16-.26a1.64,1.64,0,0,1,.48-.26,15.25,15.25,0,0,0,1.65-.69Z" />
          <path class="cls-1" d="M152.45,191h.08c.42.05.63.21.63.47a2,2,0,0,1-1,1.12,13.68,13.68,0,0,1-1.32.45s0,0,0,0v0a1.36,1.36,0,0,0,.56.81,2,2,0,0,0,.9.2h.16a4.32,4.32,0,0,0,3.11-1.44c.15-.25.28-.38.41-.38h0c.12,0,.22.08.28.25s-.56.8-1.45,1.45a5.64,5.64,0,0,1-2.1.61l-.46,0a3.7,3.7,0,0,1-1.14-.28,3.23,3.23,0,0,1-.73-.81c-.06,0-.12-.18-.16-.55a2.42,2.42,0,0,1,.48-1.05,3.06,3.06,0,0,1,1.17-.79A1.39,1.39,0,0,1,152.45,191ZM151,192.42h0a2.7,2.7,0,0,0,.92-.31c.43-.32.65-.54.65-.64s0,0-.05,0h0a2.24,2.24,0,0,0-1.26.73A.8.8,0,0,0,151,192.42Z" />
          <path class="cls-1" d="M156.06,192.05c.15,0,.23.1.23.18v.05a4.17,4.17,0,0,1-.33.72,3.55,3.55,0,0,0-.2.56v-.06s.05-.1.29-.32a1.36,1.36,0,0,1,.63-.26h.17c.38,0,.63.25.76.74h-.06a3.06,3.06,0,0,0,.26-.38,1.18,1.18,0,0,1,.78-.34,1,1,0,0,1,.86.6,1.71,1.71,0,0,0,1.2.88h0a1.9,1.9,0,0,0,1.36-.64,3.16,3.16,0,0,0,.78-.87c.19-.37.37-.55.54-.55h0c.13,0,.22.08.27.25a4.38,4.38,0,0,1-.91,1.31,3,3,0,0,1-1.83,1h-.24a2.21,2.21,0,0,1-1.53-.82c-.28-.44-.46-.66-.55-.66h0c-.24,0-.46.29-.67.87s-.33.66-.41.66l-.09,0c-.22,0-.34-.29-.37-.88s-.14-.67-.25-.67,0,0,0,0h0c-.27,0-.79.56-1.56,1.67-.15.16-.27.24-.35.24h0a.27.27,0,0,1-.27-.29,21.72,21.72,0,0,1,1.09-2.57C155.75,192.19,155.9,192.05,156.06,192.05Z" />
          <path class="cls-1" d="M139.8,213.4a.45.45,0,0,1,.51.52s0,.05,0,.15c-.05.58-.09,1-.12,1.25,0-.07,0-.1,0-.1a16,16,0,0,1-1,3.73,3.34,3.34,0,0,0-.38.86v0a1.29,1.29,0,0,0,1.34.73h.15a4.32,4.32,0,0,0,3.12-1.44c.14-.26.28-.38.41-.38h0c.12,0,.21.08.28.25s-.57.8-1.46,1.45a5.73,5.73,0,0,1-2.1.61l-.45,0a3.85,3.85,0,0,1-1.11-.27,2.24,2.24,0,0,1-.54-.46c.05,0,0,.07,0,.17a2.78,2.78,0,0,1-1.61,1.07,1.31,1.31,0,0,1-.58.09,1.59,1.59,0,0,1-1.64-1.17,1,1,0,0,1,0-.17v-.19a2.53,2.53,0,0,1,.85-1.62A2.77,2.77,0,0,1,137,218l.44,0,.81.22c-.28.23-.39.23-.34,0,0,0,0-.08,0-.2a7.64,7.64,0,0,1,.54-2.58l.53-1.11a2.52,2.52,0,0,1,.64-.87A.85.85,0,0,1,139.8,213.4Zm-4.62,6.76a1,1,0,0,0,.77,1l.26,0a2.52,2.52,0,0,0,1.81-1.06,1.09,1.09,0,0,0,.14-.33,7,7,0,0,1-.26-1.14l-.32,0a.68.68,0,0,0-.41-.11,2,2,0,0,0-1.94,1.32A2.12,2.12,0,0,0,135.18,220.16Zm3.29-2.76c0-.07,0-.1,0-.1s0,.38-.05.82l.09.93s.06-.07.11-.22a19.15,19.15,0,0,0,1-3.32l.08-1.45s0-.06,0-.06a5.24,5.24,0,0,0-.79,1.47A8.46,8.46,0,0,0,138.47,217.4Z" />
          <path class="cls-1" d="M144.56,218.65a.87.87,0,0,1,.9.51,1,1,0,0,1,.07.38,2.61,2.61,0,0,1-.06.53s0,.05.08.05h.25a5,5,0,0,0,2.94-1.23.78.78,0,0,1,.37-.14h.06a.27.27,0,0,1,.16.25c0,.14-.26.37-.74.68a5.54,5.54,0,0,1-3.12,1h-.18c-.06,0-.15.1-.29.31a1.55,1.55,0,0,1-1.21.66h-.15a.82.82,0,0,1-.8-.66,1.14,1.14,0,0,1,0-.27v-.08a2.16,2.16,0,0,1,1.11-1.82A1.91,1.91,0,0,1,144.56,218.65Zm-1.22,2a.48.48,0,0,0,.28.49l.14,0a1.14,1.14,0,0,0,.85-.6v-.05a2.5,2.5,0,0,0-.38-.2,1.12,1.12,0,0,1-.5-.69l0,0h0c-.09,0-.21.27-.38.71T143.34,220.61Zm1-1.08c0,.13.12.27.36.4a.56.56,0,0,0,.19.07h0a2,2,0,0,0,.08-.43c0-.25-.1-.38-.29-.38S144.32,219.32,144.32,219.53Z" />
          <path class="cls-1" d="M152.2,212h.05a.45.45,0,0,1,.26.45v0a12.16,12.16,0,0,1-1.12,3.59,19.4,19.4,0,0,1-1.66,2.92.65.65,0,0,0-.24.44v.05a1.72,1.72,0,0,0,.33.77,1.12,1.12,0,0,0,.74.34h.22a3.62,3.62,0,0,0,1.3-.5c.5-.45.86-.78,1.06-1s.26-.26.37-.26h.06a.29.29,0,0,1,.2.27c0,.2-.46.63-1.27,1.3a3.22,3.22,0,0,1-1.9.69,1.71,1.71,0,0,1-1.71-1.41,2.91,2.91,0,0,1-.07-.43,5.3,5.3,0,0,1,.42-2,2.58,2.58,0,0,1,.29-.75,15.84,15.84,0,0,1,1.26-2.88,7.27,7.27,0,0,1,.54-.85,3.53,3.53,0,0,0,.25-.42A.81.81,0,0,1,152.2,212Zm-2.74,6.37a.14.14,0,0,1,0,.09l0,0h0a11.85,11.85,0,0,0,.74-1.19,21.65,21.65,0,0,0,1.36-3.05,7.54,7.54,0,0,0,.33-1.34c0-.1,0-.13,0-.09h.08a5.77,5.77,0,0,0-.89,1.48,24.62,24.62,0,0,0-1.34,3.26C149.6,217.82,149.52,218.09,149.46,218.36Z" />
          <path class="cls-1" d="M154.23,218.65a.87.87,0,0,1,.9.51,1,1,0,0,1,.07.38,2.61,2.61,0,0,1-.06.53s0,.05.08.05h.25a5,5,0,0,0,2.94-1.23.78.78,0,0,1,.37-.14h.06a.27.27,0,0,1,.16.25c0,.14-.26.37-.74.68a5.54,5.54,0,0,1-3.12,1H155c-.05,0-.15.1-.29.31a1.55,1.55,0,0,1-1.21.66h-.15a.82.82,0,0,1-.8-.66,1.14,1.14,0,0,1,0-.27v-.08a2.16,2.16,0,0,1,1.11-1.82A1.91,1.91,0,0,1,154.23,218.65Zm-1.22,2a.48.48,0,0,0,.28.49l.14,0a1.14,1.14,0,0,0,.85-.6v-.05a2.5,2.5,0,0,0-.38-.2,1.12,1.12,0,0,1-.5-.69l0,0h0c-.09,0-.21.27-.38.71T153,220.61Zm1-1.08c0,.13.11.27.35.4a.56.56,0,0,0,.19.07h0a2,2,0,0,0,.08-.43c0-.25-.1-.38-.29-.38S154,219.32,154,219.53Z" />
          <path class="cls-1" d="M160.86,217.67a.26.26,0,0,1,.25.28,6.67,6.67,0,0,1-.45.73,5.44,5.44,0,0,0-.57,1.94c0,.48.18.79.44.91a.24.24,0,0,0,.12,0h.07a2,2,0,0,0,1.12-.55,9.24,9.24,0,0,0,1.2-1.61c.18-.39.35-.58.54-.58s.26.14.26.27v.05a9.68,9.68,0,0,1-.87,1.41,4.46,4.46,0,0,1-1.35,1.31,2.37,2.37,0,0,1-.91.25h0a1.22,1.22,0,0,1-1.06-.81,1.49,1.49,0,0,1-.1-.64,4.13,4.13,0,0,1,.42-1.72l.11-.36v-.08c0,.05,0,.07.07.07h0a2.48,2.48,0,0,0-.73.23,2.29,2.29,0,0,0-.55.36.38.38,0,0,1-.39-.3l.16-.25a1.5,1.5,0,0,1,.48-.27,12.43,12.43,0,0,0,1.65-.68Z" />
        </g>
        <path class="cls-16" d="M139.6,202.26a1.07,1.07,0,0,1,.88.55,3.9,3.9,0,0,0,.53,1.06,5.34,5.34,0,0,0,.69-.32,3.24,3.24,0,0,1,1.25-.27c.25,0,.38.09.38.2v.05c0,.12-.07.18-.22.21h-.28a3.57,3.57,0,0,0-1.54.54s0,0,0,0v.09s.09.24.27.66a10.69,10.69,0,0,1,.58,2.65v.25a3.41,3.41,0,0,1-.37,1.61,2.34,2.34,0,0,1-.61.87,1.54,1.54,0,0,1-.91.29h0a2,2,0,0,1-1.74-1.24,2.17,2.17,0,0,1-.26-1v-.27a5,5,0,0,1,1.61-3.33,7.09,7.09,0,0,1,.82-.65l0,0a.68.68,0,0,0-.21-.38h0l-.23.47a.87.87,0,0,1-.76.44h-.09a1.06,1.06,0,0,1-.91-.56,1,1,0,0,1-.08-.43v-.05a1.65,1.65,0,0,1,.72-1.21A1.07,1.07,0,0,1,139.6,202.26Zm-.95,6a2,2,0,0,0,.43,1.35,1.35,1.35,0,0,0,1.15.65c.65,0,1.11-.59,1.36-1.78a2.77,2.77,0,0,0,.07-.69,9.38,9.38,0,0,0-.6-2.81,2.46,2.46,0,0,0-.21-.44c-.29.06-.73.47-1.32,1.21A4.26,4.26,0,0,0,138.65,208.29Zm.17-4.58v0c0,.25.2.42.6.5h.06c.18,0,.36-.22.53-.67a2.17,2.17,0,0,0,.06-.48c-.07-.31-.19-.47-.36-.47h-.1c-.21,0-.42.18-.65.52A1.16,1.16,0,0,0,138.82,203.71Z" />
        <path class="cls-16" d="M144.84,202.39a.21.21,0,0,1,.2.23,4.76,4.76,0,0,1-.37.58,4.42,4.42,0,0,0-.45,1.55c0,.4.14.64.35.74l.1,0h.05a1.66,1.66,0,0,0,.9-.44,8.15,8.15,0,0,0,1-1.29c.13-.31.28-.47.42-.47s.21.11.21.22v0a6.62,6.62,0,0,1-.69,1.13,3.62,3.62,0,0,1-1.08,1,2.06,2.06,0,0,1-.74.2h0a1,1,0,0,1-.85-.65,1.29,1.29,0,0,1-.08-.51,3.22,3.22,0,0,1,.34-1.38l.08-.29v-.07a.05.05,0,0,0,.06.06h0a1.76,1.76,0,0,0-.59.19,1.55,1.55,0,0,0-.44.29.32.32,0,0,1-.31-.24l.13-.21a1.2,1.2,0,0,1,.39-.21,10.65,10.65,0,0,0,1.31-.55Z" />
        <path class="cls-16" d="M148.39,202.3h.19a.57.57,0,0,1,.56.35,5.92,5.92,0,0,1,.18.77.91.91,0,0,0,.9.6,2.09,2.09,0,0,0,1.37-.72l.21,0c.11.05.16.11.16.19s-.11.26-.33.41a2.68,2.68,0,0,1-1.13.57h-.29a1.37,1.37,0,0,1-1.32-.83l0,0a4.7,4.7,0,0,1-.22.42,1.43,1.43,0,0,1-1.12.69h0a.75.75,0,0,1-.7-.52.91.91,0,0,1-.05-.26,1.84,1.84,0,0,1,.59-1.2A1.51,1.51,0,0,1,148.39,202.3Zm-1.2,1.57a.4.4,0,0,0,.3.43h.07c.32,0,.65-.34,1-1a1.73,1.73,0,0,0,.14-.47v-.08h-.21a1.23,1.23,0,0,0-1.13.72A.82.82,0,0,0,147.19,203.87Z" />
        <path class="cls-16" d="M152.77,203.07a1.61,1.61,0,0,1,1.12.44H154l.51-.19a8.66,8.66,0,0,1,1.17-.12c.25,0,.38.11.38.26s-.07.17-.21.19h-.47a2.56,2.56,0,0,0-1.27.33,2.19,2.19,0,0,0,0,.53c-.18.74-.49,1.11-.94,1.11s-.57-.24-.57-.55a1.45,1.45,0,0,1,.41-.86l.48-.39v0s-.08-.11-.23-.2a.88.88,0,0,0-.34-.08h0a.69.69,0,0,0-.71.43,1.32,1.32,0,0,0,0,.13c.06.68.1,1.45.1,2.32a9,9,0,0,1-.4,2.62c-.18.51-.38.8-.58.88a.88.88,0,0,1-.5.17c-.1,0-.2-.08-.31-.24a1.56,1.56,0,0,1-.13-.77,11.52,11.52,0,0,1,.66-3.06c.24-.77.38-1.26.42-1.46a2.8,2.8,0,0,0,.23-.94c0-.09.11-.16.32-.21a2,2,0,0,0,.34-.19A1.47,1.47,0,0,1,152.77,203.07Zm-2,6c0,.37.05.56.11.56a.4.4,0,0,0,.26-.19,5.64,5.64,0,0,0,.47-1.54l.09-.6c0,.06,0,.08,0,.08a8,8,0,0,0,.08-1.09v-.1a6.55,6.55,0,0,0-.06-1.2s-.07.32-.24.95a13.78,13.78,0,0,0-.54,2A4.72,4.72,0,0,0,150.73,209Zm2.31-4v.07c0,.06,0,.09.07.09a.59.59,0,0,0,.27-.27,1.41,1.41,0,0,0,.17-.58h0a.88.88,0,0,0-.42.37A.68.68,0,0,0,153,205Z" />
        <path class="cls-16" d="M157.28,202.18h.06c.34,0,.51.17.51.37s-.32.6-.8.91c-.11,0-.46.17-1.05.35,0,0,0,0,0,0h0a1.09,1.09,0,0,0,.45.65,1.54,1.54,0,0,0,.73.17h.12a3.49,3.49,0,0,0,2.5-1.16c.11-.2.22-.31.33-.31h0c.1,0,.17.07.23.21s-.46.64-1.17,1.16a4.59,4.59,0,0,1-1.68.48l-.37,0a2.85,2.85,0,0,1-.92-.23,2.48,2.48,0,0,1-.58-.64c-.05,0-.09-.15-.13-.45a1.9,1.9,0,0,1,.39-.84,2.28,2.28,0,0,1,.94-.63A1.09,1.09,0,0,1,157.28,202.18Zm-1.17,1.18s0,0,0,0h0a2.09,2.09,0,0,0,.73-.25c.35-.26.52-.43.52-.51a0,0,0,0,0,0,0h0a1.9,1.9,0,0,0-1,.59A.62.62,0,0,0,156.11,203.36Z" />
      </g>
      <g id="glass">
        <path class="cls-17" d="M115.83,303.23s-44.62.47-46.93-1.41-9.59-9.91,9.6-8,52.58-.94,53,4.24S127,303.23,115.83,303.23Z" />
        <path class="cls-18" d="M108.47,237.45h.09s25.14.41,27.32-24.52c2-22.45-13.56-45.72-13.56-45.72H89.19s-15.53,23.27-13.56,45.72c2.18,24.93,27.31,24.52,27.31,24.52h.1v51.83a3,3,0,0,0,0,.31H86.33c-5.93,0-6.18,8.6-6.18,8.6h51.21s-.25-8.6-6.19-8.6H108.44a3,3,0,0,0,0-.31Z" />
        <path class="cls-19" d="M131.92,199H79.52S70,234.09,105.17,234.82h1.11C141.47,234.09,131.92,199,131.92,199Z" />
        <path class="cls-20" d="M90.8,179.77c-4,7.5-10.61,23.4-6.07,41.47h5.11a1.85,1.85,0,0,0-.07-.41c-5.11-18.89,3.68-35.85,6.81-41.06Z" />
      </g>
      <g id="grapes">
        <path class="cls-17" d="M28,286.72s4.65,9.92,11.26,11.36,9.5,7.87,18.21,8a19,19,0,0,1,13.64,6.25s14.2,7.73,26.45,5.26,2.15-20.31-.49-21-30.09-20.25-41.44-21S22.31,272.9,28,286.72Z" />
        <path class="cls-21" d="M74.92,264.52s-12.05-6.77-23.05-8.43a16.45,16.45,0,0,1,2.34-1,18.53,18.53,0,0,1,7-.62l1.39-3L64,249.18l-.77-2.43-.42-2.35L61,243.71l-3.43-2.58-1.65.58-3-4L52.24,240,51,237.7H49.69l-2.31-1.38-4.9-1.23-1.65,9.09-1.66-1.38-4.74-2.58-.35,1.23-2.81-.77-2,1.16L17.59,241l3.47,6,1.35,4.74,1.74,1,.11,3.08,2,2.77-2.74,1.51,3.13.69,9.28-.85-2.42,2.08s-5.4,3.32-5.51,4.17-4.24,4.66-5.78,5.05-.31,2,1.39,2.27,9.52.73,9.52.73l.35,2.7,3.77-.62s-.5,4.54.74,4.24,6.32-4.21,6.32-4.21L45.4,282l5.89-3.28,1.46-3.32s1.86,1.51,2.47,1.35.85-5.78.85-5.78L58,272.16s2.74-6.1,0-7.87a68.7,68.7,0,0,1-7-5.77,2,2,0,0,1-.37-.52c3.14.61,10.32,2.43,21.85,7.76Z" />
        <circle class="cls-22" cx="49.1" cy="264.53" r="9.96" />
        <circle class="cls-23" cx="65.12" cy="255.06" r="10.66" />
        <circle class="cls-24" cx="81.79" cy="245.13" r="10" />
        <circle class="cls-25" cx="82.35" cy="264.35" r="12.28" />
        <circle class="cls-26" cx="53.07" cy="279.91" r="8.13" />
        <circle class="cls-27" cx="65.25" cy="273" r="10.69" />
        <circle class="cls-28" cx="66.34" cy="291.48" r="10.76" />
        <circle class="cls-29" cx="80.81" cy="282.64" r="10.55" />
        <circle class="cls-30" cx="97.36" cy="274.8" r="9.49" />
        <circle class="cls-31" cx="82.12" cy="299.68" r="8.13" />
        <circle class="cls-32" cx="96.2" cy="292.93" r="11.31" />
        <circle class="cls-33" cx="95.59" cy="308.91" r="10.33" />
      </g>
      <!-- <g id="dollar-2">
        <path class="cls-7" d="M275.53,233.21q-4.54-4.11-4.44-13l0-2.53,16.83.19,0,1.77q0,4.22,1.53,5.82a5.69,5.69,0,0,0,4.19,1.64,5,5,0,0,0,4.18-1.74,7.23,7.23,0,0,0-.46-9.36,18.51,18.51,0,0,0-5-3.28q-3-1.38-6.57-2.78a28.47,28.47,0,0,1-6.55-3.63,17.66,17.66,0,0,1-5-5.8,18.69,18.69,0,0,1-1.91-9.29q.2-17.47,17.34-20.1l.05-5.43,8.61.09-.06,5.43q9.35,1,13.5,5.81t4.05,14.4l-16.27-.18v-1.4a9.64,9.64,0,0,0-1.16-4.93,4,4,0,0,0-3.72-2.14,4.7,4.7,0,0,0-4.13,1.78,6.6,6.6,0,0,0-1.35,4,6.52,6.52,0,0,0,2,5.17A19.38,19.38,0,0,0,296.5,197c2.11.93,4.38,1.82,6.8,2.69a27.41,27.41,0,0,1,6.79,3.58,18.28,18.28,0,0,1,5.22,6c1.38,2.45,2,5.7,2,9.75q-.1,9.54-5.11,14.07t-14.65,5.46l-.09,8.41-8.61-.09.09-8.42Q280.07,237.33,275.53,233.21Z"/>
      </g> -->
      <g id="tresor-front-1" data-name="-front-tresor">
        <rect class="cls-3" x="50.93" y="72.84" width="235.21" height="223.82" rx="10.07"/>
        <rect class="cls-4" x="51.22" y="69.3" width="231.06" height="223.82" rx="10.07"/>
        <rect class="cls-5" x="40.19" y="117.41" width="21.47" height="44.12" rx="2.16"/>
        <rect class="cls-6" x="40.19" y="200.89" width="21.47" height="44.12" rx="2.16"/>
        <path class="cls-3 settings-1" d="M203.74,198.37c0-3.88-.46-4.34-4.38-4.34s-3.7-.21-5.59-3.6c-2.17-3.86-2.17-6.7,1.68-9.34,1.52-1,1.34-2.77.05-4.12-1.71-1.78-3.44-3.54-5.23-5.23a2.62,2.62,0,0,0-3.86,0c-.62.53-1.18,1.15-1.76,1.73-2.41,2.39-2.39,2.35-5.54.95-1.8-.8-4.37-.93-5.33-2.27s-.29-3.79-.44-5.75a2.66,2.66,0,0,0-2.9-2.78c-2.4,0-4.79,0-7.19,0-2.05,0-3,1-3.08,3,0,1.51.07,3,0,4.52a2.08,2.08,0,0,1-.93,1.47c-2.56,1.1-5.18,2.06-8.26,3.26-1-1.13-2.2-2.55-3.5-3.85-1.48-1.49-3-1.47-4.48,0s-2.91,2.9-4.35,4.36c-2.1,2.15-2.09,3.18,0,5.34,1.1,1.13,2.83,2.14,3.09,3.44s-1.37,2.75-1.58,4.23c-.62,4.23-3,5.2-6.93,4.7-2.43-.32-3.45.89-3.53,3.41,0,1.84,0,3.69,0,5.54,0,3.29.87,4.12,4.14,4.13,4.46,0,4,.25,6.15,4s1.74,6.27-1.7,8.8c-1.76,1.3-1.61,2.82-.1,4.4s3.26,3.33,4.94,4.94a2.7,2.7,0,0,0,4.29-.07c1.3-1.31,2.51-2.71,3.74-4.06,1.54.75,2.7,1.66,4,1.88,4.45.73,5.76,3.26,5.12,7.51-.29,2,1,3,3,3.09,1.85.08,3.7,0,5.55,0,4,0,4.62-.66,4.62-4.66s.25-3.54,3.73-5.51c3.91-2.22,6.57-1.81,9.16,1.76,1.07,1.47,2.81,1.35,4.12.09q2.61-2.47,5.08-5.09c1.34-1.41,1.29-2.87-.09-4.32-1-1.05-2.1-2-3-3.08a2.09,2.09,0,0,1-.48-1.71c1.06-2.65,2.27-5.24,3.5-8,1.76,0,3.68.06,5.59,0a2.55,2.55,0,0,0,2.78-2.84C203.72,202.34,203.74,200.35,203.74,198.37Zm-37,18.86a16.62,16.62,0,1,1,16.68-16.52C183.51,209.62,175.83,217.22,166.76,217.23Z"/>
        <path class="cls-3" d="M166.91,192.44a8.17,8.17,0,1,0,8,8.24A8.26,8.26,0,0,0,166.91,192.44Z"/>
        <path class="cls-7 settings-2" d="M203.87,194.32c0-3.88-.46-4.34-4.38-4.34s-3.7-.21-5.6-3.6c-2.16-3.86-2.16-6.69,1.69-9.34,1.52-1,1.34-2.77,0-4.12-1.71-1.78-3.44-3.53-5.23-5.23a2.62,2.62,0,0,0-3.86,0c-.63.53-1.18,1.15-1.76,1.73-2.41,2.39-2.39,2.35-5.54,1-1.8-.8-4.37-.93-5.33-2.27s-.29-3.79-.44-5.75a2.66,2.66,0,0,0-2.9-2.78c-2.4,0-4.8,0-7.19,0-2,0-3,1-3.08,3-.05,1.51.07,3-.05,4.52a2.08,2.08,0,0,1-.93,1.47c-2.56,1.1-5.18,2.06-8.26,3.26-1-1.13-2.2-2.55-3.5-3.85-1.48-1.49-3-1.46-4.48,0s-2.91,2.9-4.35,4.36c-2.1,2.15-2.09,3.18,0,5.34,1.1,1.13,2.83,2.14,3.09,3.44s-1.37,2.75-1.58,4.23c-.62,4.24-3,5.2-6.93,4.7-2.44-.32-3.45.89-3.53,3.41-.06,1.85,0,3.69,0,5.54,0,3.29.86,4.12,4.14,4.13,4.46,0,4,.25,6.15,4s1.74,6.27-1.7,8.8c-1.77,1.3-1.61,2.82-.1,4.4s3.26,3.33,4.94,4.94a2.7,2.7,0,0,0,4.29-.07c1.3-1.31,2.51-2.71,3.74-4.06,1.54.75,2.7,1.66,4,1.88,4.45.73,5.75,3.26,5.12,7.51-.29,2,1,3,3,3.09,1.85.08,3.7,0,5.55,0,4,0,4.61-.66,4.62-4.65s.25-3.55,3.73-5.52c3.9-2.22,6.56-1.81,9.16,1.76,1.07,1.47,2.81,1.35,4.12.09,1.73-1.65,3.43-3.35,5.08-5.09,1.34-1.41,1.29-2.86-.1-4.32-1-1-2.09-2-3-3.07a2.12,2.12,0,0,1-.48-1.72c1.06-2.65,2.27-5.23,3.49-8,1.77,0,3.69.06,5.6,0a2.55,2.55,0,0,0,2.78-2.84C203.85,198.29,203.87,196.3,203.87,194.32Zm-37,18.86a16.62,16.62,0,1,1,16.68-16.52C183.64,205.57,176,213.17,166.89,213.18Z"/>
        <path class="cls-7" d="M167,188.39a8.17,8.17,0,1,0,8,8.24A8.25,8.25,0,0,0,167,188.39Z"/>
        <path class="cls-3" d="M131.51,113.11v-.68a.45.45,0,0,0-.45-.45h-10a.45.45,0,0,0-.45.45v.69a.44.44,0,0,0,.45.44h4.1v14.49a.48.48,0,0,0,.45.45h.9a.47.47,0,0,0,.45-.44V113.56h4.1A.45.45,0,0,0,131.51,113.11Z"/>
        <path class="cls-3" d="M135.36,118.82a3.48,3.48,0,0,0-2.83,1.25l-.19-.69a.4.4,0,0,0-.4-.33h-.45a.48.48,0,0,0-.45.45V128a.46.46,0,0,0,.45.45h.73a.47.47,0,0,0,.45-.45v-6.3a3,3,0,0,1,2.57-1.32,2.29,2.29,0,0,1,.92.19c.24.09.45.09.57-.21l.28-.81C137.25,118.93,136.07,118.82,135.36,118.82Z"/>
        <path class="cls-3" d="M142.11,118.81a4.65,4.65,0,0,0-4.62,5c0,2.71,1.77,5,4.62,5a5.83,5.83,0,0,0,3.47-1.09c.36-.26.26-.4.1-.61l-.41-.54c-.18-.26-.33-.14-.66,0a4.38,4.38,0,0,1-2.33.71,3.29,3.29,0,0,1-3.16-3.06h6.79a.42.42,0,0,0,.42-.4,4.46,4.46,0,0,0,0-.63A4.16,4.16,0,0,0,142.11,118.81Zm-3,4a2.93,2.93,0,0,1,3-2.6,2.72,2.72,0,0,1,2.64,2.59Z"/>
        <path class="cls-3" d="M151,122.84c-1-.47-1.74-.82-1.74-1.6,0-.4.28-.95,1.34-.95a4.71,4.71,0,0,1,1.89.57.52.52,0,0,0,.68-.21l.19-.38a.57.57,0,0,0-.14-.73,4.79,4.79,0,0,0-2.62-.73c-2.19,0-3,1.46-3,2.45,0,1.61,1.45,2.43,2.7,3s2.07,1.06,2.07,1.89c0,.64-.56,1.13-1.53,1.13a4,4,0,0,1-2.22-.82.42.42,0,0,0-.63.07c-.07.12-.24.45-.33.59a.49.49,0,0,0,.11.5,4.75,4.75,0,0,0,3.07,1.05c1.94,0,3.26-1.29,3.26-2.66C154.09,124.35,152.63,123.62,151,122.84Z"/>
        <path class="cls-3" d="M159.68,118.8a5,5,0,1,0,4.7,4.93A4.84,4.84,0,0,0,159.68,118.8Zm0,8.38a3.26,3.26,0,0,1-3.07-3.45,3.08,3.08,0,1,1,6.13,0A3.26,3.26,0,0,1,159.69,127.18Z"/>
        <path class="cls-3" d="M170.42,118.79a3.07,3.07,0,0,0-2.83,1.26l-.19-.69a.41.41,0,0,0-.4-.33h-.45a.47.47,0,0,0-.45.45V128a.46.46,0,0,0,.44.45h.74a.46.46,0,0,0,.44-.45v-6.3s1.16-1.71,2.69-1.38c.54.11.52.15.72-.08s.49-.58.67-.8S171.33,118.86,170.42,118.79Z"/>
        <path class="cls-3" d="M205.66,104.37l.05,0,0,0a.81.81,0,0,0,.67-.75.76.76,0,0,0-.83-.8h-.07c-8,1.66-13.74,9-18.8,15.51-3.89,5-7.25,9.32-10.54,9.32-1.47,0-1.67-.63-1.67-1.25,0-3.12,6.25-10.53,10-15,2.42-2.88,2.94-3.53,2.94-3.9A.51.51,0,0,0,187,107c-.09,0-.37,0-1.25.64a46.62,46.62,0,0,1-7,3.81c-2.7,1.2-6.57,2.64-9.7,2.64-2.38,0-3.94-.47-4.64-1.41a.57.57,0,0,0-.51-.25.81.81,0,0,0-.8.83c0,.91,2.24,2.25,5.32,2.25,3.66,0,8.67-1.73,14.91-5.14-4.87,5.74-10.11,12.74-10.11,16.2,0,1.06.47,2.31,2.73,2.31,4.13,0,7.5-4.36,11.41-9.41C192,113.33,197.37,106.43,205.66,104.37Z"/>
        <path class="cls-3" d="M211.47,125.52l-.3-.32a.34.34,0,0,0-.26-.12.36.36,0,0,0-.26.11,3.38,3.38,0,0,1-2.36,1,1.5,1.5,0,0,1-1.47-.75,2.22,2.22,0,0,0,0-.38c0-.47-.37-1.39-1-1.39a.56.56,0,0,0-.47.26.82.82,0,0,0-.2-.12.36.36,0,0,0-.32,0,10.86,10.86,0,0,0-1.88,1.51c-1.07.94-3.54,2.3-4.79,2.3-.42,0-.42-.11-.42-.24a3.32,3.32,0,0,1,.45-1.12,1.94,1.94,0,0,0,.32-.81c0-.27-.18-.77-.59-.77-.19,0-.39.11-1.77,1-.72.44-1.91,1.18-2.58,1.51.18-.34.46-.81.65-1.1.48-.78.66-1.1.66-1.35,0-.8-.54-.86-.71-.86-.76,0-1.92,1.12-2.08,1.29l-.21.18c-.14.13-.27.24-.42.36l-.05,0a7.41,7.41,0,0,1-3.75,1.64c-.27,0-.27-.07-.27-.15a5.49,5.49,0,0,1,.93-1.89c.37-.58.5-.79.5-1a1,1,0,0,0-.1-.37s0,0,0,0a.57.57,0,0,0-.5-.27c-.76,0-2.32,2.58-2.32,3.83a1.14,1.14,0,0,0,1.25,1.22,10.37,10.37,0,0,0,5.18-2.2,3.4,3.4,0,0,0-.54,1.48.73.73,0,0,0,.8.77,12.48,12.48,0,0,0,3.59-1.63l.11-.06a1.69,1.69,0,0,0,0,.38c0,.83.57,1.31,1.57,1.31a9.4,9.4,0,0,0,3.9-1.24v.17a1.15,1.15,0,0,0,1.24,1.21,4.32,4.32,0,0,0,3.28-2.1,3,3,0,0,0,1.89.64,4.86,4.86,0,0,0,3.34-1.47A.36.36,0,0,0,211.47,125.52Zm-8.29,2.24h-.06v0c0-1,1.48-2.29,2.17-2.65a3.46,3.46,0,0,0,.13.57C205.09,126.66,203.93,127.76,203.18,127.76Z"/>
        <path class="cls-3" d="M190.25,120.37a.75.75,0,0,0,.73.76c.66,0,1.25-.86,1.25-1.39a.67.67,0,0,0-.66-.73A1.54,1.54,0,0,0,190.25,120.37Z"/>
      </g>
      <g id="screen">
        <rect class="cls-8" x="266.38" y="304.71" width="59.77" height="40.48"/>
        <rect class="cls-9" x="188.84" y="155.9" width="214.87" height="165.71" rx="2"/>
        <path class="cls-10" d="M401.7,157.9V319.61H190.84V157.9H401.7m0-4H190.84a4,4,0,0,0-4,4V319.61a4,4,0,0,0,4,4H401.7a4,4,0,0,0,4-4V157.9a4,4,0,0,0-4-4Z"/>
        <line class="cls-11" x1="228.54" y1="344.92" x2="364" y2="344.92"/>
        <path class="cls-12" d="M263.21,180.53v-.65a.43.43,0,0,0-.43-.43h-9.56a.43.43,0,0,0-.43.43v.65a.43.43,0,0,0,.43.43h3.92v13.85a.45.45,0,0,0,.43.42h.86a.45.45,0,0,0,.43-.43V181h3.92A.43.43,0,0,0,263.21,180.53Z"/>
        <path class="cls-13" d="M266.89,186a3.32,3.32,0,0,0-2.7,1.2l-.18-.66a.39.39,0,0,0-.39-.31h-.43a.45.45,0,0,0-.43.43v8.16a.44.44,0,0,0,.43.43h.7a.45.45,0,0,0,.43-.43v-6a2.91,2.91,0,0,1,2.46-1.27,2.31,2.31,0,0,1,.88.18c.23.09.43.09.54-.2l.27-.77C268.7,186.1,267.57,186,266.89,186Z"/>
        <path class="cls-14" d="M273.35,186a4.75,4.75,0,0,0,0,9.47,5.6,5.6,0,0,0,3.32-1c.34-.25.24-.38.09-.59l-.39-.52c-.18-.24-.31-.13-.63.05a4.24,4.24,0,0,1-2.23.68,3.18,3.18,0,0,1-3-2.93H277a.42.42,0,0,0,.41-.38c0-.16,0-.45,0-.61A4,4,0,0,0,273.35,186Zm-2.84,3.85a2.8,2.8,0,0,1,2.84-2.48,2.6,2.6,0,0,1,2.52,2.48Z"/>
        <path class="cls-15" d="M281.85,189.84c-.92-.45-1.67-.79-1.67-1.54,0-.38.27-.9,1.29-.9a4.49,4.49,0,0,1,1.8.54.49.49,0,0,0,.65-.2l.18-.36a.54.54,0,0,0-.13-.7,4.64,4.64,0,0,0-2.51-.7c-2.09,0-2.86,1.4-2.86,2.35,0,1.53,1.38,2.32,2.57,2.91s2,1,2,1.8c0,.61-.54,1.08-1.46,1.08a3.84,3.84,0,0,1-2.13-.78.41.41,0,0,0-.6.06c-.07.12-.23.43-.32.57a.44.44,0,0,0,.11.47,4.55,4.55,0,0,0,2.94,1,2.85,2.85,0,0,0,3.11-2.55C284.81,191.28,283.41,190.58,281.85,189.84Z"/>
        <path class="cls-16" d="M290.15,186a4.75,4.75,0,1,0,4.49,4.72A4.64,4.64,0,0,0,290.15,186Zm0,8a3.28,3.28,0,1,1,2.93-3.29A3.1,3.1,0,0,1,290.15,194Z"/>
        <path class="cls-17" d="M300.41,186a3,3,0,0,0-2.7,1.2l-.18-.66a.39.39,0,0,0-.39-.31h-.43a.44.44,0,0,0-.42.42v8.17a.44.44,0,0,0,.43.43h.7a.45.45,0,0,0,.43-.43v-6s1.12-1.64,2.58-1.32c.51.11.5.14.68-.08l.65-.76C301.92,186.4,301.29,186,300.41,186Z"/>
        <path class="cls-18" d="M334.11,172.18l0,0,.06,0a.78.78,0,0,0,.64-.72.73.73,0,0,0-.79-.76H334c-7.66,1.59-13.14,8.63-18,14.84-3.72,4.78-6.93,8.91-10.08,8.91-1.4,0-1.59-.61-1.59-1.2,0-3,6-10.07,9.54-14.3,2.31-2.75,2.81-3.38,2.81-3.73a.48.48,0,0,0-.45-.53c-.09,0-.35,0-1.19.6a43.94,43.94,0,0,1-6.7,3.65c-2.58,1.15-6.28,2.52-9.28,2.52-2.27,0-3.76-.45-4.43-1.35a.54.54,0,0,0-.48-.24.78.78,0,0,0-.77.8c0,.87,2.14,2.15,5.09,2.15,3.5,0,8.29-1.65,14.25-4.92-4.65,5.5-9.67,12.18-9.67,15.49,0,1,.46,2.22,2.62,2.22,3.94,0,7.17-4.18,10.9-9C321.08,180.74,326.19,174.15,334.11,172.18Z"/>
        <path class="cls-19" d="M339.66,192.4l-.28-.31a.33.33,0,0,0-.25-.11.33.33,0,0,0-.25.1,3.23,3.23,0,0,1-2.25,1,1.44,1.44,0,0,1-1.41-.72,1.88,1.88,0,0,0,0-.36c0-.45-.35-1.33-1-1.33a.5.5,0,0,0-.45.25.76.76,0,0,0-.19-.12.34.34,0,0,0-.31,0,10.31,10.31,0,0,0-1.8,1.44c-1,.9-3.39,2.21-4.58,2.21-.4,0-.4-.11-.4-.23a3,3,0,0,1,.43-1.07,1.89,1.89,0,0,0,.3-.78c0-.26-.16-.73-.56-.73-.18,0-.37.1-1.69.92-.69.43-1.83,1.13-2.46,1.45.16-.33.44-.78.61-1.06.46-.74.64-1.05.64-1.29,0-.76-.52-.82-.68-.82-.73,0-1.84,1.07-2,1.23l-.19.17c-.14.13-.26.24-.4.35l-.05,0a7.17,7.17,0,0,1-3.59,1.57c-.25,0-.26-.07-.26-.15a5.39,5.39,0,0,1,.89-1.81c.36-.55.48-.75.48-.94a.69.69,0,0,0-.1-.35.05.05,0,0,0,0,0,.53.53,0,0,0-.48-.26c-.72,0-2.21,2.46-2.21,3.66a1.08,1.08,0,0,0,1.19,1.16,9.77,9.77,0,0,0,4.95-2.1,3.36,3.36,0,0,0-.51,1.42.7.7,0,0,0,.76.74A12,12,0,0,0,325,194l.1-.06a2.33,2.33,0,0,0,0,.37c0,.79.55,1.24,1.51,1.24a9,9,0,0,0,3.73-1.18.92.92,0,0,0,0,.16,1.1,1.1,0,0,0,1.19,1.16,4.13,4.13,0,0,0,3.14-2,2.83,2.83,0,0,0,1.81.62,4.66,4.66,0,0,0,3.19-1.41A.34.34,0,0,0,339.66,192.4Zm-7.92,2.14h-.06v0c0-1,1.42-2.19,2.07-2.54a4,4,0,0,0,.14.55C333.56,193.48,332.45,194.54,331.74,194.54Z"/>
        <path class="cls-20" d="M319.37,187.48a.72.72,0,0,0,.7.72c.64,0,1.2-.82,1.2-1.33a.63.63,0,0,0-.63-.69A1.47,1.47,0,0,0,319.37,187.48Z"/>
        <rect class="cls-21" x="206.9" y="218.37" width="178.74" height="86.13"/>
        <rect class="cls-22 bar-1" x="308.66" y="261.19" width="9.97" height="17.25"/>
        <rect class="cls-23 bar-2" x="321.65" y="257.2" width="9.97" height="21.24"/>
        <rect class="cls-22 bar-3" x="338.65" y="246.3" width="9.97" height="32.14"/>
        <rect class="cls-23 bar-4" x="351.64" y="244.43" width="9.97" height="34"/>
        <line class="cls-24" x1="299" y1="281.86" x2="370.7" y2="281.86"/>
        <line class="cls-24" x1="303.65" y1="236.88" x2="303.65" y2="287.85"/>
        <path class="cls-23 pie-1" d="M251,264.44V242.93a21.52,21.52,0,1,1,0,43,19.9,19.9,0,0,1-9.09-2Z"/>
        <path class="cls-25 pie-2" d="M248.39,261.85l-21,4.84a21.51,21.51,0,0,1,21-26.36Z"/>
        <path class="cls-22 pie-3" d="M237,286a21,21,0,0,1-11.87-14.67l21-4.84"/>
      </g>
    </svg>
  </div>
</div>

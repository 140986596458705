<head>
  <!-- Taboola Pixel Code -->
  <script type='text/javascript'>
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: 1733058});
    !function (t, f, a, x) {
          if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
          }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1733058/tfa.js',
    'tb_tfa_script');
  </script>
  <!-- End of Taboola Pixel Code -->
</head>
<nav class="navbar navbar-dark navbar-expand-lg tr-header myHeader justify-content-lg-start d-flex align-items-center" [ngClass]="{'show': showMenu, 'tr-shadow align-items-md-end': authStatus}">
  <button class="navbar-toggler mr-lg-3" *ngIf="authStatus" type="button" (click)="showHideMenu()">
    <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
    <mat-icon aria-label="Menu Icon" *ngIf="!showMenu">menu</mat-icon>
    <mat-icon aria-label="Close Icon" *ngIf="showMenu">close</mat-icon>
    <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
  </button>

  <a class="navbar-brand" *ngIf="authStatus" routerLink="/dashboard">
    <img src="../../assets/img/TresorVino-Logo-white.png" alt="Tresorvino Logo">
  </a>

  <div class="collapse navbar-collapse tr-primary-menu" *ngIf="authStatus" [ngClass]="{'show': showMenu}">
    <ul class="navbar-nav mr-lg-auto">
      <li class="nav-item" routerLinkActive="active">
          <a class="nav-link d-flex align-items-center" routerLink="/dashboard">
            {{ 'common.dashboard' | translate }}
          </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/cellarbook">
          {{ 'common.cellar_book' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/friends" [fragment]="'friendList'">
          {{ 'common.friends' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/communities">
          {{ 'common.communities' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/marketplace" [fragment]="'offers'">
          {{ 'common.marketplace' | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div class="top-logo" *ngIf="!authStatus">
    <a [routerLink]="['/home']">
      <img class="main-logo" src="../../assets/img/TresorVino-Logo-white.png" alt="Tresorvino Logo">
    </a>
  </div>

  <ul class="navbar ml-lg-auto tr-secondary-menu" [ngClass]="{'d-none d-lg-flex': showMenu}">
    <li class="nav-item">
      <div class="lang-drop" tooltip="Select default language">
        <mat-form-field appearance="fill" [ngClass]="'white-field'">
          <mat-select  #selectedLang [ngModel]="userLang" panelClass="white-field" (selectionChange)="switchLang(selectedLang.value)">
            <mat-option *ngFor="let language of translate.getLangs()" [value]="language"
            > {{ language }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li>
    <li class="nav-item" *ngIf="authStatus">
      <a class="vino-balance" routerLink="/profile" [fragment]="'payment'" tooltip="{{ 'common.vino_coins' | translate }}">
        <span>
          <mat-icon>payments</mat-icon>
        </span>
        <span class="coin-number" *ngIf="vinoCoinBalance > 0">{{ vinoCoinBalance }}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="authStatus">
      <app-notifications></app-notifications>
    </li>
    <li class="nav-item" *ngIf="!authStatus && !isLoginPage">
      <button class="btn tr-btn-primary btn_style" routerLink="/login">
        {{ 'common.login' | translate }}
      </button>
    </li>
    <li class="nav-item profile-menu" *ngIf="authStatus">
      <div class="dropdown">
        <a class="dropdown-toggle my-2 my-sm-0" role="button" id="dropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src={{imageUrl}} alt="Photo" class="profileImage" type="button">
          <span class="userProfile d-none d-sm-inline">{{ firstName }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right profile-dropdown tr-bg-fourth" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" routerLink="/profile" [fragment]="'personalDetails'">
            <mat-icon>account_circle</mat-icon>
            <span class="userOptions">{{ 'common.my_profile' | translate }}</span>
          </a>
          <a class="dropdown-item" routerLink="/settings">
            <mat-icon>settings</mat-icon>
            <span class="userOptions">{{ 'common.settings' | translate }}</span>
          </a>
          <a class="dropdown-item" routerLink="/home" (click)="onLogOut()">
            <mat-icon>logout</mat-icon>
            <span class="userOptions">{{ 'common.log_out' | translate }}</span>
          </a>
        </div>
      </div>
    </li>
  </ul>
</nav>

<div class="faq-container">
    <div class="container">
        <h3 class="heading text-center mb-4 mb-md-4">Frequently asked Questions</h3>
        <div class="search-container">
            <input type="text" class="form-control faq-search-input" (keyup)="filterFaqList($event)" placeholder="Search FAQ">
        </div>
        <div class="faq-wrapper">
            <div class="faq-section" *ngFor="let faq of faqList; let i = index" [ngClass]="{'opened': activeIndex === i}">
                <div class="header" (click)="toggleAccordion(i)">
                    <span class="question">{{ faq.question }}</span>
                    <mat-icon class="ml-auto">expand_more</mat-icon>
                </div>
                <div class="content" [innerHTML]="faq.answer"></div>
            </div>
            <h4 class="no-match text-center" *ngIf="!faqList.length">{{ 'No Match Found' }}</h4>
        </div>
    </div>
</div>
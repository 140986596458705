/* eslint-disable lines-between-class-members */
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../sharedServices/authentication.service';
import UserDataService from 'src/app/sharedServices/user-data.service';
import { UserProfileDataService } from 'src/app/user-profile/user-profile-data.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import HttpService from 'src/app/sharedServices/http.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export default class HeaderComponent implements OnInit {
  authStatus: boolean = false;
  isLoginPage: boolean = false;
  firstName: string;
  imageUrl: string;
  user: any = {};
  userLang = '';
  showMenu = window.innerWidth > 991;
  vinoCoinBalance = 0;

  constructor(
    private router: Router,
    public amplifyService: AmplifyService,
    public translate: TranslateService,
    public data: AuthenticationService,
    private userDataService: UserDataService,
    private userProfileDataService: UserProfileDataService,
    private storageService: StorageService,
    private httpService: HttpService
  ) {
    translate.addLangs(['EN', 'DE']);
    this.userLang = this.userDataService.getLanguage();
    if (!this.userLang) {
      this.userLang = 'EN';
      this.userDataService.setLanguage(this.userLang);
    }
    this.translate.setDefaultLang(this.userLang);
    this.translate.use(this.userLang);
    
    Hub.listen('auth', async ({ }) => {
      const user = await Auth.currentAuthenticatedUser();
      this.user = user;
      if (user.attributes.hasOwnProperty('custom:languagePreference')) {
        this.translate.use(user.attributes['custom:languagePreference']);
        this.userLang = user.attributes['custom:languagePreference'];
        this.userDataService.setLanguage(user.attributes['custom:languagePreference']);
        this.translate.setDefaultLang(user.attributes['custom:languagePreference']);
      } else {
        this.translate.use('EN');
        this.translate.setDefaultLang('EN');
      }
    });

    if (this.router.url === '/login') {
      this.isLoginPage = true;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/home') {
          this.authStatus = false;
          this.isLoginPage = false;
        }
        if (event.url === '/login') {
          this.isLoginPage = true;
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        this.showMenu = false;
        this.checkUserSession();
      }
    });
    this.checkUserSession();
    this.userProfileDataService.currentVinoCoins$.subscribe((data: any) => {
      if (data) {
        this.vinoCoinBalance = parseFloat(data);
      } else {
        this.vinoCoinBalance = 0;
      }
    });
  }

  ngOnInit(): void {
    this.userProfileDataService.setVinoCoins();
    this.data.currentImage.subscribe(imageUrl => (this.imageUrl = imageUrl));
    this.data.currentAuthStatus.subscribe(
      authStatus => (this.authStatus = authStatus)
    );
    this.data.currentFirstName.subscribe(
      firstName => (this.firstName = firstName)
    );
    this.data.currentUser.subscribe(user => (this.user = user));
    this.vinoCoinBalance = this.storageService.getLocalStorage('currentUserVinoCoins');
  }

  showHideMenu(): void {
    this.showMenu = !this.showMenu;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.userLang = lang;
    this.userDataService.setLanguage(this.userLang);
    if (this.authStatus) {
      Auth.updateUserAttributes(this.user, {
        'custom:languagePreference': this.userLang
      }).then(data => {}).catch(err => {
        console.log(err);
      });
    }
  }

  onLogOut() {
    Auth.signOut({ global: true })
      .then(data => {
        this.authStatus = false;
        this.isLoginPage = false;
        this.router.navigate(['/home']);
        setTimeout(() => {
          this.clearStorages();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err?.message === 'User is disabled.') {
          this.clearStorages();
          this.authStatus = false;
          this.isLoginPage = false;
          this.router.navigate(['/home']);
        }
      });
  }

  checkUserSession(): void {
    Auth.currentSession()
      .then(data => {
        this.authStatus = true;
      })
      .catch(err => {
        this.authStatus = false;
      });
  }

  clearStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}

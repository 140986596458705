import { Injectable } from '@angular/core';
import { getNotifications as getNotificationsQuery }  from '../graphql/subscriptions.graphql';
import { Notification } from './notification.model';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from '../../aws-exports';
import { NormalizedCacheObject } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { getUnreadNotificationsQuery } from '../graphql/queries.graphql';
import { markNotificationAsRead } from '../graphql/mutations.graphql';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  client: AWSAppSyncClient<NormalizedCacheObject>;

  constructor(private apollo: Apollo) {
    const payload = {};
    const authPayload = {
      "Authorization": localStorage.getItem('access_token'),
      "host": environment.aws_appsync_graphqlWSEndpoint,
    }

    this.client = new AWSAppSyncClient({
      url: awsconfig.aws_appsync_graphqlWSEndpoint,
      // url: 'wss://izybtmmcgzaptfcop67wziweaq.appsync-realtime-api.eu-central-1.amazonaws.com/graphql?header=' + btoa(JSON.stringify(authPayload)) + '&payload=' + btoa(JSON.stringify(payload)),
      region: awsconfig.aws_appsync_region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        // jwtToken: localStorage.getItem('access_token'),
      },
    });
  }

  getUnreadNotifications() {
    const unreadNotifications = this.apollo.query<any>({
      query: getUnreadNotificationsQuery,
    });
    return unreadNotifications;
  }

  getNotifications(userId: string){
    return this.client.subscribe({
      query: getNotificationsQuery,
      variables: {
        user_id: userId
      }
    });
  }

  setNotificationAsRead(id): any {
    return this.apollo.mutate({
      mutation: markNotificationAsRead,
      variables: {
        eventId: id,
      }
    });
  }
}

<section class="terms-section">
    <div class="container terms-wrapper">
        <br>
        <div class="row">
            <div class="col-12" *ngIf="currentLanguage == 'DE'">
                <h4>Allgemeine Nutzungsbedingungen der Plattform www.Tresorvino.de</h4><br>

                <h5>§ 1 Allgemeines, Anwendungsbereich</h5><br>
                <p>(1) Diese Allgemeinen Nutzungsbedingungen gelten für alle rechtlichen Beziehungen der Fa. Baroxx
                    Projektmanagement
                    GmbH (nachstehend: „Anbieter“) gegenüber den Nutzern der Internetseite www.tresorvino.de und allen
                    Unterseiten
                    (nachfolgend auch: „Internetseite“). Als Nutzer gilt jeder, der sich auf der Internetseite
                    registriert.
                </p>

                <p>(2) Abweichende Vorschriften der Nutzer gelten nicht, außer der Anbieter hat dies ausdrücklich und
                    schriftlich
                    bestätigt. Individualvereinbarungen haben stets Vorrang.</p>

                <p>(3) Der Vertragstext wird vom Anbieter nach dem Vertragsschluss nicht gespeichert und ist deshalb
                    nicht
                    zugänglich.
                    Die Vertragssprache ist deutsch. Der Nutzer kann diese Allgemeinen Nutzungsbedingungen abrufen,
                    speichern und
                    ausdrucken.</p>

                <p>(4) Die Geschäftsbeziehungen zwischen dem Anbieter und dem Nutzer unterliegen dem Recht der
                    Bundesrepublik
                    Deutschland. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz
                    durch
                    zwingende
                    Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat,
                    entzogen
                    wird. Die
                    Geltung von UN‑Kaufrecht ist ausgeschlossen.</p>

                <p>(5) Gerichtsstand ist Heidelberg, soweit der Nutzer Kaufmann, eine juristische Person des
                    öffentlichen
                    Rechts oder
                    ein öffentlich-rechtliches Sondervermögen ist. Dasselbe gilt, wenn ein Kunde keinen allgemeinen
                    Gerichtsstand in
                    Deutschland hat oder der Wohnsitz oder gewöhnlichen Aufenthalt im Zeitpunkt der Klageerhebung nicht
                    bekannt sind.
                </p>

                <p>(6) Informationspflicht gem. Verbraucherstreitbeilegungsgesetz (§36 VSBG): Der Anbieter ist zur
                    Teilnahme
                    an weiteren
                    Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
                </p>

                <p>(7) Der Anbieter ist weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren mit
                    Verbrauchern
                    vor einer
                    Verbraucherschlichtungsstelle teilzunehmen. Verbraucher haben jedoch die Möglichkeit eine
                    alternative
                    Streitbeilegung zu nutzen. Der folgende Link der EU-Kommission (auch OS-Plattform genannt) enthält
                    Informationen
                    über die Online-Streitschlichtung und dient als zentrale Anlaufstelle zur außergerichtlichen
                    Beilegung
                    von
                    Streitigkeiten, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen:
                    <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.
                </p>

                <p>(8) Der Anbieter ist bereit eine außergerichtlichen Streitbeilegung mit gewerblichen Nutzern über
                    folgende Mediatoren
                    zu erzielen:</p>

                <p class="alignedParagraph">aktuell noch in Verhandlung</p>

                <p>Vor der Einleitung eines Mediationsverfahrens sollte eine Streitbeilegung unmittelbar mit dem
                    Anbieter
                    versucht
                    werden.</p>

                <br>

                <h5>§ 2 Leistungen des Anbieters, Inhalte der Internetseite</h5><br>
                <p>(1) Tresorvino ist eine Plattform auf der Nutzer ihren eigenen virtuellen Weinkeller auf Basis ihres
                    realen Bestands
                    anlegen kann. Mit Hinblick auf etwaiger Wertsteigerungen bestimmter individueller Weine ermittelt
                    der
                    Anbieter auf
                    wöchentlicher Basis anhand einer globalen Marktanalyse den Wert der einzelnen Weine des
                    Weinbestands.
                    Auf der
                    Plattform können Weinliebhaber und interessierte Personen ihren Weinbestand darstellen, sich
                    vernetzen,
                    sich
                    gegenseitig informieren und nach belieben den eigenen Weinkeller zur Ansicht an andere Nutzer
                    freigeben.
                    Ferner
                    können die Nutzer über Communities eigene Beiträge anlegen oder an Communities von anderen Nutzern
                    teilnehmen.</p>

                <p>(2) Zudem haben Nutzer einer kostenpflichtigen Private-Pay oder Business-Premium-Mitgliedschaft
                    untereinander die
                    Möglichkeit einzelne Weine oder gar den ganzen Weinbestand zu tauschen (dazu § 7 der
                    Nutzungsbedingungen).</p>

                <p>(3) Durch die ständige Marktwertanalyse haben die Nutzer damit die Möglichkeit den Marktwert sowohl
                    des
                    eigenen als
                    auch den individuell freigeschalteten Weinbestands anderer Nutzer stets im Blick zu haben und auf
                    dieser
                    Basis den
                    eigenen Weinkeller als Investment zu nutzen.</p>

                <p>(4) Bei den vom Anbieter angezeigten Marktpreise der angelegten Weine handelt es sich Mittelwerte der
                    entsprechenden
                    im Internet angebotenen Marktwerte. Jeder Preis hat eine Indikation mittels Ampelfarben. Durch
                    Anklicken
                    der
                    angezeigten Ampel erhalten Sie Informationen für die entsprechenden Farben
                    <a href="#">(Link Mouse over traffic light
                        explanation „prices“)</a>.
                </p>

                <p>(5) Der Anbieter kann die Nutzer der Internetseite durch Links auf fremde Inhalte und Anwendungen
                    Dritter
                    (nachfolgend „Drittinhalte“) hinweisen. Solche Drittinhalte sind durch einen entsprechenden Hinweis
                    deutlich
                    gekennzeichnet. Wenn und soweit im Zusammenhang mit diesen Drittinhalten der Abschluss eines
                    Vertrages
                    angeboten
                    wird, kommt dieser ausschließlich mit dem jeweiligen Anbieter zustande.</p>

                <p>(6) Der Anbieter kann den Zugang zu den eigenen Leistungen beschränken, sofern die Sicherheit des
                    Netzbetriebes, die
                    Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung schwerwiegender Störungen des
                    Netzes,
                    der Software
                    oder gespeicherter Daten dies erfordern.</p>

                <br>

                <h5>§ 3 Nutzung als registriertes Mitglied (Private-Free, Private-Pay- oder Business-Premium
                    Mitgliedschaft)
                </h5><br>
                <p>(1) Die aktive Nutzung der Internetseite ist nur nach vorheriger Registrierung als Mitglied möglich.
                    Ein
                    Nutzer kann
                    sich dabei kostenlos (Private-Free Mitgliedschaft) oder kostenpflichtig (Private-Pay- oder
                    Business-Premium
                    Mitgliedschaft) registrieren. Die Nutzerzugänge unterscheiden sich durch den Umfang der Nutzbarkeit
                    der
                    Plattform,
                    z.B. hinsichtlich des Informationsangebots, der Suchmöglichkeiten, der Communitynutzung und einer
                    Tauschoption
                    <a href="#">(Link Community)</a>. Der Anbieter behält sich das Recht vor, die Nutzungsmöglichkeit
                    der
                    Private-Free
                    Mitglieder mit einer
                    Ankündigungsfrist von 4 Wochen ganz oder teilweise einzustellen.
                </p>

                <p>(2) Die Registrierung erfolgt durch die Eröffnung eines Nutzerzugangs als Priavte-Free, Private-Pay
                    oder
                    Business-Premium Mitglied unter Zustimmung dieser Allgemeinen Nutzungsbedingungen und der
                    Kenntnisnahme
                    der
                    Datenschutzerklärung <a href="#">(Link Datenschutzerklärung)</a>. Mit dem Abschluss des
                    Registrierungsvorgangs kommt
                    zwischen dem
                    Anbieter und dem jeweiligen Nutzer ein Vertrag über die Nutzung der Internetseite (nachfolgend:
                    „Nutzungsvertrag“)
                    zustande. Bis zum Abschluss des Registrierungszuganges kann der Nutzer seine Eingaben vor mit den
                    üblichen Tastatur-
                    und Mausfunktionen direkt in den entsprechenden Eingabefeldern korrigieren. Der Anbieter bestätigt
                    dem
                    Nutzer den
                    Abschluss des Nutzungsvertrages per E‑Mail. Mit dieser E‑Mail erhält der Nutzer auch diese
                    Allgemeinen
                    Nutzungsbedingungen, eine Widerrufsbelehrung sowie eine Datenschutzerklärung. Ein Anspruch auf
                    Abschluss
                    eines
                    Nutzungsvertrages besteht nicht.</p>

                <p>(3) Gewerbliche Nutzer sind verpflichtet bei der Registrierung die gewerbliche Nutzung des
                    Nutzerkontos
                    anzuzeigen.
                    Der Anbieter kennzeichnet dann das jeweilige Nutzerkonto als "Gewerblicher Nutzer". Mit der Nutzung
                    der
                    Plattform
                    für den gewerblichen Vertrieb von Waren sind gewerbliche Nutzer verpflichtet die gesetzlichen
                    Informationspflichten
                    gegenüber Verbrauchern einzuhalten. Insbesondere ist u.a. auf die Verpflichtung zur Erstellung eines
                    eigenen
                    Impressums und Informationspflichten zu den gesetzlich vorgeschriebenen Vorschriften zum
                    Verbraucherschutz
                    hinzuweisen. Der Verweis auf Bestimmungen und die Nutzung dieser Nutzungsbedingungen ist untersagt.
                </p>

                <p>(4) Die Registrierung ist nur unbeschränkt geschäftsfähigen natürlichen Personen erlaubt.
                    Minderjährige
                    dürfen sich
                    nicht beim Anbieter anmelden. Jeder Nutzer darf nur einen Zugang haben; eine Übertragung des Zugangs
                    ist
                    nicht
                    möglich.</p>

                <p>(5) Die vom Anbieter bei der Registrierung abgefragten Daten sind vollständig und korrekt anzugeben,
                    z.B.
                    Vor- und
                    Nachname, aktuelle Adresse (kein Postfach) und Telefonnummer, gültige E-Mail-Adresse. Ändern sich
                    die
                    angegebenen
                    Daten nachträglich, so ist der Nutzer verpflichtet, die Angaben umgehend zu korrigieren. Gewerbliche
                    Nutzer sind
                    zudem verpflichtet ihren Firmennamen, Firmensitz und die entsprechende Adresse anzugeben.</p>

                <p>(6) Bei der Anmeldung geben die Nutzer einen Nutzernamen an und wählen ein Passwort. Über die
                    angegebene
                    E-Mail-Adresse erfolgt die Kommunikation zwischen dem Anbieter und den Nutzern. Nutzer müssen ihr
                    Passwort geheim
                    halten.</p>

                <p>(7) Jeder Nutzer ist verpflichtet, den Anbieter umgehend zu informieren, wenn es Anhaltspunkte dafür
                    gibt, dass sein
                    Zugang missbraucht wurde. Jeder Nutzer haftet grundsätzlich für sämtliche Aktivitäten, die unter
                    Verwendung seines
                    Zugangs vorgenommen werden, und stellt den Anbieter von etwaigen Schadensersatzansprüchen von
                    Dritten
                    frei, außer
                    der Nutzer hat den Missbrauch nicht zu vertreten.</p>

                <br>

                <h5>§ 4 Dauer des kostenpflichtigen Zugangs, Einstellung der Internetseite durch den Anbieter</h5><br>
                <p>(1) Die Laufzeit einer Private-Pay- oder Business-Premium-Mitgliedgliedschaft beträgt ein Monat. Nach
                    diesem Zeitraum
                    verlängert sich die Mitgliedschaft automatisch, außer der Nutzer teilt mindestens einen Tag vor
                    Ablauf
                    schriftlich
                    mit, dass er keine Verlängerung wünscht, mithin den Vertrag kündigt. Die Kündigung gilt als
                    eingegangen,
                    sobald sie
                    dem Anbieter zu den gewöhnlichen Geschäftszeiten zugestellt wurde. Kündigt das Mitglied werden die
                    Nutzungsdaten
                    innerhalb einer Woche nach Wirksamwerden der Kündigung gelöscht. Sofern eine offene Streitigkeit das
                    Nutzerkonto
                    betrifft, werden die Nutzungsdaten innerhalb einer Woche nach Beilegung/ Erledigung der Streitigkeit
                    gelöscht. Eine
                    erneute kostenpflichtige Registrierung ist jederzeit möglich. Ferner werden die Informationen des
                    Nutzungskonto
                    nicht gelöscht, soweit der Anbieter dazu gesetzlich verpflichtet ist oder ein berechtigtes Interesse
                    daran hat.</p>

                <p>(2) Zur Ausübung des Kündigungsrechts hat der Nutzer die Möglichkeit der Zusendung der Kündigung in
                    schriftlicher
                    oder elektronischer Form an kündigung@tresorvino.de oder der Inanspruchnahme der Kündigungsfunktion
                    innerhalb des
                    Nutzerkontos.</p>

                <p>(3) Die Registrierung als Private-Free-Mitglied ist unbefristet. Ein Private-Free-Mitglied kann
                    seinen
                    Nutzungsvertrag jederzeit schriftlich oder per E-Mail kündigen. Die Nutzungsdaten werden innerhalb
                    einer
                    Woche nach
                    Zugang der Kündigung gelöscht.</p>

                <p>(4) Der Anbieter behält sich vor, die Internetseite insgesamt einzustellen. Hierüber wird er die
                    Nutzer
                    mindestens 6
                    Wochen vorher per E-Mail informieren und etwa bestehende Guthaben an Nutzungsgebühren unverzüglich
                    an
                    die jeweiligen
                    Nutzer erstatten.</p>

                <p>(5) Das Recht zur außerordentlichen Kündigung bleibt unberührt.</p>

                <br>

                <h5>§ 5 Nutzungsgebühren als Private-Pay- / Business-Premium- Mitglied</h5><br>
                <p>(1) Die Nutzungsgebühren für die Registrierung als Private-Pay- oder Business-Premium- Mitglied
                    richten
                    sich nach der
                    Art der gewählten Mitgliedschaft und werden dem Nutzer bei der Registrierung bekannt gegeben <a
                        href="#">(Link Mitgliedschaften)</a>. Die Nutzergebühren verstehen sich, wenn nicht ausdrücklich
                    anders angegeben, inkl.
                    der jeweils
                    geltenden Umsatzsteuer (derzeit 19 %).</p>

                <p>(2) Die Nutzungsgebühren sind jeweils im Voraus fällig. Der Nutzer kann die Nutzungsgebühren mittels
                    PayPal bezahlen.
                    Kommt ein Nutzer mit den Zahlungsverpflichtungen in Verzug, so kann der Anbieter nach den
                    gesetzlichen
                    Bestimmungen
                    Schadensersatz verlangen und/ oder vom Vertrag zurücktreten. Der Anbieter behält sich vor zu einem
                    späteren
                    Zeitpunkt die Zahlung durch Kreditkarte anzubieten.</p>

                <p>(3) Der Anbieter ist berechtigt, die Aktivierung des Zugangs als Private-Pay- / Business-Premium-
                    Mitglied vom
                    vorherigen Zahlungseingang abhängig zu machen.</p>

                <p>(4) Der Nutzer erhält vom Anbieter über die Nutzungsgebühren eine Rechnung per E-Mail.</p>

                <br>

                <h5>§ 6 Besondere Bestimmungen zum Vertragsschluss für Private-Pay und Business-Premium Mitglieder</h5>
                <br>
                <p>(1) Im Rahmen Tauschmöglichkeiten der Nutzer mit einer Private-Pay oder Business-Premium
                    Mitgliedschaft
                    versteht sich
                    der Anbieter Tauschplattform im Internet. Bei den angelegten Weinen handelt es sich dabei nicht um
                    Produkte des
                    Anbieters sondern um Produkte von Dritten.</p>

                <p>(2) Ein Tauchvertrag kommt deshalb stets zwischen den jeweiligen Nutzern zustande; der Anbieter
                    stellt
                    insoweit nur
                    die technische Plattform zur Verfügung und wird nicht Vertragspartner der Tauschverträge.</p>

                <p>(3) Zwischen den Nutzern kommt ein Tauschvertrag nach den gesetzlichen Bestimmungen durch Angebot und
                    Annahme
                    zustande. Das Anlegen und die Veröffentlichung des eigenen Weinbestandes stellt kein Angebot auf den
                    Abschluss eines
                    Tauschvertrag dar. Das Zustandekommen einer vertraglichen Beziehung erfordert insofern eine im
                    Rahmen
                    eines
                    kommunikativen Austauschs zustande gekommene individuelle Vereinbarung der jeweiligen Nutzer. Für
                    die
                    Tauschverträge
                    gelten – soweit vorhanden – die Allgemeinen Geschäftsbedingungen der jeweiligen Nutzer, diese sind
                    auch
                    für die
                    Erfüllung aller ihnen obliegenden Belehrungspflichten verantwortlich. Individualabreden haben stets
                    Vorrang. Im
                    Übrigen gelten die gesetzlichen Bestimmungen über Tauschverträge.</p>

                <p>(4) Ein Tausch von Weinen über die Plattform ist lediglich Private-Pay und
                    Business-Premium-Mitglieder
                    gestattet.
                    Damit ist ein über die Plattform des Anbieters abgeschlossener Tauschvertrag nur zulässig, soweit
                    Vertragsparteien
                    als Privat-Pay- oder Business-Premium-Mitglieder registriert sind. Der Anbieter behält sich
                    diesbezüglich vor,
                    Bestandsveränderungen der Nutzer zu analysieren und Rückschlüsse auf etwaiges Tauschverhalten zu
                    ziehen.
                    Stellt der
                    Anbieter ein Verstoß gegen diese Bestimmung fest, dann gilt § 8 und § 9 dieser Nutzungsbedingungen
                    entsprechend.</p>

                <p>(5) Die tatsächliche Abwicklung des Tauschvertrags liegt in der Verantwortung der korrespondierenden
                    Nutzer.
                    Wesentliche Vertragsbestandteile (wie z.B. Namen, Anschriften, Anzahl und Bestimmung der Weine,
                    Versand)
                    sind durch
                    die Nutzer selbst zu ermitteln und zu bestimmen. Der Anbieter stellt insoweit keine
                    personenbezogenen
                    Daten zu
                    Verfügung. Insbesondere bilden die vom Anbieter ermittelten aktuellen Marktwerte (Richtwerte) der
                    Produkte i.S.d § 2
                    Abs. 2 der Nutzungsbedingung keine Geschäftsgrundlage. Der Anbieter übernimmt für die Richtigkeit
                    dieser
                    Richtwerte
                    keine Haftung (siehe § 10 Abs. 2).</p>

                <p>(6) Die Nutzer dürfen die im Rahmen der Vertragsverhandlungen erhaltenen Informationen anderer Nutzer
                    nur
                    für den
                    Zweck der vertraglichen oder vorvertraglichen Kommunikation nutzen. Eine weitere Verarbeitung der
                    Informationen
                    jeglicher Art ist verboten.</p>

                <p>(7) Nach der vollständigen Abwicklung des Vertrages überträgt der Anbieter den entsprechenden
                    virtuellen
                    Bestand auf
                    das Nutzerkonto des entsprechenden Nutzers. Der Anbieter behält sich vor, im Falle schwerwiegender
                    vertraglicher
                    Pflichtverletzungen, den virtuellen Bestand auf das ursprüngliche Nutzerkonto zurück zu übertragen.
                    Schwerwiegende
                    Pflichtverletzungen liegen beispielsweise vor, wenn einem Nutzer die vertraglich festgelegten
                    Produkte
                    nicht
                    zugestellt werden und der andere Vertragspartner den tatsächlichen Versand nicht nachweisen kann.
                </p>

                <p>(8) Der Anbieter kann den Zugang zu den eigenen Leistungen beschränken, sofern die Sicherheit des
                    Netzbetriebes und/
                    oder die Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung schwerwiegender Störungen
                    des
                    Netzes, der
                    Software oder gespeicherter Daten, dies erfordern.</p>

                <br>

                <h5>§ 7 Verhaltenspflichten der Nutzer, Freistellung bei Verstößen</h5><br>
                <p>(1) Die Beiträge der Nutzer auf der Internetseite werden vom Anbieter grundsätzlich nicht auf
                    Richtigkeit
                    und
                    Rechtmäßigkeit geprüft. Sollte der Anbieter Kenntnis davon erlangen, dass ein Nutzer mit seinem
                    Beitrag
                    gegen diese
                    Allgemeinen Nutzungsbedingungen oder gegen gesetzliche Vorschriften verstößt, werden die
                    rechtswidrigen
                    Inhalte
                    jedoch unverzüglich entfernt oder der Zugang zu diesen wird gesperrt.</p>

                <p>(2) Allein der Nutzer ist dafür verantwortlich, dass er über alle Rechte hinsichtlich der von ihm
                    veröffentlichten
                    Inhalte verfügt und dass dadurch keine Rechte Dritter verletzt werden. Sollen z.B. markenrechtlich
                    geschützte Bilder
                    hochgeladen werden, so ist dies nur mit Zustimmung des Lizenzinhabers möglich. Der Nutzer räumt dem
                    Anbieter mit der
                    Veröffentlichung unwiderruflich und unentgeltlich das räumlich, zeitlich und inhaltlich
                    unbeschränkte
                    Recht zur
                    Nutzung und Verwertung der von ihm bereitgestellten Inhalte auf der Internetseite ein. Eine
                    anderweitige
                    Nutzung der
                    vom Nutzer übertragenen Inhalte ist nur mit dessen Zustimmung zulässig. In keinem Fall stellen
                    Inhalte
                    die Meinung
                    des Anbieters dar und der Anbieter macht sich diese auch nicht zu Eigen.</p>

                <p>(3) Jeder Nutzer verpflichtet sich, das Angebot der Internetseite nicht zu nutzen, um Inhalte zu
                    veröffentlichen oder
                    Nachrichten zu übermitteln, die bzw. deren Einstellung</p>
                <ul>
                    <li>sittenwidrig, pornographisch, rassistisch oder in sonst einer Weise anstößig sind,</li>
                    <li>unsachlich oder vorsätzlich unwahr sind,</li>
                    <li>ein Gütesiegel, Garantiezeichen oder sonstige Symbole Dritte enthalten,</li>
                    <li>die Rechte Dritter, insbesondere Urheberrechte und geschützte Rechte nach dem MarkenG,
                        verletzen,
                    </li>
                    <li>in sonst einer Weise gegen geltende Gesetze verstoßen bzw. einen Straftatbestand erfüllen,</li>
                    <li>Viren oder andere Computerprogramme beinhalten, welche Soft- oder Hardware beschädigen oder die
                        Nutzung von
                        Computern beeinträchtigen können,</li>
                    <li>Umfragen oder Kettenbriefe oder getarnte Werbung sind, oder</li>
                    <li>dem Zweck dienen, persönliche Daten von anderen Nutzern zu insbesondere geschäftlichen Zwecken
                        zu
                        sammeln und/
                        oder zu nutzen.</li>

                </ul>

                <p>(4) Sollte der Anbieter von einem Verstoß gegen die vorstehenden Bestimmungen erfahren, so bleibt es
                    ihm
                    vorbehalten,
                    den Beitrag/ die Anzeige zu ändern oder zu löschen. Sollten Dritte wegen eines solchen Verstoßes
                    Schadensersatzansprüche gegenüber dem Anbieter geltend machen, so hat der verantwortliche Nutzer den
                    Anbieter
                    hiervon freizustellen.</p>

                <br>

                <h5>§ 8 Sperrung</h5><br>
                <p>(1) Der Anbieter kann folgende Maßnahmen ergreifen, wenn konkrete Anhaltspunkte dafür bestehen, dass
                    ein
                    Nutzer
                    gesetzliche Vorschriften, Rechte Dritter oder diese Allgemeinen Nutzungsbedingungen verletzt, oder
                    wenn
                    der Anbieter
                    ein sonstiges berechtigtes Interesse hat, insbesondere zum Schutz der anderen Nutzer:</p>
                <ul>
                    <li>Verwarnung von Nutzern,</li>
                    <li>Vorläufige, teilweise oder endgültige Sperrung.</li>

                </ul>
                <p>(2) Der Anbieter kann einen Nutzer endgültig von der aktiven Benutzung der Internetseite auch dann
                    ausschließen
                    (endgültige Sperre), wenn er bei der Anmeldung falsche Kontaktdaten angegeben hat, insbesondere eine
                    falsche oder
                    ungültige E-Mail-Adresse, wenn er andere Nutzer oder Anbieter in erheblichem Maße schädigt oder wenn
                    ein
                    anderer
                    wichtiger Grund vorliegt.</p>

                <p>(3) Sobald ein Nutzer vorläufig oder endgültig gesperrt wurde, darf er die Internetseite auch mit
                    anderen
                    Nutzerzugängen nicht mehr nutzen und sich nicht erneut anmelden.</p>

                <br>

                <h5>§ 9 Systemintegrität und Störung der Internetseite</h5><br>
                <p>(1) Nutzer dürfen keine Mechanismen, Software oder sonstige Scripts in Verbindung mit der Nutzung der
                    Internetseite
                    verwenden, die das Funktionieren der Internetseite stören können, insbesondere solche, dies es
                    ermöglichen,
                    automatisierte Seitenaufrufe oder Seitenaufrufe zu generieren.</p>

                <p>(2) Nutzer dürfen keine Maßnahmen ergreifen, die eine unzumutbare oder übermäßige Belastung der
                    Infrastruktur zur
                    Folge haben können.</p>

                <p>(3) Nutzer dürfen keine vom Anbieter generierten Inhalte blockieren, überschreiben oder modifizieren
                    oder
                    in
                    sonstiger Weise störend in die Internetseite eingreifen.</p>

                <br>

                <h5>§ 10 Verantwortung für Inhalte, Haftung</h5><br>
                <p>(1) Der Anbieter übernimmt keine Verantwortung dafür, dass die auf der Internetseite zur Verfügung
                    gestellten
                    Informationen und Dokumentationen vollständig und richtig sind sowie dem aktuellen Stand
                    entsprechen.
                    Dies gilt auch
                    für alle auf der Internetseite enthaltenen Verlinkungen. Der Anbieter ist für den Inhalt einer
                    Seite,
                    die mit einem
                    solchen Link erreicht wird, nicht verantwortlich.</p>

                <p>(2) Der Anbieter haftet insbesondere nicht für die von ihm ermittelten Markwerte der Produkte i.S.d §
                    2
                    Abs. 2 der
                    Nutzungsbedingungen. Der Nutzer hat keinen Anspruch auf Offenlegung der bei der Ermittlung der
                    Preise
                    verwendeten
                    Faktoren bzw. auf Offenlegung der Namen der Anbieter, deren Preise die Berechnungsgrundlage für den
                    auf
                    der
                    Plattform ermittelten Marktwert dienen.</p>

                <p>(3) Außerhalb der Haftung für Sach- und Rechtsmängel haftet der Anbieter unbeschränkt, soweit die
                    Schadensursache auf
                    Vorsatz oder grober Fahrlässigkeit beruht. Der Anbieter haftet auch für die leicht fahrlässige
                    Verletzung von
                    wesentlichen Pflichten (Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet)
                    sowie
                    für die
                    Verletzung von Kardinalpflichten (Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des
                    Vertrages überhaupt
                    erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut), jedoch nur für den
                    vorhersehbaren,
                    vertragstypischen Schaden. Insbesondere haftet der Anbieter für den Verlust von Daten nur in Höhe
                    des
                    Aufwandes, der
                    entsteht, wenn der Nutzer regelmäßig und anwendungsadäquat eine Datensicherung durchgeführt und
                    dadurch
                    sichergestellt hat, dass verloren gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden
                    können. Für die
                    leicht fahrlässige Verletzung anderer als der vorstehenden Pflichten haftet der Anbieter nicht.</p>

                <p>(4) Die Haftungsbeschränkungen des vorstehenden Absatzes gelten nicht bei der Verletzung von Leben,
                    Körper und
                    Gesundheit, für einen Mangel nach Übernahme einer Garantie und bei arglistig verschwiegenen Mängeln.
                </p>

                <p>(5) Ist die Haftung des Anbieters ausgeschlossen oder beschränkt, so gilt dies ebenfalls für die
                    persönliche Haftung
                    der Angestellten, Vertreter und Erfüllungsgehilfen.</p>

                <br>

                <h5>§ 11 Datenschutz</h5><br>
                <p>(1) Dem Nutzer ist bekannt und er willigt darin ein, dass die zur Abwicklung des
                    Nutzungsverhältnisses
                    erforderlichen
                    persönlichen Daten auf Datenträgern gespeichert werden. Der Nutzer stimmt der Erhebung, Verarbeitung
                    und
                    Nutzung
                    seiner personenbezogenen Daten zum Zwecke der Durchführung des Nutzungsvertrages ausdrücklich zu.
                    Hierzu
                    zählen alle
                    Daten, die zur ordnungsgemäßen Abwicklung des zwischen dem Nutzer und dem Anbieter zustande
                    gekommenen
                    Vertrages
                    erforderlich sind, also insbesondere Name, Adresse, Kontaktdaten (Telefon- und Telefaxnummer,
                    E-Mail-Adresse); aber
                    auch die anonyme Auswertung von Dateien, die angefordert wurden, sowie dem Namen der Datei, dem
                    Datum
                    und der
                    Uhrzeit der Abfrage, der übertragene Datenmenge, dem Zugriffsstatus (Datei übertragen, Datei nicht
                    gefunden etc.),
                    einer Beschreibung des Typs des verwendeten Webbrowsers.</p>

                <p>(2) Im Rahmen der Community-Funktion sind personenbezogene Daten der Nutzer für andere Nutzer
                    grundsätzlich nicht
                    sichtbar. Im Falle einer Verknüpfung mit anderen Nutzern (Hinzufügen zu einer Freundesliste) werden
                    personenbezogene
                    Daten der verknüpften Nutzer (Name, Vorname, Firmenname) auf den jeweiligen Accounts sichtbar, wenn
                    sie
                    dieser
                    zustimmen. Die Zustimmung zur Sichtbarkeit der personenbezogenen Daten erfolgt durch die Bestätigung
                    der
                    Verknüpfung
                    zu anderen Nutzern (Bestätigung der Hinzufügung zu einer Freundesliste).</p>

                <p>(3) Der Anbieter ist nur bei gesonderter Einwilligung des Nutzers berechtigt, dessen personenbezogene
                    Daten zum
                    Zwecke der Werbung zu erheben, zu speichern, zu verändern oder zu nutzen. Der Anbieter ist in keinem
                    Fall
                    berechtigt, diese Daten in listenmäßiger oder sonst wie zusammengefasster Form zum Zwecke der
                    Werbung an
                    Dritte zu
                    übermitteln.</p>

                <p>(4) Die gespeicherten persönlichen Daten werden vom Anbieter vertraulich behandelt. Die Erhebung,
                    Verarbeitung und
                    Nutzung der personenbezogenen Daten erfolgt unter Beachtung des Bundesdatenschutzgesetzes (BDSG)
                    sowie
                    des
                    Telemediengesetzes (TMG). Dem Nutzer steht das Recht zu, seine Einwilligung jederzeit mit Wirkung
                    für
                    die Zukunft zu
                    widerrufen. Der Anbieter ist in diesem Fall zur Löschung der persönlichen Daten des Nutzers
                    verpflichtet. Bei
                    laufenden Vertragsverhältnissen eines Nutzers erfolgt die Löschung nach Beendigung des Vertrages.
                </p>

                <br>

                <h5>§ 12 Änderung dieser AGB</h5><br>
                <p>Der Anbieter behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern. Die
                    geänderten
                    Bedingungen
                    werden den Nutzern per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet.
                    Widerspricht
                    ein Nutzer der
                    Geltung der neuen AGB nicht innerhalb von sechs Wochen nach Empfang der E-Mail, gelten die
                    geänderten
                    AGB als
                    angenommen. Der Anbieter wird die Nutzer in der E-Mail, die die geänderten Bedingungen enthält, auf
                    die
                    Bedeutung
                    dieser Frist gesondert hinweisen.</p>

                <br>

                <h5>§ 13 Salvatorische Klausel</h5><br>
                <p>Sofern eine Bestimmung dieses Vertrags unwirksam ist, bleiben die übrigen Bestimmungen davon
                    unberührt.
                    Die
                    unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen
                    Bestimmung in
                    rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle
                    Regelungslücken.
                </p>

                <br>

                <h5>§ 14 Widerrufsrecht</h5><br>
                <p>Verbraucher haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                    widerrufen.
                    Die
                    Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.</p>

                <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns Tresorvino Daten mittels einer eindeutigen Erklärung
                    (z.B. ein mit
                    der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
                    informieren.
                </p>

                <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                    Widerrufsrechts vor
                    Ablauf der Widerrufsfrist absenden.</p>

                <br>

                <h5>Folgen des Widerrufs</h5><br>
                <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                    einschließlich
                    der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
                    andere
                    Art der
                    Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und
                    spätestens
                    binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
                    Vertrags bei uns
                    eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
                    ursprünglichen
                    Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in
                    keinem Fall
                    werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>

                <p>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie
                    uns
                    einen
                    angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
                    Ausübung
                    des
                    Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im
                    Vergleich zum
                    Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>


                <br>

                <h5>Muster-Widerrufsformular</h5><br>
                <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es
                    zurück an:</p>

                <span>Tresorvino</span><br>
                <span>Baroxx Projektmanagement GmbH</span><br>
                <span>Am Büchsenackerhang 66</span><br>
                <span>69118 Heidelberg</span><br><br><br>

                <ul style="list-style-type:none;">
                    <li>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag die Erbringung
                        der
                        folgenden
                        Dienstleistung (*)</li>
                    <hr class="formLine">
                    <hr class="formLine">
                    <hr class="formLine">
                    <li>- Bestellt am (*)/ erhalten am (*):</li>
                    <hr class="formLine">
                    <li>- Name des/ der Verbraucher(s):</li>
                    <hr class="formLine">
                    <li>- Anschrift des/ der Verbraucher(s):</li>
                    <hr class="formLine">
                    <br><br>
                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:60%; margin-left:0px;">

                    <p>Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>

                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:45%; margin-left:0px;">

                    <p>Datum</p>
                </ul>
            </div>
            <div class="col-12" *ngIf="currentLanguage == 'EN'">
                <h4>General Terms and Conditions of the platform www.Tresorvino.de</h4><br>

                <h5>§ 1 General, Scope of Application</h5><br>
                <p>(1) These General Terms and Conditions apply to all legal relationships of Baroxx Projektmanagement
                    GmbH (hereinafter: "Provider") with users of the website www.tresorvino.de and all subpages
                    (hereinafter also: "Website"). Any person who registers on the website is considered a user.</p>

                <p>(2) Deviating regulations of the users do not apply unless expressly and in writing confirmed by the
                    Provider. Individual agreements always take precedence.</p>

                <p>(3) The contract text is not stored by the Provider after conclusion of the contract and is therefore
                    not accessible. The contract language is German. The user can access, save, and print these General
                    Terms and Conditions.</p>

                <p>(4) The business relationship between the Provider and the user is subject to the law of the Federal
                    Republic of Germany. For consumers, this choice of law applies only insofar as the protection
                    granted by mandatory provisions of the law of the country in which the consumer has his habitual
                    residence is not withdrawn. The application of the UN Convention on Contracts for the International
                    Sale of Goods is excluded.</p>


                <p>(5) Jurisdiction is Heidelberg, insofar as the user is a merchant, a legal entity under public law,
                    or a public-law special fund. The same applies if a customer does not have a general place of
                    jurisdiction in Germany or if the place of residence or habitual abode at the time the action is
                    filed is not known.</p>

                <p>(6) Information obligation according to the Consumer Dispute Resolution Act (§36 VSBG): The provider
                    is neither willing nor obliged to participate in further dispute resolution proceedings before a
                    consumer arbitration board.</p>

                <p>(7) The provider is neither willing nor obliged to participate in dispute resolution proceedings with
                    consumers before a consumer arbitration board. However, consumers have the opportunity to use
                    alternative dispute resolution. The following link from the European Commission (also known as the
                    ODR platform) contains information on online dispute resolution and serves as a central point of
                    contact for the out-of-court resolution of disputes arising from online purchase contracts or online
                    service contracts: <a
                        href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.</p>

                <p>(8) The provider is willing to achieve out-of-court dispute resolution with commercial users through
                    the following mediators:</p>

                <p class="alignedParagraph">currently still under negotiation</p>

                <p>Before initiating a mediation procedure, dispute resolution should be attempted directly with the
                    provider.</p>

                <br>


                <h5>§ 2 Services Provided by the Provider, Contents of the Website</h5><br>
                <p>(1) Tresorvino is a platform where users can create their own virtual wine cellar based on their real
                    inventory. With regard to potential value increases of certain individual wines, the provider
                    determines the value of each wine in the inventory on a weekly basis through a global market
                    analysis. On the platform, wine enthusiasts and interested individuals can showcase their wine
                    inventory, connect with others, share information, and optionally grant access to their own wine
                    cellar to other users. Additionally, users can create or participate in communities to share their
                    own posts or join communities created by other users.</p>

                <p>(2) Furthermore, users with a paid Private-Pay or Business-Premium membership have the opportunity to
                    exchange individual wines or even the entire wine inventory among themselves (see § 7 of the terms
                    of use).</p>

                <p>(3) Through the constant market value analysis, users have the opportunity to keep track of the
                    market value of both their own and individually shared wine inventories of other users, allowing
                    them to use their wine cellar as an investment based on this information.</p>

                <p>(4) The market prices of the wines displayed by the provider are averages of the corresponding market
                    values offered on the internet. Each price is indicated by traffic light colors. By clicking on the
                    displayed traffic light, you will receive information about the respective colors (<a href="#">Link
                        Mouse over traffic light explanation "prices"</a>).</p>

                <p>(5) The provider may inform users of the website about third-party content and applications
                    (hereinafter "Third-Party Content") through links. Such third-party content is clearly marked with a
                    corresponding notice. If and to the extent that the conclusion of a contract is offered in
                    connection with this third-party content, such contract is concluded exclusively with the respective
                    provider.</p>

                <p>(6) The provider may restrict access to its own services if the security of the network operation,
                    the maintenance of network integrity, in particular the avoidance of serious disruptions to the
                    network, the software, or stored data, requires it.</p>

                <br>

                <h5>§ 3 Use as a Registered Member (Private-Free, Private-Pay, or Business-Premium Membership)</h5><br>

                <p>(1) Active use of the website is only possible after prior registration as a member. A user can
                    register either for free (Private-Free membership) or for a fee (Private-Pay or Business-Premium
                    membership). The user accesses differ in terms of the extent of platform usability, such as
                    information offerings, search capabilities, community usage, and an exchange option (Link
                    Community). The provider reserves the right to completely or partially suspend the usability of
                    Private-Free memberships with a notice period of 4 weeks.</p>

                <p>(2) Registration is done by opening a user account as a Private-Free, Private-Pay, or
                    Business-Premium member, agreeing to these General Terms and Conditions, and acknowledging the
                    privacy policy (Link Privacy Policy). By completing the registration process, a contract for the use
                    of the website (hereinafter: "User Agreement") is concluded between the provider and the respective
                    user. Until the registration process is completed, the user can correct their entries directly in
                    the respective input fields using the usual keyboard and mouse functions. The provider confirms the
                    conclusion of the user agreement to the user via email. With this email, the user also receives
                    these General Terms and Conditions, a revocation instruction, and a privacy policy. There is no
                    entitlement to conclude a user agreement.</p>

                <p>(3) Commercial users are required to indicate the commercial use of the user account during
                    registration. The provider will then label the respective user account as "Commercial User".
                    Commercial users are obligated to comply with the statutory information obligations towards
                    consumers when using the platform for the commercial distribution of goods. In particular,
                    commercial users must comply with obligations such as creating their own imprint and providing
                    information on legally required consumer protection regulations. Referring to provisions and using
                    these terms of use is prohibited.</p>

                <p>(4) Registration is only permitted for fully legally capable natural persons. Minors are not allowed
                    to register with the provider. Each user may only have one account; transferring the account is not
                    possible.</p>

                <p>(5) The data requested by the provider during registration must be complete and correct, such as
                    first and last name, current address (no PO Box), and telephone number, valid email address. If the
                    provided data changes subsequently, the user is obligated to correct the information immediately.
                    Commercial users are also required to provide their company name, registered office, and
                    corresponding address.</p>

                <p>(6) When registering, users provide a username and choose a password. Communication between the
                    provider and users is done via the provided email address. Users must keep their password
                    confidential.</p>

                <p>(7) Each user is obligated to promptly inform the provider if there are indications that their
                    account has been misused. Each user is generally liable for all activities carried out using their
                    account and indemnifies the provider from any claims for damages by third parties, unless the user
                    is not responsible for the misuse.</p>

                <br>

                <h5>§ 4 Duration of paid access, Termination of the website by the provider</h5><br>
                <p>(1) The duration of a Private-Pay or Business-Premium membership is one month. After this period, the
                    membership is automatically renewed unless the user notifies in writing at least one day before
                    expiration that they do not wish to renew, thus terminating the contract. The termination is
                    considered received as soon as it is delivered to the provider during regular business hours. If the
                    member terminates, the usage data will be deleted within one week after the termination becomes
                    effective. If an open dispute concerns the user account, the usage data will be deleted within one
                    week after the dispute is settled/resolved. A new paid registration is possible at any time.
                    Furthermore, the information of the user account will not be deleted to the extent that the provider
                    is legally obligated to do so or has a legitimate interest in doing so.</p>

                <p>(2) To exercise the right of termination, the user has the option of sending the termination in
                    written or electronic form to kündigung@tresorvino.de or using the termination function within the
                    user account.</p>

                <p>(3) Registration as a Private-Free member is unlimited. A Private-Free member can terminate their
                    user agreement at any time in writing or by email. The usage data will be deleted within one week
                    after the termination is received.</p>

                <p>(4) The provider reserves the right to completely shut down the website. The users will be informed
                    about this at least 6 weeks in advance by email, and any existing balances of usage fees will be
                    refunded to the respective users immediately.</p>

                <p>(5) The right to terminate without notice remains unaffected.</p>

                <br>


                <h5>§ 5 Usage Fees as Private-Pay / Business-Premium Member</h5><br>
                <p>(1) The usage fees for registering as a Private-Pay or Business-Premium member depend on the type of
                    membership chosen and will be communicated to the user upon registration <a href="#">(Membership
                        Link)</a>. Unless expressly stated otherwise, the usage fees include the applicable value-added
                    tax (currently 19%).</p>

                <p>(2) The usage fees are due in advance. The user can pay the usage fees via PayPal. If a user is in
                    arrears with payment obligations, the provider may, in accordance with legal provisions, demand
                    damages and/or withdraw from the contract. The provider reserves the right to offer payment by
                    credit card at a later date.</p>

                <p>(3) The provider is entitled to make the activation of access as a Private-Pay / Business-Premium
                    member dependent on prior receipt of payment.</p>

                <p>(4) The user will receive an invoice for the usage fees from the provider via email.</p>

                <br>

                <h5>§ 6 Special Provisions for Contract Conclusion for Private-Pay and Business-Premium Members</h5>
                <br>
                <p>(1) Within the exchange possibilities of users with a Private-Pay or Business-Premium membership, the
                    provider is understood to be an online exchange platform. The wines listed therein are not products
                    of the provider but products of third parties.</p>

                <p>(2) A barter agreement is therefore always concluded between the respective users; the provider only
                    provides the technical platform and is not a contracting party to the barter agreements.</p>

                <p>(3) A barter agreement between users is concluded in accordance with legal provisions through offer
                    and acceptance. The creation and publication of one's own wine inventory does not constitute an
                    offer to conclude a barter agreement. The conclusion of a contractual relationship therefore
                    requires an individual agreement between the respective users reached within the framework of
                    communicative exchange. For the barter agreements, the general terms and conditions of the
                    respective users apply, if available; they are also responsible for fulfilling all notification
                    obligations incumbent upon them. Individual agreements always take precedence. Otherwise, the legal
                    provisions on barter agreements apply.</p>

                <p>(4) Bartering wines via the platform is only permitted for Private-Pay and Business-Premium members.
                    Therefore, a barter agreement concluded via the provider's platform is only permissible if the
                    contracting parties are registered as Private-Pay or Business-Premium members. The provider reserves
                    the right to analyze changes in user profiles and draw conclusions about any bartering behavior. If
                    the provider identifies a violation of this provision, then § 8 and § 9 of these terms of use apply
                    accordingly.</p>

                <p>(5) The actual execution of the barter agreement is the responsibility of the corresponding users.
                    Essential components of the contract (such as names, addresses, quantity and destination of the
                    wines, shipping) must be determined and specified by the users themselves. The provider does not
                    provide any personal data. In particular, the current market values (reference values) of the
                    products as determined by the provider do not form the basis of the transaction. The provider
                    assumes no liability for the accuracy of these reference values (see § 10 para. 2).</p>

                <p>(6) Users may only use the information received from other users during contract negotiations for the
                    purpose of contractual or pre-contractual communication. Any further processing of information of
                    any kind is prohibited.</p>

                <p>(7) After the complete execution of the contract, the provider transfers the corresponding virtual
                    inventory to the user account of the respective user. The provider reserves the right, in the event
                    of serious contractual breaches, to transfer the virtual inventory back to the original user
                    account. Serious breaches of contract occur, for example, when a user fails to deliver the
                    contractually agreed products and the other contracting party cannot prove the actual shipment.</p>

                <p>(8) The provider may restrict access to its own services if the security of the network operation
                    and/or the maintenance of network integrity, in particular the avoidance of serious disruptions to
                    the network, software, or stored data, so require.</p>

                <br>


                <h5>§ 7 Duties of Users, Indemnification for Violations</h5><br>
                <p>(1) The contributions of users on the website are generally not checked for accuracy and legality by
                    the provider. Should the provider become aware that a user violates these General Terms and
                    Conditions or legal regulations with their contribution, the unlawful content will be promptly
                    removed or access to it will be blocked.</p>
                <p>(2) The user alone is responsible for ensuring that they have all rights regarding the content they
                    publish and that no rights of third parties are violated thereby. For example, uploading images
                    protected by trademark law is only possible with the consent of the licensor. By publishing, the
                    user irrevocably and free of charge grants the provider the spatially, temporally, and contentually
                    unrestricted right to use and exploit the content provided by them on the website. Any other use of
                    the content transferred by the user is only permissible with their consent. Under no circumstances
                    do the contents represent the opinion of the provider, and the provider does not endorse them.</p>
                <p>(3) Each user undertakes not to use the website's offer to publish content or transmit messages that:
                </p>
                <ul>
                    <li>are immoral, pornographic, racist, or otherwise offensive,</li>
                    <li>are irrelevant or intentionally untrue,</li>
                    <li>contain third-party trademarks, warranty marks, or other symbols,</li>
                    <li>infringe on the rights of third parties, in particular copyrights and protected rights under
                        trademark law,</li>
                    <li>violate applicable laws or constitute a criminal offense in any way,</li>
                    <li>contain viruses or other computer programs that can damage software or hardware or impair
                        computer use,</li>
                    <li>are surveys, chain letters, or disguised advertisements, or</li>
                    <li>serve the purpose of collecting and/or using personal data from other users, especially for
                        commercial purposes.</li>
                </ul>
                <p>(4) Should the provider become aware of a violation of the above provisions, it reserves the right to
                    change or delete the contribution/advertisement. If third parties assert claims for damages against
                    the provider due to such a violation, the responsible user shall indemnify the provider from this.
                </p>
                <br>
                <h5>§ 8 Blocking</h5><br>
                <p>(1) The provider may take the following measures if there are concrete indications that a user
                    violates legal regulations, third-party rights, or these General Terms and Conditions, or if the
                    provider has any other legitimate interest, particularly to protect other users:</p>
                <ul>
                    <li>Warning of users,</li>
                    <li>Temporary, partial, or final blocking.</li>
                </ul>
                <p>(2) The provider may permanently exclude a user from active use of the website (permanent block) even
                    if they have provided false contact details during registration, especially a false or invalid email
                    address, if they significantly harm other users or providers, or if another important reason exists.
                </p>
                <p>(3) Once a user has been temporarily or permanently blocked, they may no longer use the website with
                    other user accounts or re-register.</p>
                <br>
                <h5>§ 9 System Integrity and Disruption of the Website</h5><br>
                <p>(1) Users may not use mechanisms, software, or other scripts in connection with the use of the
                    website that could disrupt the functioning of the website, particularly those that enable automated
                    page views or page generation.</p>
                <p>(2) Users may not take measures that could result in unreasonable or excessive burden on the
                    infrastructure.</p>
                <p>(3) Users may not block, overwrite, or modify content generated by the provider or otherwise
                    interfere with the website in a disruptive manner.</p>
                <br>

                <h5>§ 10 Responsibility for Content, Liability</h5><br>
                <p>(1) The provider assumes no responsibility for the completeness, accuracy, and timeliness of the
                    information and documentation provided on the website. This also applies to all links contained on
                    the website. The provider is not responsible for the content of a page reached by such a link.</p>
                <p>(2) In particular, the provider is not liable for the market values ​​of the products as determined
                    by him/her in accordance with § 2 (2) of the terms of use. The user has no right to disclosure of
                    the factors used in determining the prices or disclosure of the names of the providers whose prices
                    serve as the basis for the market value determined on the platform.</p>
                <p>(3) Apart from liability for defects in title and quality, the provider is liable without limitation
                    insofar as the cause of damage is based on intent or gross negligence. The provider is also liable
                    for the negligent breach of essential obligations (obligations whose fulfillment is essential for
                    the proper execution of the contract and on whose compliance the user regularly relies), but only
                    for the foreseeable, contract-typical damage. In particular, the provider is liable for data loss
                    only up to the amount of effort that would have been required if the user had regularly and
                    appropriately performed data backups, thereby ensuring that lost data can be restored with
                    reasonable effort. The provider is not liable for the slight negligence of obligations other than
                    those mentioned above.</p>
                <p>(4) The limitations of liability in the preceding paragraph do not apply in the event of loss of
                    life, bodily injury, or damage to health, for a defect after assuming a guarantee, and for
                    fraudulently concealed defects.</p>
                <p>(5) If the liability of the provider is excluded or limited, this also applies to the personal
                    liability of employees, representatives, and vicarious agents.</p>
                <br>
                <h5>§ 11 Data Protection</h5><br>
                <p>(1) The user is aware and agrees that the personal data required for the execution of the usage
                    relationship will be stored on data carriers. The user expressly agrees to the collection,
                    processing, and use of his/her personal data for the purpose of executing the usage contract. This
                    includes all data necessary for the proper execution of the contract concluded between the user and
                    the provider, especially name, address, contact details (telephone and fax number, email address);
                    but also the anonymous evaluation of files requested, including the name of the file, date and time
                    of the request, the amount of data transferred, the access status (file transmitted, file not found,
                    etc.), and a description of the type of web browser used.</p>
                <p>(2) In the context of the community function, personal data of users is generally not visible to
                    other users. In the event of a connection with other users (adding to a friends list), personal data
                    of the linked users (name, first name, company name) become visible on the respective accounts if
                    they agree to it. Consent to the visibility of personal data is given by confirming the connection
                    to other users (confirmation of adding to a friends list).</p>
                <p>(3) The provider is only entitled to collect, store, modify, or use the user's personal data for
                    advertising purposes with the separate consent of the user. Under no circumstances is the provider
                    entitled to transmit this data to third parties for advertising purposes in a list-like or otherwise
                    summarized form.</p>
                <p>(4) The stored personal data is treated confidentially by the provider. The collection, processing,
                    and use of personal data take place in compliance with the Federal Data Protection Act (BDSG) and
                    the Telemedia Act (TMG). The user has the right to revoke his/her consent at any time with effect
                    for the future. In this case, the provider is obliged to delete the user's personal data. In the
                    case of ongoing contractual relationships of a user, deletion takes place upon termination of the
                    contract.</p>
                <br>

                <h5>§ 12 Amendment of these Terms and Conditions</h5><br>
                <p>The provider reserves the right to amend these terms and conditions at any time and without stating
                    reasons. The amended conditions will be sent to the users by email at the latest two weeks before
                    they come into effect. If a user does not object to the validity of the new terms and conditions
                    within six weeks of receiving the email, the amended terms and conditions shall be deemed accepted.
                    The provider will separately draw the users' attention to the significance of this deadline in the
                    email containing the amended conditions.</p>

                <br>

                <h5>§ 13 Severability Clause</h5><br>
                <p>If any provision of this contract is or becomes invalid, the remaining provisions shall remain
                    unaffected. The invalid provision shall be replaced by one which comes economically closest to the
                    meaning and purpose of the invalid provision in a legally effective manner. The same applies to any
                    regulatory gaps.</p>

                <br>

                <h5>§ 14 Right of Withdrawal</h5><br>
                <p>Consumers have the right to withdraw from this contract within fourteen days without giving any
                    reason. The withdrawal period is fourteen days from the day of concluding the contract.</p>

                <p>To exercise your right of withdrawal, you must inform us, Tresorvino Data, of your decision to
                    withdraw from this contract by means of a clear statement (e.g. a letter sent by post, fax, or
                    email).</p>

                <p>To comply with the withdrawal period, it is sufficient that you send the notification of exercising
                    the right of withdrawal before the expiry of the withdrawal period.</p>

                <br>

                <h5>Consequences of Withdrawal</h5><br>
                <p>If you withdraw from this contract, we shall reimburse you for all payments we have received from
                    you, including delivery costs (except for the additional costs resulting from your choice of a
                    different type of delivery than the cheapest standard delivery offered by us), without undue delay
                    and at the latest within fourteen days from the day on which we received notification of your
                    withdrawal from this contract. We will use the same means of payment for this refund as you used for
                    the original transaction, unless expressly agreed otherwise with you; in no event will you be
                    charged any fees for this refund.</p>

                <p>If you have requested that the services begin during the withdrawal period, you shall pay us a
                    reasonable amount corresponding to the proportion of services already provided up to the time you
                    inform us of the exercise of the right of withdrawal with regard to this contract compared to the
                    total scope of the services provided for in the contract.</p>

                <br>

                <h5>Model Withdrawal Form</h5><br>
                <p>If you wish to withdraw from the contract, please fill out this form and send it back to:</p>

                <span>Tresorvino</span><br>
                <span>Baroxx Project Management GmbH</span><br>
                <span>Am Büchsenackerhang 66</span><br>
                <span>69118 Heidelberg</span><br><br><br>

                <ul style="list-style-type:none;">
                    <li>- I/we (*) hereby revoke the contract concluded by me/us (*) for the provision of the following
                        service (*):</li>
                    <hr class="formLine">
                    <hr class="formLine">
                    <hr class="formLine">
                    <li>- Ordered on (*)/ received on (*):</li>
                    <hr class="formLine">
                    <li>- Name of the consumer(s):</li>
                    <hr class="formLine">
                    <li>- Address of the consumer(s):</li>
                    <hr class="formLine">
                    <br><br>
                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:60%; margin-left:0px;">

                    <p>Signature of the consumer(s) (only if communicated on paper)</p>

                    <hr class="formLine" style="margin-bottom: 0.5rem; margin-top: 3rem; width:45%; margin-left:0px;">

                    <p>Date</p>
                </ul>

            </div> 
        </div>
    </div>
</section>
<div class="container">
    <div class="row form-div">
        <div id="resetPassword" class="col-sm-4 p-4 my-3 allFormBox">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onRPSubmit()">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <small id="passwordHelp" class="form-text text-muted">{{ 'login.reset_code_msg' | translate }}
                            {{ userName }}.</small>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <div class="col-sm-5">
                                <label class="allLabels">{{ 'login.reset_code' | translate }}</label> </div>
                            <div class="col-sm-7"> <input type="text" class="form-control allInputBoxes"
                                    formControlName="code" placeholder="{{'login.enter_reset_code' | translate}}"
                                    [ngClass]="{ 'is-invalid': submitted && resetDetails.code.errors }" />
                            </div>
                        </div>
                        <div *ngIf="submitted && resetDetails.code.errors" class="invalid-feedback">
                            <div *ngIf="resetDetails.code.errors.required">{{ 'login.reset_code_warning' | translate }}</div>

                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="row">
                            <div class="col-sm-5">
                                <label class="allLabels">{{ 'login.new_password' | translate }}</label> </div>
                            <div class="col-sm-7"> <input type="password" class="form-control allInputBoxes"
                                formControlName="new_password" placeholder="{{'login.enter_new_password' | translate}}"
                                [ngClass]="{ 'is-invalid': submitted && resetDetails.new_password.errors }" />
                            </div>
                        </div>
                        <div *ngIf="submitted && resetDetails.new_password.errors" class="invalid-feedback">
                            <div *ngIf="resetDetails.new_password.errors.required">
                                {{ 'login.password_required_warning' | translate }}</div>
                            <div *ngIf="resetDetails.new_password.errors.minlength">{{ 'login.password_length_msg' | translate }}</div>
                            <div *ngIf="!resetDetails.new_password.errors.validatePassword.valid">
                                {{ 'login.invalid_password' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="row custom-row">
                            <span class="font-bold">
                                {{ 'login.policy' | translate }}:
                            </span>
                            <ul class="policy-statement">
                                <li>{{ 'login.password_policy_1' | translate }}</li>
                                <li>{{ 'login.password_policy_2' | translate  }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-sm-12 btn-position">
                            <button type="submit" class="btn btn-sm allButtons tr-btn-primary">{{ 'login.submit' | translate }}</button>
                            <button type="reset" routerLink="/login" class="btn btn-sm allButtons cancelResetBtn tr-btn-secondary">{{ 'login.cancel' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  languageSubscription: Subscription;
  currentLanguage: string;

  constructor(private translate: TranslateService) {
    this.languageSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
    // Set initial language
    this.currentLanguage = this.translate.currentLang;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.languageSubscription.unsubscribe();
  }

}

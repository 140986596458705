import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';
import { TokenService } from './graphql/token.service';

const uri = environment.aws_appsync_graphqlEndpoint;

export function createApollo(httpLink: HttpLink, tokenService: TokenService) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext((operation, context) => {
    let token: any = localStorage.getItem('access_token');

    if(!token) {
      token = tokenService?.retrieveToken();
    }

    if ((!token || token === null) && window.location.pathname.includes('/registration')) {
      return {
        headers: {
          Authorization: 'Auth'
        }
      };
    } else {
      return {
        headers: {
          Authorization: token
        }
      };
    }
  });

  const link2 = httpLink.create({
    uri: environment.aws_appsync_graphqlEndpoint,
    headers: new HttpHeaders({
      Authorization: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : this.token?.retrieveToken(),
    }),
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  imports: [ApolloModule],
  exports: [
    HttpClientModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})

export default class GraphQLModule {}

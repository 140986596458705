import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  mainFaqList = [
    {
      question: this.translate.instant('faqs.question_1'),
      answer: this.translate.instant('faqs.answer_1')
    },
    {
      question: this.translate.instant('faqs.question_2'),
      answer: this.translate.instant('faqs.answer_2')
    },
    {
      question: this.translate.instant('faqs.question_3'),
      answer: this.translate.instant('faqs.answer_3')
    },
    {
      question: this.translate.instant('faqs.question_4'),
      answer: this.translate.instant('faqs.answer_4')
    },
    {
      question: this.translate.instant('faqs.question_5'),
      answer: this.translate.instant('faqs.answer_5')
    },
    {
      question: this.translate.instant('faqs.question_6'),
      answer: this.translate.instant('faqs.answer_6')
    },
  ];
  faqList = [];
  activeIndex = 0;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((data) => {
      this.mainFaqList = [
        {
          question: this.translate.instant('faqs.question_1'),
          answer: this.translate.instant('faqs.answer_1')
        },
        {
          question: this.translate.instant('faqs.question_2'),
          answer: this.translate.instant('faqs.answer_2')
        },
        {
          question: this.translate.instant('faqs.question_3'),
          answer: this.translate.instant('faqs.answer_3')
        },
        {
          question: this.translate.instant('faqs.question_4'),
          answer: this.translate.instant('faqs.answer_4')
        },
        {
          question: this.translate.instant('faqs.question_5'),
          answer: this.translate.instant('faqs.answer_5')
        },
        {
          question: this.translate.instant('faqs.question_6'),
          answer: this.translate.instant('faqs.answer_6')
        },
      ];
      this.faqList = this.mainFaqList.slice();
    })
    this.faqList = this.mainFaqList.slice();
  }

  toggleAccordion(index: number): void {
    if (this.activeIndex === index) {
      this.activeIndex = -1;
    } else {
      this.activeIndex = index;
    }
  }

  filterFaqList(event: any): void {
    let searchedText = event.target.value;
    if (searchedText.length) {
      searchedText = searchedText.toLowerCase();
      this.faqList = this.mainFaqList.filter((item: any) => item.question.toLowerCase().includes(searchedText) || item.answer.toLowerCase().includes(searchedText));
    } else {
      this.faqList = this.mainFaqList.slice();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  showToast = false;
  toastData;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.toastService.toastSub.subscribe((data: any) => {
      this.toastData = data;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 6000);
    });
  }
}

<section class="section-one">
  <div class="container container-xxl">
    <div class="row">
      <div class="col-lg-6">
        <div class="left-side h-100">
          <app-svg-animation></app-svg-animation>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="right-side">
          <h2 class="heading">{{ 'logout_home_page.heading_title.paragraph_1' | translate }}</h2>
          <p>{{ 'logout_home_page.heading_title.paragraph_2' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-two">
  <div class="container container-xxl">
    <div class="row">
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/wine_database_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.wine_database_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.wine_database_description.paragraph_1' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.wine_database_description.paragraph_2' | translate }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/weekly_price_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.weekly_price_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.weekly_price_description.paragraph_1' | translate }}</p>
          <span>
            <ul class="m-0">
              <li class="feature-description">{{ 'logout_home_page.weekly_price_description.point_1' | translate }}</li>
              <li class="feature-description">{{ 'logout_home_page.weekly_price_description.point_2' | translate }}</li>
              <li class="feature-description">{{ 'logout_home_page.weekly_price_description.point_3' | translate }}</li>
            </ul>
          </span>
          <p class="feature-description m-0">{{ 'logout_home_page.weekly_price_description.paragraph_2' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/dashboard_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.exchange_precious_wines_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_precious_wines_description.paragraph_1' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_precious_wines_description.paragraph_2' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_precious_wines_description.paragraph_3' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_precious_wines_description.paragraph_4' | translate }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/kellerbook_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.development_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.development_description.paragraph_1' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.development_description.paragraph_2' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/friends_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.exchange_with_friends_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_with_friends_description.paragraph_1' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_with_friends_description.paragraph_2' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.exchange_with_friends_description.paragraph_3' | translate }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="feature-item d-flex flex-column">
          <img src="../../assets/icons/community_pink.svg" class="feature-image">
          <span class="feature-name font-bold">{{ 'logout_home_page.join_communities_title' | translate }}</span>
          <p class="feature-description m-0">{{ 'logout_home_page.join_communities_description.paragraph_1' | translate }}</p>
          <p class="feature-description m-0">{{ 'logout_home_page.join_communities_description.paragraph_2' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container ">
    <br>
    <div class="row">

        <div class="col-sm-4">
            <!--Blank 1-->
        </div>

        <div id="toVerifyEmail" class="col-sm-4 p-4 my-3 allFormBox">
            <form [formGroup]="verifyEmailForm" (ngSubmit)="onVESubmit()">
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <label class="allLabels">{{ 'login.verification_code' | translate }}</label>
                        <small id="emailHelp" class="form-text text-muted">{{ 'login.enter_code_msg' | translate }}
                            {{userName}}</small><br>
                        <input type="text" class="form-control allInputBoxes" formControlName="verifycode"
                            placeholder="{{'login.verification_code_msg' | translate}}"
                            [ngClass]="{ 'is-invalid': submitted && verificationDetails.verifycode.errors }" />

                        <div *ngIf="submitted && verificationDetails.verifycode.errors" class="invalid-feedback">
                            <div *ngIf="verificationDetails.verifycode.errors.required">{{ 'login.code_required' | translate }}</div>
                        </div>
                    </div>
                </div>
                <br>
                <button type="submit" class="btn btn-sm tr-btn-primary verifyBtn">{{ 'login.verify' | translate }}</button>
            </form>
        </div>
    </div>
</div>

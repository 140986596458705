import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  sortArrayOfObjects(data: any, keyName: string): any {
    const arrayToBeSorted = data;
    arrayToBeSorted.sort((a, b) => {
      const nameA = a[keyName].toUpperCase(); // ignore upper and lowercase
      const nameB = b[keyName].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return arrayToBeSorted;
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  currentLanguage = 'EN';
  constructor(private translate: TranslateService) {
    this.currentLanguage = translate.currentLang;
    translate.onLangChange.subscribe((language) => {
      this.currentLanguage = language.lang;
    })
  }

  ngOnInit(): void {
  }
}

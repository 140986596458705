export class Notification{
  label: string;
  id: string;
  category: string;
  cellar_bk_id?: string | undefined;
  user_wine_id?: string | undefined;
  invoker_user_id?: string | undefined;
  createdTimestamp: string;
  community_id?: string | undefined;
  userName?: string | undefined;
  sales_id: string | undefined;
  bid_detail_id: string | undefined;
  duration?: number | undefined;

  constructor(
    label: string, 
    id: string,
    category: string,
    cellar_bk_id: string,
    user_wine_id: string,
    invoker_user_id: string,
    createdTimestamp: string,
    community_id?: string | undefined,
    userName?: string | undefined,
    sales_id?: string | undefined,
    bid_detail_id?: string | undefined,
    duration?: number | undefined,
  ){
    this.label = label;
    this.id = id;
    this.category = category;
    this.cellar_bk_id = cellar_bk_id;
    this.user_wine_id = user_wine_id;
    this.invoker_user_id = invoker_user_id;
    this.createdTimestamp = createdTimestamp;
    this.community_id = community_id;
    this.userName = userName;
    this.sales_id = sales_id;
    this.bid_detail_id = bid_detail_id;
    this.duration = duration;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {
  transform(date: string): any {
    if (!date) {
      return null;
    } else {
      const notificationDate = new Date(date + ' UTC').getTime();
      return this.timeAgo(notificationDate);
      
    }
  }

  timeAgo(date: any): any {
    const seconds = (new Date().getTime() - date) / 1000;
  
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return interval + (interval === 1 ? ' year' : ' years') + ' ago';
    }
  
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + (interval === 1 ? ' month' : ' months') + ' ago';
    }
  
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + (interval === 1 ? ' day' : ' days') + ' ago';
    }
  
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + (interval === 1 ? ' hour' : ' hours') + ' ago';
    }
  
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + (interval === 1 ? ' minute' : ' minutes') + ' ago';
    }
  
    if(seconds < 10) return 'just now';
  
    return Math.floor(seconds) + ' seconds ago';
  };
}

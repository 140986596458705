import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import LoadingIndicatorService from '../../../sharedServices/loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css'],
})
export default class LoadingIndicatorComponent {

}

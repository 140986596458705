import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommunitiesService } from '../communities/services/communities.service';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Component({
  selector: 'app-federated-user-details',
  templateUrl: './federated-user-details.component.html',
  styleUrls: ['./federated-user-details.component.css']
})
export class FederatedUserDetailsComponent implements OnInit {

  federatedUserDetailsForm: FormGroup;
  submitted = false;
  acceptTermsCheck: string;
  userTypeList: any = ['Private User', 'Business User'];
  accountPrivateList: any = ['Free Version', 'Premium Version'];
  accountBusinessList: any = ['Premium Version'];
  paymentTypeList: any = ['Payment Monthly'];
  userName: string;
  firstTimeLogin = false;
  tncOverlay = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    public translate: TranslateService,
    private communityService: CommunitiesService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.federatedUserDetailsForm = this.formBuilder.group({
      userType: ['', Validators.required],
      termsConditions: [false, Validators.requiredTrue]
    });
    if (window.location.search.includes('firstTimeLogin')) {
      this.firstTimeLogin = true;
    }
  }

  get federatedUserDetails() {
    return this.federatedUserDetailsForm.controls;
  }

  onReset() {
    this.submitted = false;
    this.federatedUserDetailsForm.reset();

  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (!this.federatedUserDetailsForm.invalid) {
      if (this.federatedUserDetailsForm.value.userType && this.federatedUserDetailsForm.value.termsConditions == true) {
        this.updateFederatedUserAttributes(this.federatedUserDetailsForm.value.userType, this.federatedUserDetailsForm.value.termsConditions);
      } else {
        return;
      }
    } 
  }

  async updateFederatedUserAttributes(userType, termsConditions) {
    this.acceptTermsCheck = termsConditions;
    const user = await Auth.currentAuthenticatedUser();
    const paymentInfo = {
      paymentStatus: 'unPaid',
      paymentDate: 'NA',
      subscriptionId: 'NA'
    }

    if (!(user.attributes.hasOwnProperty('custom:userType'))) {
      await Auth.updateUserAttributes(user, {
        'custom:userType': userType,
        'custom:accountType': 'NA',
        'custom:paymentType': 'NA',
        'custom:termsConditions': this.acceptTermsCheck.toString(),
        'custom:paymentInfo': JSON.stringify(paymentInfo),
        'address': 'Not updated',
        'picture': 'No photo uploaded.',
        'custom:languagePreference': 'EN',
        'custom:notifications': 'ON',
        'custom:identityId': 'Not available',
      }).then(data => {
        this.onReset();
        // const address = JSON.parse(user.attributes.address);
        // this.httpService.getPostalCodesForNewUser(address.postalCode, address.country);
        this.communityService.joinOfficialCommunity().subscribe(() => {
          localStorage.removeItem('firstLogin');
          this.route.navigate(["/dashboard"]);
          this.toastService.showError("Your Details Successfully Updated");
        });
      });
    } else {
      // alert(this.translate.instant("AccountExists"));
      this.toastService.showError("Account Already Exists");
      this.route.navigate(["/dashboard"]);
    }
  }
}

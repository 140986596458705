import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {
  cookieAccepted = true;
  currentLanguage = 'EN';
  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
  ) {
    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((language) => {
      this.currentLanguage = language.lang;
    });
  }

  ngOnInit(): void {
    const cookieInfo = this.storageService.getCookie('tresInfo');
    if (cookieInfo.length) {
      this.cookieAccepted = true;
    } else {
      this.cookieAccepted = false;
    }
  }

  acceptRejectCookies(value: boolean): void {
    this.cookieAccepted = true;
    if (value) {
      const data = {
        requiredCookies: true,
        analyticalCookies: true,
        socialCookies: true,
        marketingCookies: true,
      }
      this.storageService.setCookie('tresInfo', data);
    } else {
      window.location.assign('https://www.google.com');
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getPostalCodesForNewUser, getCurrentUserVinoCoins, getVcTransaction } from '../graphql/queries.graphql';

@Injectable({
  providedIn: 'root',
})
export default class HttpService {
  APIKey = environment.countryStateCityAPIKey;
  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) { }

  get(endpoint) {
    return this.http.get(endpoint);
  }

  getCurrencyList() {
    return this.get('../../assets/data/currency-list.json');
  }

  getMiniCurrencyList() {
    return this.get('../../assets/data/currency-list-mini.json');
  }

  getGrapeList() {
    return this.get('../../assets/data/grape-list.json');
  }

  getCountryList() {
    return this.get('../../assets/data/country-list.json');
  }

  getCountryDialCodeList() {
    return this.get('../../assets/data/country-dial-code-list.json');
  }

  getCountryDialCodeWithISO3List() {
    return this.get('../../assets/data/country-dial-code-with-iso3.json');
  }

  getPaymentPreferenceList() {
    return this.get('../../assets/data/payment-preference-list.json');
  }

  getTransactionStatement() {
    return this.get('../../assets/data/transaction-statement.json');
  }

  getCountryListFromAPI(): Observable<any> {
    return this.http.get('https://api.countrystatecity.in/v1/countries', { headers: {"X-CSCAPI-KEY": this.APIKey}});
  }

  getStatesByCountry(country: string): Observable<any> {
    return this.http.get('https://api.countrystatecity.in/v1/countries/'+ country +'/states', { headers: {"X-CSCAPI-KEY": this.APIKey}});
  }

  getCitiesByStateAndCountry(country: string, state: string): Observable<any> {
    return this.http.get('https://api.countrystatecity.in/v1/countries/'+ country +'/states/'+ state +'/cities', { headers: {"X-CSCAPI-KEY": this.APIKey}});
  }

  getCitiesByCountry(country: string): Observable<any> {
    return this.http.get('https://api.countrystatecity.in/v1/countries/'+ country +'/cities', { headers: {"X-CSCAPI-KEY": this.APIKey}});
  }

  getCountryDetialsByCountryCode(country: string): Observable<any> {
    return this.http.get('https://api.countrystatecity.in/v1/countries/'+ country , { headers: {"X-CSCAPI-KEY": this.APIKey}});
  }

  getPostalCodes(country: string, state:string, city: string): any {
    const payload = {
      inputData: {
        country: country,
        state: state,
        city: city,
      }
    };
    return this.http.post(environment.postalCodeEndpoint, payload, { headers: { "X-API-KEY": environment.APIKey }});
  }

  getPostalCodesForNewUser(pinCode: number, country: string): any {
    this.apollo.watchQuery<any>({
      query: getPostalCodesForNewUser,
      variables:{
        pincode: pinCode,
        country: country
      }
    }).valueChanges.subscribe(() => {});
  }

  getUserAccessInfo(): any {
    return this.get('../../assets/data/user-access-details.json');
  }
  
  getVinoBalance() {
    return this.apollo.watchQuery<any>({
      query: getCurrentUserVinoCoins,
      fetchPolicy: 'network-only'
    });
  }

  getTransactionDetails(payload: any) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'network-only',
      query: getVcTransaction,
      variables:{
        startDate: payload.startDate,
        endDate: payload.endDate
      }
    })
  }
}
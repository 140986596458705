<div class="container">
    <br>
    <div class="row">
        <div class="col-12" *ngIf="currentLanguage === 'DE'">
            <h1>Datenschutzerklärung</h1><br>
            <p>Wir freuen uns über Ihren Besuch auf unserer Webseite www.tresorvino.de und Ihr Interesse an unserem
                Unternehmen und unseren Angeboten. Für externe Links zu fremden Inhalten übernehmen wir trotz
                sorgfältiger inhaltlicher Kontrolle keine Haftung, da wir die Übermittlung dieser Information nicht
                veranlasst, den Adressaten der übermittelten Information und die übermittelten Informationen selbst
                nicht ausgewählt oder verändert haben.</p>

            <p>Der Schutz Ihrer personenbezogenen Daten bei der Erhebung, Verarbeitung und Nutzung anlässlich Ihres
                Besuchs auf unseren Internetseiten ist uns ein wichtiges Anliegen und erfolgt im Rahmen der gesetzlichen
                Vorschriften, über die Sie sich z.B. unter www.bfd.bund.de informieren können.</p>

            <p>Im Folgenden erläutern wir Ihnen, welche Informationen wir während Ihres Besuchs auf unseren Webseiten
                erfassen und wie diese genutzt werden:</p>

            <br>

            <h5>1. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h5><br>
            <p>a) Beim Besuch der Webseite</p>

            <p>Bei jedem Zugriff eines Nutzers (oder sonstigen Besuchers) auf unserer Webseite werden durch den auf
                Ihrem Endgerät (Computer, Laptop, Tablet, Smartphone, etc.) zum Einsatz kommenden Internet-Browser
                automatisch Informationen an den Server unserer Webseite gesendet. Diese Informationen werden temporär
                in einem sog. Logfile (Protokolldatei) gespeichert.</p>

            <p>Folgende Daten werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>

            <ul>
                <li>IP-Adresse des anfragenden Rechners, sowie Geräte-ID oder individuelle Geräte-Kennung und Gerätetyp,
                </li>
                <li>Name der abgerufenen Datei und übertragene Datenmenge, sowie Datum und Uhrzeit des Abrufs,</li>
                <li>Meldung über erfolgreichen Abruf,</li>
                <li>anfragende Domain,</li>
                <li>Beschreibung des Typs des verwendeten Internet-Browsers und ggf. des Betriebssystems Ihres Endgeräts
                    sowie der Name Ihres Access-Providers,</li>
                <li>Ihre Browser-Verlaufsdaten sowie Ihre standardmäßigen Weblog-Informationen,</li>
                <li>Standortdaten, einschließlich Standortdaten von Ihrem Mobilgerät. Bitte beachten Sie, dass Sie bei
                    den meisten Mobilgeräten die Verwendung von Standortservices im Einstellungsmenü des Mobilgeräts
                    steuern oder deaktivieren können.</li>

            </ul>

            <p>Unser berechtigtes Interesse gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Erhebung der Daten beruht auf den
                folgenden Zwecken: Gewährleistung eines reibungslosen Verbindungsaufbaus und einer komfortablen Nutzung
                der Webseite, Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken.
            </p>

            <p>In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
            </p>

            <p>b) Bei Nutzung unseres Kontaktformulars</p>

            <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Webseite
                bereitgestelltes Formular Kontakt aufzunehmen. Dabei sind mindestens die folgenden Angaben erforderlich:
                Name, E-Mail Adresse, Anschrift und Benutzername des Nutungskontos, damit wir wissen, von wem die
                Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
                Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt gem. Art. 6 Abs. 1 S. 1 lit. a
                DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
                Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach
                Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.
            </p>

            <p>c) Bei Abschluss eines Vertragsverhältnisses</p>

            <p>Bei Abschluss eines Vertragsverhältnisses auf unserer Webseite bitten wir Sie um die Angaben folgender
                personenbezogener Daten:</p>

            <ul>
                <li>Daten, die Sie persönlich identifizieren wie Name und E-Mail-Adresse</li>
                <li>Kontaktdaten, wie Anschrift, Rechnungsanschrift und Telefonnummer.</li>
                <li>Daten, die Ihr Unternehmen identifizieren, wie Firma, Anschrift, Kommunikationsdaten
                    (E-Mail-Adresse, Telefon-, Faxnummer), ggf. USt-ID oder Steuernummer) - Informationen zu Ihrem
                    Zahlungsmittel</li>
                <li>weitere personenbezogene Daten, zu deren Erfassung und Verarbeitung wir gesetzlich verpflichtet oder
                    berechtigt sind und die wir für Ihre Authentifizierung, Identifizierung oder zur Überprüfung der von
                    uns erhobenen Daten benötigen.</li>
            </ul>

            <p>Die genannten Daten werden zur Abwicklung des Vertragsverhältnisses verarbeitet. Die Verarbeitung der
                Daten erfolgt aufgrund von Art. 6 Abs. 1 lit. b DSGVO. Die Speicherfrist ist auf den Vertragszweck und,
                sofern vorhanden, gesetzliche und vertragliche Aufbewahrungspflichten beschränkt.</p>

            <p>d) Einsatz von Zahlungsdienstleistern</p>

            <p>Zur Bezahlung von mit uns kostenpflichtig abgeschlossenen Verträgen arbeiten wir zudem mit
                Zahlungsdienstleistern zusammen. Ihre Zahlungsdaten geben wir im Rahmen der Zahlungsabwicklung –
                zweckgebunden auf die Zahlung – an den beauftragten Zahlungsdienstleister weiter, sofern dies für die
                Zahlungsabwicklung erforderlich ist. Rechtsgrundlage für die Weitergabe der Daten ist hierbei jeweils
                Art. 6 Abs. 1 lit. b DSGVO.</p>

            <br>
            <h5>2. Weitergabe von personenbezogenen Daten</h5><br>
            <p>Eine Übermittlung Ihrer Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht
                statt.</p>

            <p>Wir geben Ihre Daten nur an Dritte weiter, wenn:</p>

            <ul>
                <li>Sie eine ausdrückliche Einwilligung dazu erteilt haben nach (Art. 6 Abs. 1 S. 1 lit. a DSGVO),</li>
                <li>dies für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist (Art. 6 Abs. 1 lit. b
                    DSGVO),</li>
                <li>eine gesetzliche Verpflichtung zur Weitergabe besteht (Art.6 Abs.1 lit. c DSGVO),</li>
                <li>die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist
                    und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der
                    Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f DSGVO).</li>
            </ul>

            <p>In diesen Fällen beschränkt sich der Umfang der übermittelten Daten jedoch nur auf das erforderliche
                Minimum.</p>

            <p>Unsere Datenschutzbestimmungen stehen im Einklang mit den geltenden datenschutzrechtlichen Bestimmungen
                und die Daten werden nur in der Bundesrepublik Deutschland und in der Europäischen Union verarbeitet.
                Eine Übermittlung in Drittländer findet nicht statt und ist nicht beabsichtigt.</p>

            <br>
            <h5>3. Betroffenenrechte</h5><br>
            <p>Auf Anfrage werden wir Sie gern informieren, ob und welche personenbezogenen Daten zu Ihrer Person
                gespeichert sind (Art. 15 DSGVO), insbesondere über die Verarbeitungszwecke, die Kategorie der
                personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden
                oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
                Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer
                Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten
                Entscheidungsfindung einschließlich Profiling.</p>

            <p>Ihnen steht zudem das Recht zu, etwaig unrichtig erhobene personenbezogene Daten berichtigen oder
                unvollständig erhobene Daten vervollständigen zu lassen (Art. 16 DSGVO).</p>

            <p>Ferner haben Sie das Recht, von uns die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, sofern
                die gesetzlichen Voraussetzungen hierfür vorliegen (Art. 18 DSGVO).</p>

            <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
                verlangen (Art. 20 DSGVO).</p>

            <p>Darüber hinaus steht Ihnen das sogenannte „Recht auf Vergessenwerden“ zu, d.h. Sie können von uns die
                Löschung Ihrer personenbezogenen Daten verlangen, sofern hierfür die gesetzlichen Voraussetzungen
                vorliegen (Art. 17 DSGVO).</p>

            <p>Unabhängig davon werden Ihre personenbezogenen Daten automatisch von uns gelöscht, wenn der Zweck der
                Datenerhebung weggefallen oder die Datenverarbeitung unrechtmäßig erfolgt ist.</p>

            <p>Gemäß Art. 7 Abs. 3 DSGVO haben Sie das Recht Ihre einmal erteilte Einwilligung jederzeit gegenüber uns
                zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
                für die Zukunft nicht mehr fortführen dürfen.</p>

            <p>Sie haben zudem das Recht, jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch zu
                erheben, sofern ein Widerspruchsrecht gesetzlich vorgesehen ist. Im Falle eines wirksamen Widerrufs
                werden Ihre personenbezogenen Daten ebenfalls automatisch durch uns gelöscht (Art. 21 DSGVO).</p>

            <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an:
                <b>widerspruch@tresorvino.de</b>.
            </p>

            <p>Bei Verstößen gegen die datenschutzrechtlichen Vorschriften haben Sie gem. Art. 77 DSGVO die Möglichkeit,
                Beschwerde bei einer Aufsichtsbehörde zu erheben.</p>

            <br>
            <h5>4. Dauer der Datenspeicherung</h5><br>
            <p>Die erhobenen Daten werden solange bei uns gespeichert, wie dies für die Durchführung der mit uns
                eingegangen Verträge erforderlich ist oder Sie Ihr Recht auf Löschung oder Ihr Recht auf
                Datenübertragung auf ein anderes Unternehmen nicht ausgeübt haben.</p>

            <br>
            <h5>5. Cookies</h5><br>
            <p>Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um kleine Text-Dateien, die Ihr
                Browser automatisch erstellt und die auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Webseite
                besuchen. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
                spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir unmittelbar Kenntnis von
                Ihrer Identität erhalten. Der Einsatz von Cookies dient zunächst dazu, die Nutzung unseres Angebots für
                Sie angenehmer zu gestalten:</p>

            <p>So setzen wir sog. Session-Cookies ein, um zu erkennen, dass Sie einzelne Unterseiten unserer Webseite
                bereits besucht haben. So wird sofern Sie sich registriert haben, ihr Kennwort während der Dauer Ihres
                Besuchs auf unserer Webseite und des Wechseln von Unterseiten gespeichert, so dass Sie es nicht jedes
                Mal neu eingeben müssen. Diese Session-Cookies werden nach Verlassen unserer Webseite automatisch
                gelöscht.</p>

            <p>Wir setzen zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
                festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere
                Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche
                Eingaben und Einstellungen Sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>

            <p>Zum anderen setzen wir Cookies ein, um die Nutzung unserer Webseite statistisch zu erfassen und
                auszuwerten und unser Angebotes für Sie zu optimieren. Diese Cookies ermöglichen es uns, bei einem
                erneuten Besuch unserer Webseite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies
                werden nach einer jeweils definierten Zeit automatisch gelöscht. Die durch Cookies verarbeiteten Daten
                sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6
                Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten Browser akzeptieren Cookies automatisch. Wenn Sie es
                nicht wünschen, dass wir Informationen über Ihren Computer wiedererkennen, stellen Sie Ihren
                Internetbrowser bitte so ein, dass er Cookies von Ihrer Computerfestplatte löscht, alle Cookies
                blockiert oder Sie warnt, bevor ein Cookie gespeichert wird. Wie Sie Cookies löschen oder blockieren
                können, erfahren Sie im Hilfe- und Support-Bereich Ihres Internet-Browsers. Dort finden Sie Anweisungen
                zur Suche der Datei oder des Verzeichnisses, in dem Cookies gespeichert werden. Bitte beachten Sie in
                jedem Fall, dass die vollständige Deaktivierung von Cookies dazu führen kann, dass Sie nicht alle
                Funktionen unserer Webseite nutzen können.</p>

            <p>Bei der Bereitstellung unseres Internetangebots werden Java-Applets und Java-Script verwendet. Falls Sie
                sich aus Sicherheitsgründen diese Hilfsprogramme bzw. aktiven Inhalte nicht nutzbar machen wollen,
                sollten Sie die entsprechende Einstellung Ihres Browsers deaktivieren.</p>

            <br>
            <h5>Datensicherheit</h5><br>
            <p>Wir sind um alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen bemüht, um Ihre
                personenbezogenen Daten so zu speichern, dass sie weder Dritten noch der Öffentlichkeit zugänglich sind.
                Sollten Sie mit uns per E-Mail in Kontakt treten wollen, so weisen wir Sie darauf hin, dass bei diesem
                Kommunikationsweg die Vertraulichkeit der übermittelten Informationen nicht vollständig gewährleistet
                werden kann. Wir empfehlen Ihnen daher, uns vertrauliche Informationen ausschließlich über den Postweg
                zukommen zu lassen.</p>

            <br>
            <h5>Aktualität und Änderung dieser Datenschutzerklärung</h5><br>
            <p>Diese Datenschutzerklärung ist aktuell gültig.</p>

            <p>Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher
                beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
                Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite unter <a
                    href="www.tresorvino.de/datenschutzerklaerung">www.tresorvino.de/datenschutzerklaerung</a> von Ihnen
                abgerufen und ausgedruckt werden.</p>

            <p>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen:</p>

            <p>Sebastian Schäfer – baroxx Projektmanagement GmbH</p>

            <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>

            <p>Tresorvino</p>

            <p>Baroxx Projektmanagement GmbH</p>

            <p>69118 Heidelberg</p>

            <p>datenschutz@tresorvino.de</p>

        </div>
        <div class="col-12" *ngIf="currentLanguage === 'EN'">
            <h1>Privacy Policy</h1><br>
            <p>Thank you for visiting our website Tresorvino  and for your interest in our company and our services. Despite careful control of the content, we assume no liability for external links to third-party content, as we did not initiate the transmission of this information and did not select or change the addressee of the transmitted information or the transmitted information itself.</p>
            <p>The protection of your personal data when it is collected, processed and used during your visit to our website is important to us and is carried out in accordance with the statutory provisions, which you can find out about at www.bfd.bund.de, for example.</p>
            <p>In the following, we explain what information we collect during your visit to our website and how it is used:</p>
            <h5>collection and storage of personal data and the nature and purpose of its use</h5>
            <p>a) When visiting the website</p>
            <p>Each time a user (or other visitor) accesses our website, the Internet browser used on your device (computer, laptop, tablet, smartphone, etc.) automatically sends information to the server of our website. This information is temporarily stored in a log file.</p>
            <p>The following data is collected without any action on your part and stored until it is automatically deleted</p>
            <ul>
                <li>IP address of the requesting computer, as well as device ID or individual device identifier and device type,</li>
                <li>Name of the retrieved file and amount of data transferred, as well as the date and time of retrieval,</li>
                <li>Notification of successful retrieval,</li>
                <li>requesting domain,</li>
                <li>Description of the type of Internet browser used and, if applicable, the operating system of your end device and the name of your access provider,</li>
                <li>Your browser history data and your standard weblog information,</li>
                <li>Location data, including location data from your mobile device. Please note that on most mobile devices you can control or disable the use of location services in the settings menu of the mobile device.</li>
            </ul>
            <p>Our legitimate interest pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR in collecting the data is based on the following purposes: ensuring a smooth connection setup and convenient use of the website, evaluation of system security and stability and for other administrative purposes.</p>
            <p>Under no circumstances do we use the data collected for the purpose of drawing conclusions about your person.</p>
            <p>b) When using our contact form</p>
            <p>If you have questions of any kind, we offer you the opportunity to contact us using a form provided on the website. At least the following information is required: name, e-mail address, address and user name of the user account so that we know who sent the request and can answer it. Further information can be provided voluntarily. Data processing for the purpose of contacting us is carried out in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR on the basis of your voluntarily given consent. The personal data collected by us for </p>
            <p>the use of the contact form will be automatically deleted after your inquiry has been dealt with.</p>
            <p>c) Upon conclusion of a contractual relationship</p>
            <p>When concluding a contractual relationship on our website, we ask you to provide the following personal data:</p>
            <ul>
                <li>Data that identifies you personally, such as name and e-mail address</li>
                <li>Contact details, such as address, billing address and telephone number.</li>
                <li>Data that identifies your company, such as company, address, communication data (e-mail address, telephone, fax number), VAT ID or tax number if applicable) - Information about your means of payment
                </li>
                <li>other personal data which we are legally obliged or entitled to collect and process and which we require for your authentication, identification or to verify the data we have collected.
                </li>
            </ul>
            <p>The aforementioned data is processed in order to process the contractual relationship. The data is processed on the basis of Art. 6 para. 1 lit. b GDPR. The storage period is limited to the purpose of the contract and, if applicable, statutory and contractual retention obligations.</p>
            <p>d) Use of payment service providers</p>
            <p>We also work with payment service providers for the payment of contracts concluded with us for a fee. We pass on your payment data to the commissioned payment service provider as part of payment processing - earmarked for the payment - if this is necessary for payment processing. The legal basis for the transfer of data is Art. 6 para. 1 lit. b GDPR.</p>
            <p>transfer of personal data</p>
            <p>Your data will not be transferred to third parties for purposes other than those listed below.</p>
            <p>We only pass on your data to third parties if:</p>
            <ul>
                <li>you have given your express consent to this (Art. 6 para. 1 sentence 1 lit. a GDPR),</li>
                <li>this is necessary for the processing of contractual relationships with you (Art. 6 para. 1 lit. b GDPR),</li>
                <li>there is a legal obligation to pass on the data (Art. 6 para. 1 lit. c GDPR),</li>
                <li>the disclosure is necessary for the assertion, exercise or defense of legal claims and there is no reason to assume that you have an overriding interest worthy of protection in not disclosing your data (Art. 6 para. 1 sentence 1 lit. f GDPR).</li>
            </ul>
            <p>In these cases, however, the scope of the transmitted data is limited to the necessary minimum.</p>
            <p>Our data protection provisions are in accordance with the applicable data protection regulations and the data is only processed in the Federal Republic of Germany and in the European Union. A transfer to third countries does not take place and is not intended.</p>
            <p>rights of data subjects</p>
            <p>Upon request, we will be happy to inform you whether and which personal data relating to you is stored (Art. 15 GDPR), in particular about the processing purposes, the category of personal data, the categories of recipients to whom your data has been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right to lodge a complaint, the origin of your data if it was not collected by us, and the existence of automated decision-making including profiling.</p>
            <p>You also have the right to have any incorrectly collected personal data corrected or incompletely collected data completed (Art. 16 GDPR).</p>
            <p>Furthermore, you have the right to demand that we restrict the processing of your data, provided that the legal requirements for this are met (Art. 18 GDPR).</p>
            <p>You have the right to receive the personal data concerning you in a structured, commonly used and machine-readable format or to request that it be transmitted to another controller (Art. 20 GDPR).</p>
            <p>In addition, you have the so-called "right to be forgotten", i.e. you can request that we erase your personal data, provided that the legal requirements for this are met (Art. 17 GDPR).</p>
            <p>Irrespective of this, your personal data will be automatically deleted by us if the purpose of the data collection no longer applies or the data processing has been carried out unlawfully.</p>
            <p>In accordance with Art. 7 para. 3 GDPR, you have the right to withdraw your consent at any time. As a result, we may no longer continue the data processing that was based on this consent in the future.</p>
            <p>You also have the right to object to the processing of your personal data at any time, insofar as a right of objection is provided for by law. In the event of an effective revocation, your personal data will also be automatically deleted by us (Art. 21 GDPR).</p>
            <p>If you would like to exercise your right of revocation or objection, simply send an e-mail to: widerspruch@tresorvino.de.</p>
            <p>In the event of violations of data protection regulations, you have the right to lodge a complaint with a supervisory authority in accordance with Art. 77 GDPR.</p>

            <h5>4 Duration of data storage</h5>
            <p>The data collected will be stored by us as long as this is necessary for the execution of the contracts entered into with us or you have not exercised your right to erasure or your right to transfer data to another company.</p>

            <p>1. cookies</p>
            <p>We use cookies on our website. These are small text files that your browser automatically creates and that are stored on your device when you visit our website. Information is stored in the cookie that results in each case in connection with the specific end device used. However, this does not mean that we obtain direct knowledge of your identity. The use of cookies initially serves to make the use of our website more pleasant for you:</p>
            <p>For example, we use so-called session cookies to recognize that you have already visited individual subpages of our website. For example, if you have registered, your password will be saved for the duration of your visit to our website and when you change sub-pages so that you do not have to re-enter it each time. These session cookies are automatically deleted after you leave our website.</p>
            <p>To optimize user-friendliness, we use temporary cookies that are stored on your end device for a specified period of time. If you visit our site again to use our services, it is automatically recognized that you have already visited us and which entries and settings you have made so that you do not have to enter them again.</p>
            <p>On the other hand, we use cookies to statistically record and evaluate the use of our website and to optimize our offer for you. These cookies enable us to automatically recognize that you have already visited our website when you visit it again. These cookies are automatically deleted after a defined period of time. The data processed by cookies is required for the purposes mentioned to protect our legitimate interests and those of third parties in accordance with Art. 6 para. 1 sentence 1 lit. f GDPR. Most browsers accept cookies automatically. If you do not want us to recognize information about your computer, please set your Internet browser so that it deletes cookies from your computer hard drive, blocks all cookies or warns you before a cookie is stored. You can find out how to delete or block cookies in the help and support section of your Internet browser. There you will find instructions on how to search for the file or directory in which cookies are stored. Please note in any case that the complete deactivation of cookies may result in you not being able to use all the functions of our website.</p>
            <p>Java applets and Java script are used to provide our website. If you do not wish to use these auxiliary programs or active content for security reasons, you should deactivate the corresponding setting in your browser.</p>

            <h5>Data security</h5>
            <p>We take all necessary technical and organizational security measures to store your personal data in such a way that it is not accessible to third parties or the public. Should you wish to contact us by e-mail, we would like to point out that the confidentiality of the information transmitted cannot be fully guaranteed with this method of communication. We therefore recommend that you only send us confidential information by post.</p>
            <p>Up-to-dateness and amendment of this privacy policy</p>
 
            <p>This privacy policy is currently valid.</p>
            
            <p>Due to the further development of our website and offers on it or due to changed legal or official requirements, it may become necessary to change this data protection declaration. You can access and print out the current privacy policy at any time on the website at www.tresorvino.de/datenschutzerklaerung.</p>
            
            <p>Name and contact details of the controller:</p>
            
            <p>Sebastian Schäfer - baroxx Projektmanagement GmbH</p>
            
            <p>This data protection information applies to data processing by</p>
            
            <p>Tresorvino</p>
            
            <p>Baroxx Project Management GmbH</p>
            
            <p>69118 Heidelberg</p>
            
            <p>datenschutz@tresorvino.de</p>
        </div>
    </div>
</div>
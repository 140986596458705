import { gql } from 'apollo-angular';

export const createCommunityQuery = gql`
  mutation($communityName: String!, $communityDescription: String, $communityPicture: String, $communityLocation: String, $communityDisclosure: Boolean!) {
    createCommunity(input: {community_name: $communityName, description: $communityDescription, picture: $communityPicture, community_location: $communityLocation, is_public: $communityDisclosure }) {
      community_name
      community_location
      created_by
      creator {
        error
        firstname
        id
        identityId
        lastname
        picture
      }
      description
      id
      is_public
      members {
        total
        users {
          comments_allowed
          posts_allowed
          is_admin
          joined_timestamp
          membership_status
          user_id
          user {
            error
            firstname
            lastname
            id
            identityId
            picture
          }
        }
      }
      picture
      updated_timestamp
      created_timestamp
    }
  }
`;

export const updateCommunity = gql`
mutation MyMutation(
  $id: ID!,
  $community_location: String,
  $community_name: String,
  $description: String,
  $picture: String,
  $is_public: Boolean
) {
  updateCommunity(
    id: $id,
    input: {
      is_public: $is_public,
      picture: $picture,
      description: $description,
      community_name: $community_name,
      community_location: $community_location
    }
  ) {
    community_location
    community_name
    created_by
    created_timestamp
    updated_timestamp
    is_public
    picture
    id
    description
    creator {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
    members {
      total
      nextToken
      users {
        is_admin
        joined_timestamp
        membership_status
        user_id
        user {
          picture
          lastname
          id
          identityId
          error
          firstname
        }
      }
    }
  }
}`;

export const deleteCommunityQuery = gql`
mutation($id: ID!){
  deleteCommunity(id: $id) {
    id
  }
}
`;

export const inviteFriendToCommunityQuery = gql`
mutation($community_id: String!, $friend_id: String!){
  inviteFriendToCommunity(community_id: $community_id, friend_id: $friend_id)
}
`;

export const rejectCommunityInvitation = gql`
mutation MyMutation($communityId: ID!) {
  rejectInvitationToCommunity(id: $communityId)
}
`;

export const joinCommunityQuery = gql`
mutation($id: ID!){
  joinCommunity(id: $id)
}
`;

export const leaveCommunityQuery = gql`
mutation($id: ID!){
  leaveCommunity(id: $id)
}
`;

export const createCommunityPostMutation = gql`
mutation MyMutation($body: String!, $community_id: ID!, $image: String) {
  createPost(input: {body: $body, image: $image, community_id: $community_id}) {
    body
    community_id
    created_by
    created_timestamp
    image
    post_id
    updated_timestamp
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
  }
}`;

export const updateCommunityPost = gql`
mutation MyMutation($post_id: ID!, $body: String, $community_id: ID!, $image: String) {
  updatePost(post_id: $post_id, input: {image: $image, community_id: $community_id, body: $body}) {
    body
    community_id
    created_by
    created_timestamp
    image
    post_id
    updated_timestamp
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
  }
}`;

export const deleteCommunityPost = gql`
mutation MyMutation($post_id: ID!, $community_id: ID!) {
  deletePost(community_id: $community_id, post_id: $post_id) {
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
    updated_timestamp
    post_id
    image
    created_timestamp
    created_by
    community_id
    body
  }
}`;

export const reactToPost = gql`
mutation MyMutation($community_id: ID!, $post_id: ID!, $reaction_type: Int!) {
  reactToPost(input: {community_id: $community_id, post_id: $post_id, reaction_type: $reaction_type}) {
    community_id
    post_id
    reaction_type
    timestamp
    user_id
    user {
      firstname
      id
      identityId
      lastname
      picture
    }
  }
}`;

export const createPostComment = gql`
mutation MyMutation($post_id: ID!, $community_id: ID!, $body: String!) {
  createComment(input: {body: $body, post_id: $post_id, community_id: $community_id}) {
    body
    comment_id
    community_id
    created_by
    created_timestamp
    post_id
    updated_timestamp
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
  }
}`;

export const reactToComment = gql`
mutation MyMutation($reaction_type: Int!, $comment_id: ID!, $post_id: ID!) {
  reactToComment(input: {reaction_type: $reaction_type, comment_id: $comment_id, post_id: $post_id}) {
    user_id
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
    timestamp
    reaction_type
    comment_id
    post_id
  }
}`;

export const updatePostComment = gql`
mutation MyMutation($body: String!, $post_id: ID!, $comment_id: ID!) {
  updateComment(comment_id: $comment_id, input: {body: $body, post_id: $post_id}) {
    body
    comment_id
    community_id
    created_by
    created_timestamp
    post_id
    updated_timestamp
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
  }
}`;

export const deletePostComment = gql`
mutation MyMutation($post_id: ID!, $comment_id: ID!) {
  deleteComment(comment_id: $comment_id, post_id: $post_id) {
    body
    community_id
    comment_id
    created_by
    created_timestamp
    post_id
    updated_timestamp
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
  }
}`;

export const joinOfficialCommunity = gql`
mutation MyMutation {
  joinOfficialCommunity
}`;

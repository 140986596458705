import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthenticationService } from './sharedServices/authentication.service';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { VerifyAccountComponent } from './authentication/verify-account/verify-account.component';
import { UnauthGuardService } from './sharedServices/unauth-guard.service';
import { FederatedUserDetailsComponent } from './federated-user-details/federated-user-details.component';
import { ImprintComponent } from './imprint/imprint.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import LoginComponent from './authentication/login/login.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { FaqComponent } from './shared/components/faq/faq.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [UnauthGuardService] },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'faqs',
    component: FaqComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then((m) => m.FriendsModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then((m) => m.FriendsModule),
    canActivate: [AuthenticationService],
  },
  { path: 'login', component: LoginComponent, canActivate: [UnauthGuardService] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [UnauthGuardService] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [UnauthGuardService] },
  { path: 'registration', component: RegistrationComponent, canActivate: [UnauthGuardService] },
  { path: 'verify-account', component: VerifyAccountComponent, canActivate: [UnauthGuardService] },
  { path: 'federated-user-details', component: FederatedUserDetailsComponent },
  // { path: 'payment', component: PaypalComponent, canActivate: [PaymentGuardService] },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'communities',
    loadChildren: () => import('./communities/communities.module').then((m) => m.CommunitiesModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'cellarbook',
    loadChildren: () => import('./cellarbook/cellarbook.module').then((m) => m.KellerbookModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
    canActivate: [AuthenticationService],
  },
  {
    path: 'introduction',
    loadChildren: () => import('./modules/introduction/introduction.module').then((m) => m.IntroductionModule),
  },
  { path: '**', redirectTo: '/home' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export default class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Observable, map, of, startWith } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import HttpService from 'src/app/sharedServices/http.service';
import LoadingIndicatorService from 'src/app/sharedServices/loading-indicator.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  userTypeList = [];
  userType = '';
  countryList = [];
  stateList = [];
  cityList = [];
  paymentPreferences = [];
  phoneCodeList = [];
  inclusiveCharacters = 'a-zA-Z äöüÄÖÜßÀÂÉÊÈËÌÏÎÔÙÛÇÆŒàáâéêèëìïîôùûçæœ';
  firstFormGroup = this.fb.group({
    userType: ['', Validators.required],
  });
  secondFormGroup = this.fb.group({
    companyName: ['', [Validators.required, Validators.pattern('^['+this.inclusiveCharacters+'\'-]*$'), Validators.maxLength(50)]],
    firstName: ['', [Validators.required, Validators.pattern('^['+this.inclusiveCharacters+'\'-]*$'), Validators.maxLength(30)]],
    lastName: ['', [Validators.required, Validators.pattern('^['+this.inclusiveCharacters+'\'-]*$'), Validators.maxLength(30)]],
    emailAddress: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$"), Validators.email]],
    dialCode: ['', Validators.required],
    phoneNumber: ['', [Validators.required, Validators.maxLength(30), Validators.pattern("^([0-9]{7,15})*$")]],
    birthDate: ['', Validators.required],
    vatNumber: ['', [Validators.maxLength(30), Validators.pattern('/^[A-Z0-9\s]+$/')]],
    paymentPreference: [[], [Validators.required]],
  });
  thirdFormGroup = this.fb.group({
    street: ['', [Validators.required, Validators.pattern("^["+this.inclusiveCharacters+"0-9,.'-]*$"), Validators.maxLength(30)]],
    houseNumber: ['', [Validators.required, Validators.pattern("^[0-9-'(),.]+$"), Validators.maxLength(10)]],
    zipCode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(20)]],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    country: ['', [Validators.required]],
    additionalInfo: ['', [Validators.maxLength(500)]],
  });
  fourthFormGroup = this.fb.group({
    password: ['', [Validators.required, this.passwordValidator]],
    confirmPassword: ['', [Validators.required]],
    termsConditions: [false, Validators.required],
  });
  formSubmitted = false;
  minDate = new Date('01/01/' + (new Date().getFullYear() - 100));
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  postalCodes: string[] = [];
  filteredOptions: Observable<string[]>;
  tncOverlay = false;
  stateInfo: any = {};

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private httpService: HttpService,
    private utilityService: UtilityService,
    private loader: LoadingIndicatorService,
  ) {
    this.translate.onLangChange.subscribe((data: any) => {
      this.setTranslations();
    })
  }

  ngOnInit(): void {
    this.translate.get('login.user_type').subscribe((data: string) => {
      this.setTranslations();
    });
    this.getCountryList();
    this.getPaymentPreferences();
    this.onPhoneCodeSelected();
    this.filteredOptions = this.thirdFormGroup.get('zipCode').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(filterValue: string): string[] {
    const filteredValue = this.postalCodes.filter(option => option?.toLowerCase().includes(filterValue));
    if (this.postalCodes.length && !filteredValue.length) {
      setTimeout(() => {
        this.thirdFormGroup.get('zipCode').setValue('');
      }, 2000);
    }
    return filteredValue;
  }

  onPhoneCodeSelected() {
    this.loader.show();
    this.httpService.getCountryDialCodeWithISO3List().subscribe((response: any) => {
      this.phoneCodeList = response;
      this.loader.hide();
    });
  }

  getCountryList(): void {
    this.loader.show();
    this.httpService.getCountryListFromAPI().subscribe((response: any) => {
      if (response.length) {
        this.countryList = this.utilityService.sortArrayOfObjects(response, 'name');
      }
      this.loader.hide();
    });
  }

  getStateListByCountry(countryCode: string): void {
    this.loader.show();
    this.httpService.getStatesByCountry(countryCode).subscribe((response: any) => {
      if (response.length) {
        this.stateList = this.utilityService.sortArrayOfObjects(response, 'name');
        this.thirdFormGroup.get('state').setValidators([Validators.required]);
      } else {
        this.thirdFormGroup.get('state').clearValidators();
        this.getCityListByCountry(countryCode);
      }
      this.thirdFormGroup.get('state').updateValueAndValidity();
      this.loader.hide();
    });
  }

  getCityListByCountryAndState(countryCode: string, stateCode: string): void {
    this.loader.show();
    this.httpService.getCitiesByStateAndCountry(countryCode, stateCode).subscribe((response: any) => {
      if (response.length) {
        this.cityList = this.utilityService.sortArrayOfObjects(response, 'name');
        this.thirdFormGroup.get('city').setValidators([Validators.required]);
      } else {
        this.thirdFormGroup.get('city').clearValidators();
      }
      this.thirdFormGroup.get('city').updateValueAndValidity();
      this.loader.hide();
    });
  }

  getCityListByCountry(countryCode: string): void {
    this.loader.show();
    this.httpService.getCitiesByCountry(countryCode).subscribe((response: any) => {
      if (response.length) {
        this.cityList = this.utilityService.sortArrayOfObjects(response, 'name');
        this.thirdFormGroup.get('city').setValidators([Validators.required]);
      } else {
        this.thirdFormGroup.get('city').setValue('');
        this.thirdFormGroup.get('city').clearValidators();
        this.getPostalCodes();
      }
      this.thirdFormGroup.get('city').updateValueAndValidity();
      this.loader.hide();
    });
  }

  getPaymentPreferences(): void {
    this.loader.show();
    this.httpService.getPaymentPreferenceList().subscribe((response: any) => {
      this.paymentPreferences = response;
      this.loader.hide();
    });
  }

  onCountrySelect(): void {
    if (this.thirdFormGroup.value?.country) {
      this.thirdFormGroup.get('state').reset();
      this.thirdFormGroup.get('city').reset();
      this.getStateListByCountry(this.thirdFormGroup.value.country);
      this.stateList = [];
      this.cityList = [];
      this.stateInfo = {};
    }
  }

  onStateSelect(): void {
    if (this.thirdFormGroup.value?.country && this.thirdFormGroup.value.state) {
      this.stateInfo = this.stateList.find((state: any) => state.iso2 === this.thirdFormGroup.value.state);
      this.thirdFormGroup.get('city').reset();
      this.getCityListByCountryAndState(this.thirdFormGroup.value?.country, this.thirdFormGroup.value.state);
      this.cityList = [];
    }
  }

  onCitySelect(): void {
    this.thirdFormGroup.get('zipCode').setValue('');
    this.filteredOptions = of([]);
    this.getPostalCodes();
  }

  getPostalCodes(): void {
    const countryCode = this.thirdFormGroup.value.country;
    const stateName = this.stateInfo?.name ? this.stateInfo.name : '';
    const cityCode = this.thirdFormGroup.value.city;
    this.loader.show();
    this.httpService.getPostalCodes(countryCode, stateName, cityCode).subscribe((response: any) => {
      if (response.pincodes?.length) {
        this.postalCodes = response.pincodes;
        this.filteredOptions = of(this.postalCodes);
      }
      this.loader.hide();
    });
  }

  setTranslations(): void {
    this.userTypeList = [
      {
        id: 'Business User',
        value: this.translate.instant('login.business_user')
      },
      {
        id: 'Private User',
        value: this.translate.instant('login.private_user')
      }
    ];
  }
  
  onUserTypeChange(event: any): void {
    this.userType = event.value;
    if (event.value === 'Business User') {
      this.secondFormGroup.get('birthDate').clearValidators();
      this.secondFormGroup.get('birthDate').updateValueAndValidity();
      this.secondFormGroup.get('companyName').setValidators([Validators.required, Validators.pattern('^['+this.inclusiveCharacters+'\'-]*$'), Validators.maxLength(50)]);
      this.secondFormGroup.get('companyName').updateValueAndValidity();
    } else if (event.value === 'Private User') {
      this.secondFormGroup.get('birthDate').setValidators([Validators.required]);
      this.secondFormGroup.get('birthDate').updateValueAndValidity();
      this.secondFormGroup.get('companyName').clearValidators();
      this.secondFormGroup.get('companyName').updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    const password = this.fourthFormGroup.get('password').value;
    const confirmPassword = this.fourthFormGroup.get('confirmPassword').value;
    if (password !== '' && password !== confirmPassword ) {
      this.fourthFormGroup.get('confirmPassword').setErrors({
        passwordMatch: false
      });
    }
  }

  passwordValidator(c: FormControl): any {
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_=+-]).{8,24}$');
    if (c.value === "") {
      return { validatePassword: { valid: true } };
    }
    return passwordRegex.test(c.value) ? null : { validatePassword: { valid: false } };
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.firstFormGroup.valid && this.secondFormGroup.valid && this.thirdFormGroup.valid && this.fourthFormGroup.valid) {
      if (!this.fourthFormGroup.get('termsConditions')?.value) {
        return;
      }
      const address = {
        street: this.thirdFormGroup.get('street').value,
        houseNumber: this.thirdFormGroup.get('houseNumber').value,
        postalCode: this.thirdFormGroup.get('zipCode').value,
        city: this.thirdFormGroup.get('city').value,
        state: this.thirdFormGroup.get('state').value,
        country: this.thirdFormGroup.get('country').value,
        mobile_number: this.secondFormGroup.get('dialCode')?.value + '-' + this.secondFormGroup.get('phoneNumber')?.value,
      };
      const paymentInfo = {
        paymentStatus: 'unPaid',
        paymentDate: 'NA',
        subscriptionId: 'NA',
        boardingAccountId: 'NA'
      }
      const PhoneCode = this.secondFormGroup.get('dialCode')?.value.split(" ")[1].replace(/[()]/g, '');
      let user: any = {
        username: this.secondFormGroup.get('emailAddress')?.value?.toLowerCase(),
        password: this.fourthFormGroup.get('password')?.value,
        'attributes': {
          'email': this.secondFormGroup.get('emailAddress')?.value?.toLowerCase(),
          'name': this.secondFormGroup.get('firstName')?.value,
          'family_name': this.secondFormGroup.get('lastName')?.value,
          'address': JSON.stringify(address),
          'phone_number': PhoneCode + this.secondFormGroup.get('phoneNumber')?.value,
          'birthdate': this.firstFormGroup.get('userType')?.value === 'Private User' ? moment(this.secondFormGroup.get('birthDate')?.value).format('YYYY-MM-DD') : '',
          'custom:userType': this.firstFormGroup.get('userType')?.value,
          'custom:accountType': 'Free Version',
          'custom:paymentType': 'NA',
          'custom:termsConditions': this.fourthFormGroup.get('termsConditions')?.value?.toString(),
          'custom:paymentInfo': JSON.stringify(paymentInfo),
          'picture': 'No photo uploaded.',
          'custom:languagePreference': this.translate.currentLang,
          'custom:notifications': 'ON',
          'custom:identityId': 'Not available',
          'custom:additionalInfo': this.thirdFormGroup.get('additionalInfo')?.value,
          'custom:companyName': this.firstFormGroup.get('userType')?.value === 'Business User' ? this.secondFormGroup.get('companyName')?.value : 'NA',
          'custom:paymentPreference': JSON.stringify(this.secondFormGroup.get('paymentPreference')?.value),
        }
      }

      // if (this.firstFormGroup.get('userType')?.value === 'Private User') {
      //   user.attributes.birthDate = moment(this.secondFormGroup.get('birthDate')?.value).format('YYYY-MM-DD');
      // } else {
      //   user.attributes['custom:companyName'] = this.secondFormGroup.get('companyName')?.value;
      // }

      this.signUpToAWS(user);
    }
  }

  signUpToAWS(payload: any): void {
    Auth.signUp(payload).then((data: any) => {
      const userName = this.secondFormGroup.get('emailAddress')?.value?.toLowerCase();
      this.authService.changeUserDetails(userName);
      this.storageService.setLocalStorage('emailToBeVerified', userName);
      this.router.navigate(["/verify-account"]);
      this.resetAll();
    }).catch((err: any) => {
      if (err.message == "An account with the given email already exists.") {
        alert(this.translate.instant("login.already_registered_user"));
        this.resetAll();
        this.router.navigate(["/login"]);
      }
    });
  }

  resetAll(): void {
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
    this.fourthFormGroup.reset();
  }
}

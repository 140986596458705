import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastSub = new Subject();

  showSuccess(message: any): void {
    const payload = {
      type: 'success',
      messageOne: message,
    }
    this.toastSub.next(payload);
  }

  showWarning(message: any): void {
    const payload = {
      type: 'warning',
      messageOne: message,
    }
    this.toastSub.next(payload);
  }

  showError(message: any): void {
    const payload = {
      type: 'danger',
      messageOne: message,
    }
    this.toastSub.next(payload);
  }
}
